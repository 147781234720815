import React, { useState, useEffect, useRef } from "react";
import FeedsComponentPopup from "./FeedsComponentPopup";
import {
  API_TIMEOUT_INTERVAL,
  cacheResponse,
  getCachedResponse,
  request,
} from "../../Utils/request";
import { Select, Dropdown, Menu, Spin } from "antd";
import Loader from "../Loader/Loader";
import COMMUNITY from "../../assets/newUiImages/community1.png";
import InfiniteScroll from "react-infinite-scroll-component";
import image from "../../assets/images/imageplaceholder.png";
import CommentIcon from "../../assets/svg icons/Group 110.svg";
import { useNavigate } from "react-router-dom";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import { toast } from "react-toastify";
import Carousel from "react-multi-carousel";
import { isMobile, isMobileOnly } from "../../Utils/mobileSettings";
import "react-multi-carousel/lib/styles.css";
import PullToRefresh from "react-simple-pull-to-refresh";
import newloader from "../../assets/loader-new.gif";
import LinkPreview from "./LinkPreview";
import { getExpireDate } from "../../Utils/timeago";
import { imageVideoHeightCalc } from "../../Utils/imageVideoHeightCalc";
import downArrow from "../../assets/newUiImages/downArrow.png";
import { videoType, imageType } from "../../Utils/VideoType";
import commentDisabled from "../../assets/svg icons/comments disable.svg";
import AddIcon from "../../assets/newUiImages/addIcon.svg";
import {
  SendOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  LikeOutlined,
  LikeTwoTone,
  DislikeTwoTone,
  DislikeOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined,
  MoreOutlined,
  DeleteOutlined,
  WarningOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Input, Button, Tooltip, Modal, Radio, Popover, Checkbox } from "antd";
import "./Feeds.css";
import { TIMEAGO } from "../../Utils/timeago";
import ReportPostModal from "./ReportPostModal";

const { Search } = Input;

type Props = {};
interface Post {
  postId: string;
  // Other fields present in the post object...
  reported: boolean;
  metaData?: string[];
  youtubeURL?: string | null;
}

const FeedsComponent = (props: Props) => {
  const feedsComponentRef = useRef(null);
  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [sharePopup, setSharePopup] = useState<boolean>(false);
  const [posts, setPosts] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentCommentPage, setCurrentCommentPage] = useState<number>(0);
  const [currentCommentModalPage, setCurrentCommentModalPage] =
    useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [communityList, setCommunityList] = useState<any[]>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const [trending, setTrending] = useState<any[]>([]);
  const [toggleImgVideoPost, setToggleImgVideoPost] = useState(false);
  const [postComments, setPostComments] = useState<any[]>([]);
  const [comment, setComment] = useState<any>("");
  const [postId, setPostId] = useState<any>("");
  const [particularPost, setParticularPost] = useState<any>({});
  const [particularPostPoll, setParticularPostPoll] = useState<any>({});
  const [remainingPollUsers, setRemainingPollUsers] = useState<any>({});
  const [popupImgVideo, setPopupImgVideo] = useState<any>({});
  const [imgPrevNext, setImgPrevNext] = useState<any>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [sharePost, setSharePost] = useState<any>();
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const [trendingLoader, setTrendingLoader] = useState<Boolean>(true);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [postCommentsData, setPostCommentsData] = useState<any>();
  const [postCommentsMap, setPostCommentsMap] = useState<any>({});
  const [particularPostReply, setParticularPostReply] = useState<any>("");
  const [particularPostComment, setParticularPostComment] = useState<any>("");
  const [commentPostId, setCommentPostId] = useState<any>("");
  const [showPoll, setShowPoll] = useState<boolean>(false);
  const [toggleAction, setToggleAction] = useState<any>(null);
  const [commentRepliesMap, setCommentRepliesMap] = useState<{
    [key: string]: any[];
  }>({});
  const [replyingToCommentId, setReplyingToCommentId] = useState<any>({});
  const [postsType, setPostsType] = useState<boolean>(true);
  const [selectedOption, setSelectedOption] = useState("RECENT");
  const [isInitialized, setIsInitialized] = useState(false);
  const [isSending, setIsSending] = useState(false); // State to track if the comment is being sent
  const [isReplySending, setIsReplySending] = useState(false); // State to track if the reply is being sent

  let apiCount = 0;
  const navigate = useNavigate();

  const calculateItemsForDesktop = () => {
    const arrayLength = communityList?.length || 0;
    return Math.min(4, arrayLength);
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: calculateItemsForDesktop(),
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [isFeedsRightsideVisible, setIsFeedsRightsideVisible] = useState(false);

  const carouselCommunityList = async () => {
    const communityResp = await request(
      `/member/current/memberships?page=0&size=50`
    );
    setCommunityList(communityResp?.content);
  };

  const getCommunityAndTrendingData = async () => {
    try {
      if(!isMobile){
      let slicedTrendingArray = [];

      const trendingAPIResp = await request(
        `/member/current/community/trending`
      );

      if (trendingAPIResp?.length >= 6) {
        slicedTrendingArray = trendingAPIResp?.slice(0, 6);
      } else {
        slicedTrendingArray = trendingAPIResp?.length ? trendingAPIResp : [];
        slicedTrendingArray = trendingAPIResp?.length ? trendingAPIResp : [];
      }

      setTrending(slicedTrendingArray);}
      setTrendingLoader(false);
    } catch (error) {
      setTrendingLoader(false);
    }
  };
  useEffect(() => {
    getCommunityAndTrendingData();
    carouselCommunityList();
  }, []);

  // useEffect(() => {
  //   getCommunityListData();
  // }, []);
  if (searchText?.length === 0) {
    const inputElement = document.getElementById("feedsSearch");
    if (inputElement) {
      inputElement.blur();
    }
  }

  const getPosts = async (page?: number, createFeed?: string) => {
    let requestPromise = request(
      `/member/current/user/posts?page=${
        page || 0
      }&size=10&isSortByRecent=${postsType}`
    );
    let timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, API_TIMEOUT_INTERVAL)
    );
    apiCount++;

    try {
      const resp = await Promise.race([requestPromise, timeoutPromise]);

      if (resp?.content?.length >= 0) {
        if (!resp?.content || resp?.content?.length === 0) {
          setHasMore(false);
        } else {
          const postsWithCombinedURLs = (resp.content as Post[]).map(
            (post: Post) => ({
              ...post,
              combinedURLs: (post.metaData || [])
                .concat(post.youtubeURL || [])
                .filter((url: string | null) => url),
            })
          );
          const filteredPosts = postsWithCombinedURLs.filter(
            (post) => post?.reported === false
          );

          if (page === 0) {
            setPosts(filteredPosts);
            if (filteredPosts?.length === 0) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            cacheResponse(
              `/member/current/user/posts?page=${page || 0}&size=10`,
              filteredPosts
            );
          } else if (createFeed) {
            setPosts([...filteredPosts]);
          } else {
            setPosts([...posts, ...filteredPosts]);
            cacheResponse(
              `/member/current/user/posts?page=${page || 0}&size=10`,
              [...posts, ...filteredPosts]
            );
          }
        }
        setLoader(false);
      } else {
        // Use cached data if available
        const cachedResponse = await getCachedResponse(
          `/member/current/user/posts?page=${page || 0}&size=10`
        );

        if (cachedResponse) {
          const cachedData = await cachedResponse.json();
          setPosts(cachedData);
          setLoader(false);
          if (apiCount < 3) getPosts(page);
        } else {
          console.error("Failed to get cached data");
        }
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    const savedOption = localStorage.getItem("selectedOption");
    if (savedOption) {
      const isRecent = savedOption === "RECENT"; // Determine if the saved option is 'RECENT'
      setPostsType(isRecent); // Set the postsType based on the saved option
      setSelectedOption(savedOption); // Set the selectedOption based on the saved option
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (isInitialized) {
      if (searchText?.length === 0) {
        setSearchClicked(false);
        getPosts(currentPage);
      } else if (searchClicked) {
        handleSearch(searchText);
      }
    }
  }, [
    currentPage,
    toggleRefresh,
    searchText,
    searchClicked,
    postsType,
    isInitialized,
  ]);

  const onSortChange = (e: any) => {
    const isRecent = e.key === "RECENT";
    setPostsType(isRecent);
    setSelectedOption(e.key);
    localStorage.setItem("selectedOption", e.key);
    setCurrentPage(0);
  };

  const displayImgVideo = (postItem: any) => {
    let postData = { ...postItem };
    setPostComments([]);
    if (postItem?.combinedURLs) {
      let data = [];
      let post = postItem?.combinedURLs;
      for (let i = 0; i < post?.length; i++) {
        let format = {
          url: post[i],
          type: videoType.some((fileType) => post[i].includes(fileType))
            ? "video"
            : "photo",
          altTag: "Image Video",
        };
        data.push(format);
      }
      postData["combinedURLs"] = data;
      setPopupImgVideo(postData?.combinedURLs[0]);
    } else {
      setPopupImgVideo(null);
    }
    if (isMobileOnly) {
      navigate(`/feeds/${postItem.postId}`);
    } else {
      setParticularPost(postData);
      gettingComments(postItem?.postId);
      setPostId(postItem?.postId);
      setToggleImgVideoPost(true);
    }
  };
  const displayPollData = (post: any) => {
    let postData = { ...post };
    setParticularPostPoll(postData);
    const resetRemainingPollUsers: any = {};
    post?.pollOutput?.options?.forEach((poll: any) => {
      resetRemainingPollUsers[poll.option] = null;
    });
    setRemainingPollUsers(resetRemainingPollUsers);
    setShowPoll(true);
  };
  const handleViewAllUsers = (selectedOption: any) => {
    const usersVotedForOption = particularPostPoll?.pollOutput?.options?.find(
      (poll: any) => poll.option === selectedOption
    )?.users;
    if (usersVotedForOption) {
      setRemainingPollUsers({
        ...remainingPollUsers,
        [selectedOption]: usersVotedForOption.slice(3),
      });
    }
  };

  const loadMoreComments = () => {
    setCurrentCommentPage((Page) => Page + 1);
  };
  const loadMoreModalComments = () => {
    setCurrentCommentModalPage((Page) => Page + 1);
  };

  const gettingPostComments = async (postID: any) => {
    try {
      const response = await request(
        `/member/current/comments/${postID}?page=${
          currentCommentPage || 0
        }&size=5`
      );
      if (response?.content?.length > 0) {
        setPostCommentsMap((prevComments: any) => ({
          ...prevComments,
          [postID]:
            currentCommentPage === 0
              ? response.content
              : [...(prevComments[postID] || []), ...response.content],
        }));
      }
    } catch (error) {
      // Handle error
    }
  };

  const gettingPostReply = async (commentID: any) => {
    try {
      const response = await request(
        `/member/current/comment/${commentID}/reply`
      );
      if (response?.length > 0) {
        setCommentRepliesMap((prevComments: any) => ({
          ...prevComments,
          [commentID]: response,
        }));
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleViewReplies = async (commentID: any) => {
    if (!commentRepliesMap[commentID]) {
      await gettingPostReply(commentID);
    }
  };

  // const gettingPostComments = async (postID: any) => {
  //   try {
  //     const response = await request(
  //       `/member/current/comments/${postID}?page=${
  //         currentCommentPage || 0
  //       }&size=5`
  //     );
  //     if (response?.content?.length > 0) {
  //       const commentsWithReplies = await Promise.all(
  //         response?.content?.map(async (comment: any) => {
  //           if (comment?.noOfReplies > 0) {
  //             const replies = await gettingPostReply(comment?.commentId);
  //             return { ...comment, replies };
  //           }
  //           return comment;
  //         })
  //       );

  //       setPostCommentsMap((prevComments: any) => ({
  //         ...prevComments,
  //         [postID]:
  //           currentCommentPage === 0
  //             ? commentsWithReplies
  //             : [...(prevComments[postID] || []), ...commentsWithReplies],
  //       }));
  //     }
  //   } catch (error) {
  //     // Handle error
  //   }
  // };
  // const gettingPostReply = async (commentID: any) => {
  //   try {
  //     const response = await request(
  //       `/member/current/comment/${commentID}/reply`
  //     );
  //     if (response?.length > 0) {
  //       setCommentRepliesMap((prevComments: any) => ({
  //         ...prevComments,
  //         [commentID]: response,
  //       }));
  //     }
  //   } catch (error) {
  //     // Handle error
  //   }
  // };

  useEffect(() => {
    if (commentPostId?.length > 0) gettingPostComments(commentPostId);
  }, [currentCommentPage]);
  useEffect(() => {
    if (postId?.length > 0) gettingComments(postId);
  }, [currentCommentModalPage]);

  const toggleComments = (postItem: any) => {
    let postData = { ...postItem };
    setParticularPostComment("");
    setCurrentCommentPage(0);
    setPostCommentsData(postData);
    gettingPostComments(postItem?.postId);
    setCommentPostId(postItem?.postId);
  };

  const replyToComment = (commentId: string) => {
    setReplyingToCommentId(commentId); // Store the comment ID being replied to
  };

  const displaySharePost = (postItem: any, from?: any) => {
    let postData = { ...postItem };
    if (true) {
      if (postItem?.metaData) {
        let data = [];
        let post = postItem?.metaData;
        for (let i = 0; i < post?.length; i++) {
          let format = {
            url: post[i],
            type: videoType.some((fileType) => post[i].includes(fileType))
              ? "video"
              : "photo",
            altTag: "Image Video",
          };
          data.push(format);
        }
        postData["metaData"] = data;
        // setPopupImgVideo(postData?.metaData?.[0]);
      }
      setSharePost(postData);
      // isMobile ? setShareModal(true) : setSharePopup(true);
      setSharePopup(true);
      // isMobile ? onShareClick() : setSharePopup(true);
      // setCreatePopup(true);
      // setShareModal(true)
      setToggleImgVideoPost(false);
    }
  };

  const gettingComments = async (postID: any) => {
    try {
      const response = await request(
        `/member/current/comments/${postID}?page=${
          currentCommentModalPage || 0
        }&size=5`
      );
      if (response?.content?.length > 0) {
        setPostComments((prevComments: any) => ({
          ...prevComments,
          [postID]:
            currentCommentModalPage === 0
              ? response.content
              : [...(prevComments[postID] || []), ...response.content],
        }));
      }
    } catch (error) {}
  };

  const handleModalViewReplies = async (commentID: any) => {
    if (!commentRepliesMap[commentID]) {
      await gettingPostReply(commentID);
    }
  };

  const sendPostComments = async () => {
    if (particularPostComment) {
      setIsSending(true); // Disable input and button
      const abusiveResult = await CheckBadWordsExist(particularPostComment);

      if (abusiveResult?.status === true) {
        if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
          try {
            const body = {
              method: "POST",
              body: JSON.stringify({
                postId: commentPostId,
                content: particularPostComment,
              }),
            };

            const response = await request("/member/current/comment", body);
            if (response?.commentId) {
              let dt: any = "2023-09-11T09:53:41.44050308";
              let newParticularPost = postCommentsData;
              newParticularPost = {
                ...newParticularPost,
                noOfComments: response?.noOfCommentsOfPost,
              };

              let newPost = posts;
              let findIndex = posts.findIndex(
                (item) => item?.postId === response?.postId
              );
              newPost[findIndex] = {
                ...newPost[findIndex],
                noOfComments: response?.noOfCommentsOfPost,
              };

              setPosts(newPost);
            }
            setParticularPostComment("");
            setCurrentCommentPage(0);
            gettingPostComments(commentPostId);
          } catch (error) {
            console.error(error);
          }
        } else if (abusiveResult?.profanity) {
          toast.error("Prohibited from generating abusive content");
        }
      } else {
        toast.error(abusiveResult?.message);
      }
      setIsSending(false); // Disable input and button
    }
  };

  const sendReply = async (
    commentId: string,
    replyText: string,
    postId: string
  ) => {
    // Add the reply to the commentRepliesMap under the specific comment ID
    if (replyText) {
      const abusiveResult = await CheckBadWordsExist(replyText);
      setIsReplySending(true)
      if (abusiveResult?.status === true) {
        if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
          try {
            const body = {
              method: "POST",
              body: JSON.stringify({
                commentId: commentId,
                postId: postId,
                content: replyText,
                replyType: "FIRST_REPLY",
              }),
            };
            const response = await request("/member/current/reply", body);
            if (response?.commentId) {
              let dt: any = "2023-09-11T09:53:41.44050308";
              let newPost = posts;
              let findIndex = posts.findIndex(
                (item) => item.postId === postCommentsData?.postId
              );
              newPost[findIndex] = {
                ...newPost[findIndex],
                noOfReplies: newPost[findIndex]?.noOfReplies + 1,
              };
              setPosts(newPost);
            }
            setParticularPostReply("");
            gettingPostReply(commentId);
          } catch (error) {
            console.error(error);
          }
        } else if (abusiveResult?.profanity) {
          toast.error("Prohibited from generating abusive content");
        }
      } else {
        toast.error(abusiveResult?.message);
      }
      setIsReplySending(false)
    }
  };

  const sendComments = async () => {
    if (comment) {
      setIsSending(true); // Disable input and button
      const abusiveResult = await CheckBadWordsExist(comment);

      if (abusiveResult?.status === true) {
        if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
          try {
            const body = {
              method: "POST",
              body: JSON.stringify({
                postId: postId,
                content: comment,
              }),
            };

            const response = await request("/member/current/comment", body);
            if (response?.commentId) {
              let dt: any = "2023-09-11T09:53:41.44050308";
              let newParticularPost = particularPost;
              newParticularPost = {
                ...newParticularPost,
                noOfComments: response?.noOfCommentsOfPost,
              };

              let newPost = particularPost;
              newPost = {
                ...newPost,
                noOfComments: response?.noOfCommentsOfPost,
              };
              updateComments(response);
              setParticularPost(newPost);
            }
            console.log("NEW POST!", posts);
            setComment("");
            setCurrentCommentModalPage(0);
            gettingComments(postId);
          } catch (error) {
            console.log("Error===>", error);
          }
        } else if (abusiveResult?.profanity) {
          toast.error("Prohibited from generating abusive content");
        }
      } else {
        toast.error(abusiveResult?.message);
      }
      setIsSending(false); // Disable input and button

    }
  };
  const updateComments = async (response: any) => {
    let newPost = posts;
    let findIndex = posts.findIndex(
      (item) => item?.postId === response?.postId
    );
    newPost[findIndex] = {
      ...newPost[findIndex],
      noOfComments: response?.noOfCommentsOfPost,
    };

    setPosts(newPost);
  };
  const handleCancel = () => {
    setPostComments([]);
    setPostId("");
    setComment("");
    setParticularPost({});
    setPopupImgVideo(null);
    setImgPrevNext(0);
    setCurrentCommentModalPage(0);
    setToggleImgVideoPost(false);
  };

  const handleChange = (val: any) => {
    if (val.target.value?.length === 0) {
      document.getElementById("feedsSearch")?.blur();
      const inputElements = document.querySelectorAll(
        ".ant-input-affix-wrapper"
      );

      inputElements.forEach((inputElement) => {
        const htmlElement = inputElement as HTMLElement;
        htmlElement.style.borderColor = "none";
      });
    }
  };

  const handleSearch = async (value: string) => {
    const inputElement = document.getElementById("feedsSearch");
    const inputElements = document.querySelectorAll(".ant-input-affix-wrapper");

    inputElements.forEach((inputElement) => {
      const htmlElement = inputElement as HTMLElement;
      htmlElement.style.borderColor = "none";
    });
    if (inputElement) {
      inputElement.style.borderColor = "#0000";
      inputElement.blur();
    }
    try {
      const resp = await request(
        `/member/current/posts/search?content=${value}&page=${currentPage}&size=10`
      );
      if (resp) {
        const datafetch = resp?.content;
        if (resp?.content?.length === 0) {
          setHasMore(false);
        }
        if (currentPage === 0) {
          setPosts(resp?.content);
          if (resp?.content?.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        } else {
          let oldResults = [...posts];
          oldResults = [...oldResults, ...resp?.content];
          setPosts(oldResults);
        }
      } else {
        // setLoader(false);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };
  useEffect(() => {
    let dt = particularPost?.combinedURLs?.[imgPrevNext];
    setPopupImgVideo(dt);
  }, [imgPrevNext, toggleRefresh]);

  const LikeAndDislikeCall = async (
    type: string,
    data: any,
    index?: number
  ) => {
    const post = data;
    const status = post?.currentSentimentStatus;
    const body = {
      method: "POST",
      body: JSON.stringify({
        communityId: post?.communityId,
        sentimentType:
          status === null ? type : status === type ? "NONPARTICIPATING" : type,
      }),
    };

    try {
      const resp = await request(
        `/member/current/post/${post?.postId}/sentiment`,
        body
      );
      if (resp?.id) {
        let newParticularPost = particularPost;
        newParticularPost = {
          ...newParticularPost,
          currentSentimentStatus: resp?.sentimentType,
          noOfDislikes: resp?.noOfDislikes,
          noOfLikes: resp?.noOfLikes,
        };

        let newPosts = posts;
        let findIndex = posts.findIndex((item) => item.postId === post.postId);
        newPosts[findIndex] = {
          ...newPosts[findIndex],
          currentSentimentStatus: resp?.sentimentType,
          noOfDislikes: resp?.noOfDislikes,
          noOfLikes: resp?.noOfLikes,
        };
        setPosts(newPosts);
        setParticularPost(newParticularPost);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleFeedsRightside = () => {
    setIsFeedsRightsideVisible(!isFeedsRightsideVisible);
  };

  // const getExpireDate = (time:any) => {
  //   var utcTime = moment.utc(time);
  //   const istTime = utcTime.utcOffset("+05:30");
  //   return moment(istTime).format('lll');
  // }

  const LikeAndDisLikeComponent = (
    post: any,
    previewPopup?: boolean,
    index?: number
  ) => {
    return (
      <>
        <div className="flex flex-row gap-6 items-center mr-6">
          {/* <div className={`${previewPopup ? "flex gap-2" : "flex gap-0"}`}> */}
          <div className="flex gap-2">
            {previewPopup && (
              <div className="text-base font-light ml-2">
                {" "}
                {post?.noOfLikes > 0 ? post?.noOfLikes : ""}
              </div>
            )}
            {!post?.communityBlocked && !post?.memberBlocked ? (
              post?.currentSentimentStatus === "LIKE" ? (
                <LikeTwoTone
                  onClick={(event) => {
                    LikeAndDislikeCall("NONPARTICIPATING", post, index);
                    event?.stopPropagation();
                  }}
                  className="like-share-icon"
                />
              ) : (
                <LikeOutlined
                  onClick={(event) => {
                    LikeAndDislikeCall("LIKE", post, index);
                    event?.stopPropagation();
                  }}
                  className="like-share-icon"
                />
              )
            ) : (
              <LikeOutlined
                disabled
                className="like-share-icon  disabled:opacity-15"
                style={{ color: "gray" }}
              />
            )}
            <span className={(!post?.communityBlocked && !post?.memberBlocked) ? "" : "text-gray-500"}>{
              !previewPopup &&
              // <div className="text-base">
              // {" "}
              post?.noOfLikes > 0
                ? post?.noOfLikes
                : ""
              // </div>
            }</span>
            {previewPopup ? <span className="mr-4">Like</span> : ""}
          </div>

          {/* {!previewPopup && 
            // <div className="text-base">
              // {" "}
              post?.noOfLikes > 0 ? post?.noOfLikes : ""
            // </div>
          } */}

          <div className="flex gap-2">
            {!post?.communityBlocked && !post?.memberBlocked ? (
              post?.currentSentimentStatus === "DISLIKE" ? (
                <DislikeTwoTone
                  onClick={(event) => {
                    LikeAndDislikeCall("NONPARTICIPATING", post, index);
                    event.stopPropagation();
                  }}
                  className="like-share-icon"
                />
              ) : (
                <DislikeOutlined
                  onClick={(event) => {
                    LikeAndDislikeCall("DISLIKE", post, index);
                    event.stopPropagation();
                  }}
                  className="like-share-icon"
                />
              )
            ) : (
              <DislikeOutlined
                disabled
                className="like-share-icon"
                style={{ color: "gray" }}
              />
            )}
            {previewPopup ? <span>Dislike</span> : ""}
          </div>
        </div>
      </>
    );
  };

  const handleRefresh = async (): Promise<void> => {
    window.location.reload();
    // setToggleRefresh(!toggleRefresh);
    setCurrentPage(0);
  };

  function getVideoId(url: any) {
    const patterns = [
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch(?:\/|.+?)?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([a-zA-Z0-9_-]{11}).*/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11}).*/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  }

  function postlink(content: any) {
    // const youtubeUrlPattern =
    //   /(?<!href="|target="_blank">)https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+(?:&[\w=%]*)?/g;

    // // Remove YouTube URLs from the input string
    // const processedString = content?.replace(youtubeUrlPattern, "");
    // console.log(processedString);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        // key={index}
        className="rich-html-text"
      />
    );
  }

  const MobilePullToRefresh = ({ children, refreshingContent }: any) => {
    return isMobile ? (
      <PullToRefresh
        onRefresh={handleRefresh}
        refreshingContent={refreshingContent}
      >
        {children}
      </PullToRefresh>
    ) : (
      <>{children}</>
    );
  };

  const onRadioButtonChange = async (
    postId: string,
    optionValue: any,
    participated: any,
    type: string
  ) => {
    if (type === "singleSelect" && participated) return;
    const requestPayload = {
      method: "POST",
      body: JSON.stringify({
        postId,
        option: optionValue,
      }),
    };
    const typeOfUrl =
      type === "singleSelect" ? "fill" : participated ? "remove" : "fill";
    const resp = await request(
      `/member/current/poll/${typeOfUrl}/${postId}?option=${optionValue}`,
      requestPayload
    );
    let postCopy = [...posts];
    const findIndex = postCopy.findIndex((item) => item.postId === resp.postId);
    postCopy[findIndex] = {
      ...postCopy[findIndex],
      pollOutput: resp?.pollOutput,
    };
    setPosts(postCopy);
  };

  const deletePost = async (ind: any, data: any) => {
    toggleActionContainer(ind, false);
    try {
      const payload = { method: "PATCH", body: JSON.stringify({}) };
      const response = await request(
        `/member/current/post/${data?.postId}?archive=true`,
        payload
      );
      if (response) {
        let newData = [...posts];
        newData.splice(ind, 1);
        setPosts(newData);
        toast.success("Post deleted successfully!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOutsideClick = () => {
    const elem = document.getElementsByClassName(
      "post-action-container"
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elem.length; i++) {
      elem[i].style.display = "none";
    }
  };

  // const toggleActionContainer = (index: any) => {
  //   // handleOutsideClick();
  //   const container = document.getElementById(`post_${index}`);
  //   const actionContainer = document.getElementById(
  //     `post-action-container_${index}`
  //   );
  //   if (container && actionContainer) {
  //     actionContainer.style.display =
  //       actionContainer.style.display === "none" ? "block" : "none";
  //   }
  // };

  const toggleActionContainer = (index: any, visible: any) => {
    if (visible) setToggleAction(index);
    else setToggleAction(null);
  };

  const onViewMoreClick = (post: any, postItem: any) => {
    let postCopy = [...posts];
    const findIndex = postCopy.findIndex((item) => item.postId === post.postId);
    postCopy[findIndex] = {
      ...postCopy[findIndex],
      viewMore: true,
      pollOutput: {
        ...postCopy[findIndex].pollOutput,
        viewMore: true,
      },
    };
    setPosts(postCopy);
  };

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
    postId: string
  ) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    imageVideoHeightCalc(naturalWidth, naturalHeight, postId);
  };

  const handleVideoLoadedMetadata = (
    event: React.SyntheticEvent<HTMLVideoElement>,
    postId: any
  ) => {
    const { videoWidth, videoHeight } = event.currentTarget;
    imageVideoHeightCalc(videoWidth, videoHeight, postId);
  };
  const handleIframeLoad = (
    event: React.SyntheticEvent<HTMLIFrameElement, Event>,
    postId: any
  ) => {
    const iframe = event.currentTarget;
    const { contentWindow } = iframe;

    if (contentWindow) {
      const { innerWidth, innerHeight } = contentWindow;
      imageVideoHeightCalc(innerWidth, innerHeight, postId);
    }
  };

  const reportModalCloseFunction = () => {
    const ind = posts.findIndex((post) => post?.postId === postId);
    toggleActionContainer(ind, false);
    setShowReportModal(false);
    setPostId("");
  };

  const menu = (
    <Menu
      onClick={onSortChange}
      selectedKeys={[selectedOption]}
      className="custom-menu"
    >
      <Menu.Item key="RECENT">Recent</Menu.Item>
      <Menu.Item key="RELEVANT">Relevant</Menu.Item>
    </Menu>
  );

  return (
    <>
      {loader ? (
        <div className="flex justify-center items-center mt-8">
          <Loader />{" "}
        </div>
      ) : (
        <>
          <div className="feedsWrapper">
            <div className="feedsContent">
              {showReportModal && (
                <>
                  <ReportPostModal
                    onCancel={() => reportModalCloseFunction()}
                    postId={postId}
                    postData={posts}
                    setPostsData={setPosts}
                  />
                </>
              )}
              <div
                id="scrollableDiv"
                className="feedsLeftSide"
                onClick={() => setIsFeedsRightsideVisible(false)}
              >
                <InfiniteScroll
                  dataLength={posts?.length}
                  next={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  hasMore={hasMore}
                  loader={
                    currentPage !== 0 && (
                      <div className="flex flex-row items-center justify-center">
                        <img
                          src={newloader}
                          style={{ width: "80px", height: "80px" }}
                          className="my-3"
                          alt=""
                        />
                      </div>
                    )
                  }
                  scrollableTarget="scrollableDiv"
                >
                  <div className="feedsPostContainer">
                    <div className="headingFeed noPadding">
                      <div className="headtop">Explore</div>
                      <div className="feedsSearch-container">
                        <Search
                          style={{
                            marginBottom: "-50px",
                            borderRadius: "20px",
                          }}
                          placeholder="Search Feeds..."
                          allowClear
                          size="large"
                          // className="searchDiv"
                          id="feedsSearch"
                          enterButton
                          value={searchText}
                          onChange={(e: any) => {
                            setSearchText(e.target.value);
                            handleChange(e);
                          }}
                          onSearch={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="communityList">
                      {communityList?.length > 0 && (
                        <div className="createcommunityButtonWrapper">
                          <button
                            className="feedscreatecommunityButton"
                            onClick={() => {
                              setCreatePopup(true);
                            }}
                          >
                            <div className="" style={{ fontSize: "45px" }}>
                              +
                            </div>
                          </button>

                          <div>
                            {createPopup && (
                              <FeedsComponentPopup
                                onCancelClick={() => {
                                  setCreatePopup(false);
                                  if (
                                    typeof window != "undefined" &&
                                    window.document
                                  ) {
                                    const containerWithOutSideBar =
                                      document.getElementById(
                                        "feedsPageContainer"
                                      );
                                    if (containerWithOutSideBar) {
                                      containerWithOutSideBar.style.position =
                                        "unset";
                                    }
                                  }
                                }}
                                onSaveClick={() => {
                                  setCreatePopup(false);
                                  setToggleRefresh(!toggleRefresh);
                                  getPosts(0, "createFeed");
                                  if (
                                    typeof window != "undefined" &&
                                    window.document
                                  ) {
                                    const containerWithOutSideBar =
                                      document.getElementById(
                                        "feedsPageContainer"
                                      );
                                    if (containerWithOutSideBar) {
                                      containerWithOutSideBar.style.position =
                                        "unset";
                                    }
                                  }
                                }}
                              />
                            )}
                          </div>
                          <div
                            className="titlecomm"
                            style={{
                              paddingTop: "10px",
                              position: "relative",
                              fontWeight: "600",
                            }}
                          >
                            Add Post
                          </div>
                        </div>
                      )}

                      <InfiniteScroll
                        dataLength={posts?.length}
                        next={() => {
                          setCurrentPage(currentPage + 1);
                        }}
                        hasMore={hasMore}
                        loader={<></>}
                      >
                        <div className="feedscommunitylist">
                          <Carousel
                            responsive={responsive}
                            containerClass="carousel-container"
                            itemClass="carousel-item"
                            // infinite={true}
                            showDots={communityList?.length > 4 ? true : false}
                            arrows={false}
                            rewind={true}
                            // swipeable={true}
                            minimumTouchDrag={20}
                          >
                            {communityList?.length > 0 &&
                              communityList?.map((community: any, index) => (
                                <div
                                  className="feedslistinner cursor-pointer"
                                  onClick={() => {
                                    navigate(
                                      `/communityPreview/${community?.communityId}`,
                                      {
                                        state: {
                                          communityMemberStatus:
                                            community?.admin
                                              ? "admincommunity"
                                              : "othercommunity",
                                          from : 0
                                        },
                                      }
                                    );
                                  }}
                                  key={index}
                                >
                                  {/* <Tooltip title={community?.name}> */}
                                  <img
                                    className={
                                      community?.communityBlocked
                                        ? "feedlistImg fitImg blockedCommunityImage"
                                        : "feedlistImg fitImg"
                                    }
                                    style={{
                                      width: "150px",
                                      height: "120px",
                                    }}
                                    src={
                                      community?.communityProfileUrl !== null
                                        ? community?.communityProfileUrl
                                        : COMMUNITY
                                    }
                                    alt=""
                                  />
                                  <div
                                    style={
                                      community?.communityBlocked
                                        ? { color: "red" }
                                        : {}
                                    }
                                    className="communityNames"
                                  >
                                    {community.name}
                                  </div>
                                  {/* </Tooltip> */}
                                </div>
                              ))}

                            {/* <div>Item 1</div>
                        <div>Item 2</div>
                        <div>Item 3</div>
                        <div>Item 4</div> */}
                          </Carousel>
                        </div>
                      </InfiniteScroll>
                    </div>
                    {communityList?.length > 0 && (
                      <div
                        className="plusCreate showMobileIcon"
                        // type="primary"
                        onClick={() => {
                          setCreatePopup(true);
                          if (typeof window != "undefined" && window.document) {
                            const containerWithOutSideBar =
                              document.getElementById("feedsPageContainer");
                            if (containerWithOutSideBar) {
                              containerWithOutSideBar.style.position = "fixed";
                            }
                          }
                        }}
                        style={{ marginTop: "-1px" }}
                      >
                        <div className="" style={{ fontSize: "45px" }}>
                          {/* + */}
                          <img
                            src={AddIcon}
                            alt=""
                            style={{
                              width: "100%",
                              height: "100%",
                              border: "0.5px solid gray",
                              borderRadius: "50%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className="feedsContainer"
                      onClick={() => setIsFeedsRightsideVisible(false)}
                    >
                      {communityList?.length ? (
                        <>
                          <>
                            <div className="headingFeedLabel">
                              <div className="headtop mb-6">Feeds</div>
                              <div className="filterSearchContainer">
                                <div className="feedsSearch-container ">
                                  <Search
                                    style={{
                                      borderRadius: "20px",
                                    }}
                                    placeholder="Search Feeds..."
                                    allowClear
                                    size="large"
                                    // className="searchDiv"
                                    id="feedsSearch"
                                    enterButton
                                    value={searchText}
                                    onChange={(e: any) => {
                                      setSearchText(e.target.value);
                                      handleChange(e);
                                    }}
                                    onSearch={(value) => {
                                      setCurrentPage(0);
                                      setSearchClicked(true);
                                      // handleSearch(value);
                                    }}
                                    // onSearch={handleSearch}
                                  />
                                </div>
                                <Dropdown
                                  overlay={menu}
                                  trigger={["click"]}
                                  overlayClassName="custom-dropdown-menu"
                                >
                                  <i className="bi bi-filter filterIcon"></i>
                                </Dropdown>
                              </div>
                            </div>
                            {posts?.length > 0 ? (
                              <>
                                {posts?.map((post: any, index) => (
                                  <div className="postCard" key={index}>
                                    <div
                                      className="flex flex-col"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="flex justify-between">
                                        <div className="dpandname text-[#804C31]">
                                          {post?.userProfileUrl ? (
                                            <div
                                              className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                              onClick={() =>
                                                navigate(
                                                  `/profile/${post?.userId}`
                                                )
                                              }
                                            >
                                              <span className="font-medium ">
                                                <img
                                                  src={post?.userProfileUrl}
                                                  alt=""
                                                  style={{
                                                    width: "45px",
                                                    height: "45px",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          ) : (
                                            <div
                                              style={{
                                                backgroundColor: `${post?.profileColorCode}`,
                                              }}
                                              onClick={() =>
                                                navigate(
                                                  `/profile/${post?.userId}`
                                                )
                                              }
                                              className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                            >
                                              <span className="font-medium text-white">
                                                {post?.userName?.substring(
                                                  0,
                                                  1
                                                )}
                                              </span>
                                            </div>
                                          )}
                                          {isMobile ? (
                                            <div className="flex flex-col justify-center">
                                              <div className="flex items-center">
                                                <span
                                                  className="posttitle userName capitalize"
                                                  onClick={() =>
                                                    navigate(
                                                      `/profile/${post?.userId}`
                                                    )
                                                  }
                                                >
                                                  {post?.userName}
                                                </span>
                                                <span className="ml-1">
                                                  &#8594;
                                                </span>
                                                <span
                                                  className="posttitle postCommunityName"
                                                  onClick={() =>
                                                    navigate(
                                                      `/communityPreview/${post?.communityId}`,
                                                      {
                                                        state: {
                                                          communityMemberStatus:
                                                            post?.isAdmin
                                                              ? "admincommunity"
                                                              : "othercommunity",
                                                          from : 0,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  {post?.communityName}
                                                </span>
                                              </div>
                                              <div className="text-xs ml-3">
                                                <TIMEAGO
                                                  timestamp={post?.createdAt}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="flex items-center">
                                              <span
                                                className="posttitle userName capitalize"
                                                onClick={() =>
                                                  navigate(
                                                    `/profile/${post?.userId}`
                                                  )
                                                }
                                              >
                                                {post?.userName}
                                              </span>
                                              <span className="ml-1">
                                                &#8594;
                                              </span>
                                              <span
                                                className="posttitle postCommunityName "
                                                onClick={() =>
                                                  navigate(
                                                    `/communityPreview/${post?.communityId}`,
                                                    {
                                                      state: {
                                                        communityMemberStatus:
                                                          post?.isAdmin
                                                            ? "admincommunity"
                                                            : "othercommunity",
                                                        from : 0
                                                      },
                                                    }
                                                  )
                                                }
                                              >
                                                {post?.communityName}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        {post?.currentUserCreated
                                          ? !post?.memberBlocked &&
                                            !post?.communityBlocked && (
                                              <div>
                                                <Popover
                                                  id={"Popover_" + index}
                                                  content={
                                                    <Button
                                                      className="post-del-btn text-left w-full"
                                                      icon={<DeleteOutlined />}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        deletePost(index, post);
                                                      }}
                                                    >
                                                      Delete
                                                    </Button>
                                                  }
                                                  trigger="click"
                                                  open={toggleAction === index}
                                                  placement="bottomRight"
                                                  onOpenChange={(visible) =>
                                                    toggleActionContainer(
                                                      index,
                                                      visible
                                                    )
                                                  }
                                                  key={index}
                                                >
                                                  <div className="post-action-btn cursor-pointer">
                                                    <MoreOutlined
                                                      style={{
                                                        fontSize: "21px",
                                                        transform:
                                                          "rotate(90deg)",
                                                      }}
                                                    />
                                                  </div>
                                                </Popover>
                                              </div>
                                            )
                                          : !post?.memberBlocked &&
                                            !post?.communityBlocked && (
                                              <div>
                                                <Popover
                                                  id={"Popover_" + index}
                                                  content={
                                                    <Button
                                                      className="post-del-btn text-left w-full"
                                                      icon={<WarningOutlined />}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        setPostId(post?.postId);
                                                        setShowReportModal(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      Report
                                                    </Button>
                                                  }
                                                  trigger="click"
                                                  open={
                                                    toggleAction === index &&
                                                    !showReportModal
                                                  }
                                                  placement="bottomRight"
                                                  onOpenChange={(visible) =>
                                                    toggleActionContainer(
                                                      index,
                                                      visible
                                                    )
                                                  }
                                                  key={index}
                                                >
                                                  <div className="post-action-btn cursor-pointer">
                                                    <MoreOutlined
                                                      style={{
                                                        fontSize: "21px",
                                                        transform:
                                                          "rotate(90deg)",
                                                      }}
                                                    />
                                                  </div>
                                                </Popover>
                                              </div>
                                            )}
                                      </div>
                                      {post?.type === "POLL" ? (
                                        <div className="postContent">
                                          <div className="pollContentContainer">
                                            <div>
                                              <p className="postOptionContent text-xl font-bold">
                                                {post?.pollOutput?.question}
                                              </p>
                                              <p className="postOptionContent font-normal">
                                                {post?.pollOutput?.comment}
                                              </p>
                                            </div>
                                            <div
                                              className="poll-voting-options"
                                              style={{
                                                height: post?.viewMore
                                                  ? "400px"
                                                  : post?.pollOutput?.options
                                                      ?.length === 2
                                                  ? "200px"
                                                  : "250px",
                                                overflow: post?.viewMore
                                                  ? "auto"
                                                  : "hidden",
                                              }}
                                            >
                                              {post?.pollOutput
                                                ?.selectTypeEnum ===
                                              "MULTI_SELECT" ? (
                                                <div className="radio-options-group">
                                                  {post?.pollOutput?.options.map(
                                                    (pollItem: any) => (
                                                      <div
                                                        onClick={(e) => {
                                                          if (
                                                            post?.pollOutput
                                                              ?.pollStatusEnum !==
                                                              "EXPIRED" &&
                                                            !post?.communityBlocked &&
                                                            !post?.memberBlocked
                                                          ) {
                                                            e.stopPropagation();
                                                            onRadioButtonChange(
                                                              post?.postId,
                                                              pollItem?.option,
                                                              pollItem?.participated,
                                                              "multiSelect"
                                                            );
                                                          } else {
                                                            // toast.error('')
                                                          }
                                                        }}
                                                        className={`pollOptions ${
                                                          pollItem?.participated
                                                            ? "option-selected"
                                                            : "unSelect"
                                                        }`}
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          cursor:
                                                            post?.pollOutput
                                                              ?.pollStatusEnum !==
                                                            "EXPIRED"
                                                              ? "pointer"
                                                              : "auto",
                                                        }}
                                                      >
                                                        {post?.pollOutput
                                                          ?.pollStatusEnum !==
                                                          "EXPIRED" &&
                                                        !post?.memberBlocked &&
                                                        !post?.communityBlocked ? (
                                                          <div>
                                                            <Checkbox
                                                              style={{
                                                                display:
                                                                  "hidden",
                                                              }}
                                                              className="radio-option"
                                                              checked={
                                                                pollItem?.participated
                                                              }
                                                            >
                                                              <div className="testing">
                                                                <div>
                                                                  <span
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                    }}
                                                                  >
                                                                    {
                                                                      pollItem?.option
                                                                    }
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </Checkbox>
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <div
                                                              style={{
                                                                display:
                                                                  "hidden",
                                                              }}
                                                              className="radio-option"
                                                              // value={pollItem?.option}
                                                            >
                                                              <div className="testing">
                                                                <div>
                                                                  <span>
                                                                    {
                                                                      pollItem?.option
                                                                    }
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}

                                                        {post?.pollOutput
                                                          ?.pollStatusEnum ===
                                                          "EXPIRED" ||
                                                        post?.pollOutput
                                                          ?.pollTypeEnum ===
                                                          "OPEN" ? (
                                                          <div className="text-[18px] pr-3 text-[#808080] font-medium">
                                                            <span>
                                                              {pollItem?.votes}{" "}
                                                              {pollItem?.votes ===
                                                              1
                                                                ? "vote"
                                                                : "votes"}
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              ) : (
                                                <Radio.Group
                                                  className="radio-options-group"
                                                  value={
                                                    post?.pollOutput?.options?.find(
                                                      (item: any) =>
                                                        item?.participated
                                                    )?.option || ""
                                                  }
                                                >
                                                  {post?.pollOutput?.options.map(
                                                    (pollItem: any) => (
                                                      <div
                                                        onClick={(e) => {
                                                          if (
                                                            post?.pollOutput
                                                              ?.pollStatusEnum !==
                                                              "EXPIRED" &&
                                                            !post?.communityBlocked &&
                                                            !post?.memberBlocked
                                                          ) {
                                                            e.stopPropagation();
                                                            onRadioButtonChange(
                                                              post?.postId,
                                                              pollItem?.option,
                                                              pollItem?.participated,
                                                              "singleSelect"
                                                            );
                                                          } else {
                                                            // toast.error('')
                                                          }
                                                        }}
                                                        className={`pollOptions ${
                                                          pollItem?.participated
                                                            ? "option-selected"
                                                            : "unSelect"
                                                        }`}
                                                        style={{
                                                          display: "flex",
                                                          justifyContent:
                                                            "space-between",
                                                          cursor:
                                                            post?.pollOutput
                                                              ?.pollStatusEnum !==
                                                            "EXPIRED"
                                                              ? "pointer"
                                                              : "auto",
                                                        }}
                                                      >
                                                        {post?.pollOutput
                                                          ?.pollStatusEnum !==
                                                          "EXPIRED" &&
                                                        !post?.memberBlocked &&
                                                        !post?.memberBlocked ? (
                                                          <div>
                                                            <Radio
                                                              style={{
                                                                display:
                                                                  "hidden",
                                                              }}
                                                              className="radio-option"
                                                              value={
                                                                pollItem?.option
                                                              }
                                                            >
                                                              <div className="testing">
                                                                <div>
                                                                  <span
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                    }}
                                                                  >
                                                                    {
                                                                      pollItem?.option
                                                                    }
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </Radio>
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <div
                                                              style={{
                                                                display:
                                                                  "hidden",
                                                              }}
                                                              className="radio-option"
                                                              // value={pollItem?.option}
                                                            >
                                                              <div className="testing">
                                                                <div>
                                                                  <span>
                                                                    {
                                                                      pollItem?.option
                                                                    }
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}

                                                        {!post?.communityBlocked &&
                                                        !post?.memberBlocked &&
                                                        (post?.pollOutput
                                                          ?.pollStatusEnum ===
                                                          "EXPIRED" ||
                                                          post?.pollOutput
                                                            ?.pollTypeEnum ===
                                                            "OPEN") ? (
                                                          <div className="text-[18px] pr-3 text-[#808080] font-medium">
                                                            <span>
                                                              {pollItem?.votes}{" "}
                                                              {pollItem?.votes ===
                                                              1
                                                                ? "vote"
                                                                : "votes"}
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </Radio.Group>
                                              )}
                                            </div>

                                            {post?.pollOutput?.options?.length >
                                              3 &&
                                              !post?.viewMore && (
                                                <div
                                                  className="text-[#06be51] text-lg font-medium"
                                                  onClick={() => {
                                                    onViewMoreClick(
                                                      post,
                                                      post?.pollOutput?.options
                                                    );
                                                  }}
                                                >
                                                  {post?.pollOutput?.options
                                                    ?.length -
                                                    3 ===
                                                  1
                                                    ? `+${
                                                        post?.pollOutput
                                                          ?.options?.length - 3
                                                      } More option`
                                                    : ` +${
                                                        post?.pollOutput
                                                          ?.options?.length - 3
                                                      } More options`}
                                                </div>
                                              )}

                                            <div
                                              className="flex justify-between items-center postOptionContent mt-2"
                                              style={{ cursor: "context-menu" }}
                                            >
                                              <p className="md:text-base text-sm font-semibold">
                                                {post?.pollOutput
                                                  ?.pollStatusEnum === "ACTIVE"
                                                  ? `Poll Expiry :  ${getExpireDate(
                                                      post?.pollOutput
                                                        ?.expiryDate
                                                    )}`
                                                  : "Poll Expired"}
                                              </p>
                                              {(post?.pollOutput
                                                ?.pollStatusEnum ===
                                                "EXPIRED" ||
                                                post?.pollOutput
                                                  ?.pollTypeEnum === "OPEN" ||
                                                (post?.pollOutput
                                                  ?.pollTypeEnum === "HIDDEN" &&
                                                  post?.pollOutput
                                                    ?.viewVoters)) && (
                                                <span
                                                  className="text-[#06be51] md:text-lg text-sm font-medium"
                                                  style={{
                                                    cursor: post?.pollOutput
                                                      ?.viewVoters
                                                      ? "pointer"
                                                      : "context-menu",
                                                  }}
                                                  onClick={() =>
                                                    post?.pollOutput?.viewVoters
                                                      ? displayPollData(post)
                                                      : null
                                                  }
                                                >
                                                  Total votes :{" "}
                                                  {post?.pollOutput?.totalVotes}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="postContent postActualContent flex flex-col "
                                          onClick={(event) => {
                                            // IsMobileClick(post);
                                            displayImgVideo(post);
                                            event?.stopPropagation();
                                          }}
                                        >
                                          <div className="flex flex-row tagContainer flex-wrap">
                                            {post?.tags
                                              ?.filter(
                                                (postTag: any) =>
                                                  postTag?.toLowerCase() !==
                                                  "general"
                                              )
                                              ?.map((tag: any) => (
                                                <div className="flex flex-row tag">
                                                  {tag}
                                                </div>
                                              ))}
                                          </div>
                                          {postlink(post?.content)}
                                        </div>
                                      )}

                                      <div
                                        className="postContentWrapper"
                                        style={{
                                          display: post?.combinedURLs?.length
                                            ? "block"
                                            : "none",
                                        }}
                                        onClick={(event) => {
                                          // IsMobileClick(post);
                                          displayImgVideo(post);
                                          event?.stopPropagation();
                                        }}
                                      >
                                        <div className="postsImage">
                                          {post?.combinedURLs?.length && (
                                            <div
                                              className="flex gap-2"
                                              id={`post_imgvideo_container_${post?.postId}`}
                                            >
                                              {post?.combinedURLs?.map(
                                                (data: any, index: any) =>
                                                  index <=
                                                    (isMobile ? 1 : 2) && (
                                                    <div
                                                      id={`post_img_video_${post?.postId}`}
                                                      style={{
                                                        position: "relative",
                                                        width:
                                                          post?.combinedURLs
                                                            ?.length >=
                                                          (isMobile ? 2 : 3)
                                                            ? `calc(100% / ${
                                                                isMobile ? 2 : 3
                                                              })`
                                                            : `calc(100% / ${post?.combinedURLs?.length})`,
                                                        height:
                                                          post?.combinedURLs
                                                            ?.length > 1
                                                            ? isMobile
                                                              ? "250px"
                                                              : "300px"
                                                            : "auto",
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                      key={index}
                                                    >
                                                      {videoType.some(
                                                        (fileType) =>
                                                          data?.includes(
                                                            fileType
                                                          )
                                                      ) ? (
                                                        <video
                                                          id={`post_image_${post?.postId}`}
                                                          className="video"
                                                          controls
                                                          onLoadedMetadata={(
                                                            e
                                                          ) =>
                                                            post?.combinedURLs
                                                              ?.length === 1
                                                              ? handleVideoLoadedMetadata(
                                                                  e,
                                                                  post?.postId
                                                                )
                                                              : {}
                                                          }
                                                          style={{
                                                            width: "100%",
                                                            backgroundColor:
                                                              "black",
                                                          }}
                                                        >
                                                          <source
                                                            src={data}
                                                            type="video/mp4"
                                                          />
                                                        </video>
                                                      ) : imageType.some(
                                                          (fileType) =>
                                                            data?.includes(
                                                              fileType
                                                            )
                                                        ) ? (
                                                        <img
                                                          id={`post_image_${post?.postId}`}
                                                          onLoad={(e) =>
                                                            post?.combinedURLs
                                                              ?.length === 1
                                                              ? handleImageLoad(
                                                                  e,
                                                                  post?.postId
                                                                )
                                                              : {}
                                                          }
                                                          className="image"
                                                          src={data}
                                                          alt=""
                                                          style={{
                                                            // width:"100%",
                                                            objectFit: "cover",
                                                            borderRadius:
                                                              "10px",
                                                            transition:
                                                              "transform 0.3s ease",
                                                            width: "100%",
                                                          }}
                                                        />
                                                      ) : data?.includes(
                                                          "youtu"
                                                        ) ? (
                                                        <iframe
                                                          id={`post_image_${post?.postId}`}
                                                          onLoad={(e) =>
                                                            post?.combinedURLs
                                                              ?.length === 1
                                                              ? handleIframeLoad(
                                                                  e,
                                                                  post?.postId
                                                                )
                                                              : {}
                                                          }
                                                          title="youtube"
                                                          loading="lazy"
                                                          src={`https://www.youtube.com/embed/${getVideoId(
                                                            data
                                                          )}?autoplay=1`}
                                                          style={{
                                                            backgroundColor:
                                                              "black",
                                                          }}
                                                          srcDoc={`<style>
        * {
        padding: 0;
        margin: 0;
        overflow: hidden;
        }
        
        body, html {
          height: 100%;
        }
        
        img, svg {
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        
        svg {
          filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
          transition: all 250ms ease-in-out;
        }
        
        body:hover svg {
          filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
          transform: scale(1.2);
        }
      </style>
      <a href='https://www.youtube.com/embed/${getVideoId(data)}?autoplay=1'>
        <img src='https://img.youtube.com/vi/${getVideoId(
          data
        )}/hqdefault.jpg' alt='Coffee Recipe Javascript Project'><div class='play-button'>
        <button class="ytp-large-play-button ytp-button ytp-large-play-button-red-bg" aria-label="Play" title="Play"><svg version="1.1"  width='50' height='50' viewBox='0 0 60 50'><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></button>
      </div> </a>
      `}
                                                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                          allowFullScreen={true}
                                                          // style={{height:"350px", width:"600px"}}
                                                          className={
                                                            post?.combinedURLs
                                                              ?.length === 1
                                                              ? "singlePost"
                                                              : "multiplePost"
                                                          }
                                                        />
                                                      ) : (
                                                        <LinkPreview
                                                          url={data}
                                                          from={"POST"}
                                                          noOfLinks={
                                                            post?.youtubeURL
                                                              ?.length
                                                          }
                                                        />
                                                      )}

                                                      {post?.combinedURLs
                                                        ?.length >
                                                        (isMobile ? 2 : 3) &&
                                                        index ===
                                                          (isMobile
                                                            ? 1
                                                            : 2) && (
                                                          <div
                                                            className="display-img-video"
                                                            onClick={() => {
                                                              // IsMobileClick(post);
                                                              displayImgVideo(
                                                                post
                                                              );
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "50px",
                                                                color: "#FFF",
                                                              }}
                                                            >
                                                              +
                                                              {post
                                                                ?.combinedURLs
                                                                ?.length -
                                                                (isMobile
                                                                  ? 2
                                                                  : 3)}
                                                            </span>
                                                          </div>
                                                        )}
                                                    </div>
                                                  )
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="commentinfo ml-1 flex justify-between">
                                      <div className="flex  gap-1">
                                        {LikeAndDisLikeComponent(
                                          post,
                                          false,
                                          index
                                        )}
                                        <div
                                          key={post?.postId}
                                          className="post-comments"
                                          onClick={(event) => {
                                            isMobile
                                              ? displayImgVideo(post)
                                              : toggleComments(post);
                                            event.stopPropagation();
                                          }}
                                        >
                                          <span className="mr-2 flex flex-row items-center">
                                            <img
                                              src={
                                                !post?.communityBlocked &&
                                                !post?.memberBlocked
                                                  ? CommentIcon
                                                  : commentDisabled
                                              }
                                              className="text-xl commenticon"
                                              alt=""
                                            />{" "}
                                            <span
                                              className="text-base ml-3"
                                              style={{
                                                color:
                                                  !post?.communityBlocked &&
                                                  !post?.memberBlocked
                                                    ? "black"
                                                    : "gray",
                                              }}
                                            >
                                              {post?.noOfComments +
                                                post?.noOfReplies >
                                              0 ? (
                                                <div className="mr-2">
                                                  {post?.noOfComments +
                                                    post?.noOfReplies}
                                                </div>
                                              ) : null}
                                            </span>{" "}
                                          </span>
                                          {/* <span> {post?.noOfComments > 1 ? "Comments" : "Comment"}</span> */}
                                        </div>
                                        {post.type !== "POLL" && (
                                          <div
                                            className={`flex items-center cursor-pointer`}
                                            onClick={(event) => {
                                              displaySharePost(post, "post");
                                              event?.stopPropagation();
                                            }}
                                          >
                                            <ShareAltOutlined
                                              className={
                                                !post?.communityBlocked &&
                                                !post?.memberBlocked
                                                  ? "like-share-icon"
                                                  : "like-share-icon disabled"
                                              }
                                              style={{
                                                color:
                                                  !post?.communityBlocked &&
                                                  !post?.memberBlocked
                                                    ? "black"
                                                    : "gray",
                                              }}
                                            />
                                            {/* <i className="bi bi-send text-xl font-bold ml-2"></i> */}
                                          </div>
                                        )}
                                      </div>

                                      {isMobile ? null : (
                                        <TIMEAGO timestamp={post?.createdAt} />
                                      )}
                                    </div>
                                    {commentPostId === post?.postId &&
                                      // showCommentsMap[post?.postId]
                                      (postCommentsData?.member ? (
                                        <>
                                          {!post?.communityBlocked &&
                                          !post?.memberBlocked ? (
                                            <div className="msg-comment-input">
                                              {localStorage?.getItem(
                                                "profileUrl"
                                              ) !== "null" ? (
                                                <div
                                                  className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                                  onClick={() =>
                                                    navigate(
                                                      `/profile/${post?.userId}`
                                                    )
                                                  }
                                                >
                                                  <span className="font-medium ">
                                                    <img
                                                      src={
                                                        localStorage?.getItem(
                                                          "profileUrl"
                                                        ) || ""
                                                      }
                                                      alt=""
                                                      style={{
                                                        width: "45px",
                                                        height: "45px",
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      localStorage?.getItem(
                                                        "profileColorCode"
                                                      ) || "",
                                                  }}
                                                  onClick={() =>
                                                    navigate(
                                                      `/profile/${post?.userId}`
                                                    )
                                                  }
                                                  className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full text-white`}
                                                >
                                                  <span className="font-medium text-white">
                                                    {localStorage
                                                      ?.getItem("name")
                                                      ?.substring(0, 1)}
                                                  </span>
                                                </div>
                                              )}
                                              <Input
                                                value={particularPostComment}
                                                onChange={(e) =>
                                                  setParticularPostComment(
                                                    e.target.value
                                                  )
                                                }
                                                placeholder="Type your comments..."
                                                onPressEnter={() =>
                                                  sendPostComments()
                                                }
                                                maxLength={1000}
                                                disabled={isSending} // Disable input when sending
                                              />
                                              {isSending?
                                                 <Spin className="msg-send-icon" style={{borderRadius:"50%"}}/>
                                                 :
                                              <Button
                                                onClick={() =>
                                                  sendPostComments()
                                                }
                                                className="msg-send-icon"
                                                shape="circle"
                                                icon={<SendOutlined />}
                                                disabled={isSending} // Disable input when sending
                                              />}
                                            </div>
                                          ) : (
                                            <div className="msg-chat-input">
                                              {localStorage?.getItem(
                                                "profileUrl"
                                              ) !== "null" ? (
                                                <div
                                                  className={`capitalize relative inline-flex items-center 
                                            justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                                  onClick={() =>
                                                    navigate(
                                                      `/profile/${post?.userId}`
                                                    )
                                                  }
                                                >
                                                  <span className="font-medium ">
                                                    <img
                                                      src={
                                                        localStorage?.getItem(
                                                          "profileUrl"
                                                        ) || ""
                                                      }
                                                      alt=""
                                                      style={{
                                                        width: "45px",
                                                        height: "45px",
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </span>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    backgroundColor:
                                                      localStorage?.getItem(
                                                        "profileColorCode"
                                                      ) || "",
                                                  }}
                                                  onClick={() =>
                                                    navigate(
                                                      `/profile/${post?.userId}`
                                                    )
                                                  }
                                                  className={`capitalize relative inline-flex items-center 
                                            justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                                >
                                                  <span className="font-medium text-white">
                                                    {localStorage
                                                      ?.getItem("name")
                                                      ?.substring(0, 1)}
                                                  </span>
                                                </div>
                                              )}
                                              <Input
                                                placeholder="Comments have been disabled"
                                                disabled={true}
                                              />
                                              <Button
                                                className="msg-send-icon"
                                                shape="circle"
                                                disabled={true}
                                                icon={<SendOutlined />}
                                              />
                                            </div>
                                          )}
                                          {particularPostComment?.length >
                                            0 && (
                                            <p className="commentRemainingCharacters">
                                              {particularPostComment?.length} /
                                              1000
                                            </p>
                                          )}
                                          {postCommentsMap[post?.postId]
                                            ?.length > 0 && (
                                            <div className="comment-section">
                                              {postCommentsMap[
                                                post?.postId
                                              ]?.map(
                                                (item: any, index: any) => (
                                                  <div key={index}>
                                                    <div className="flex m-3 items-center">
                                                      {item?.userProfileUrl ? (
                                                        <div className="capitalize relative py-1 mr-4 rounded-full flex items-center justify-center">
                                                          <img
                                                            src={
                                                              item?.userProfileUrl
                                                            }
                                                            style={{
                                                              width: "45px",
                                                              height: "45px",
                                                              borderRadius:
                                                                "50%",
                                                              objectFit:
                                                                "cover",
                                                            }}
                                                            alt="commentProfile"
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div
                                                          className="capitalize relative text-[#FFFFFF] w-10 h-10 p-1 mr-4 rounded-full flex items-center justify-center"
                                                          style={{
                                                            backgroundColor:
                                                              item?.profileColorCode,
                                                          }}
                                                        >
                                                          {item?.author?.substring(
                                                            0,
                                                            1
                                                          )}
                                                        </div>
                                                      )}
                                                      <div className="comment-content-container">
                                                        <div className="comment-content">
                                                          <p>
                                                            <strong>
                                                              {item?.author}
                                                            </strong>
                                                          </p>
                                                          <span className="text-base">
                                                            {item?.content}
                                                          </span>
                                                          <div className="flex justify-end">
                                                            <TIMEAGO
                                                              timestamp={
                                                                item?.createdAt
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                        <div className="flex flex-row items-center justify-end">
                                                          <div
                                                            className="flex justify-end reply-button"
                                                            onClick={() =>
                                                              replyToComment(
                                                                item?.commentId
                                                              )
                                                            }
                                                          >
                                                            Reply
                                                          </div>
                                                          {item?.noOfReplies >
                                                            0 && (
                                                            <div
                                                              className="flex justify-end noOfReplies ml-1"
                                                              onClick={() =>
                                                                handleViewReplies(
                                                                  item?.commentId
                                                                )
                                                              }
                                                            >
                                                              <i className="bi bi-dot"></i>{" "}
                                                              {
                                                                item?.noOfReplies
                                                              }{" "}
                                                              {item?.noOfReplies >
                                                              1
                                                                ? "Replies"
                                                                : "Reply"}
                                                            </div>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    {replyingToCommentId ===
                                                      item?.commentId && (
                                                      <>
                                                        {!post?.communityBlocked &&
                                                        !post?.memberBlocked ? (
                                                          <div className="reply-chat-input">
                                                            {localStorage?.getItem(
                                                              "profileUrl"
                                                            ) !== "null" ? (
                                                              <div
                                                                className={`capitalize relative inline-flex items-center 
                    justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                                                onClick={() =>
                                                                  navigate(
                                                                    `/profile/${post?.userId}`
                                                                  )
                                                                }
                                                              >
                                                                <span className="font-medium ">
                                                                  <img
                                                                    src={
                                                                      localStorage?.getItem(
                                                                        "profileUrl"
                                                                      ) || ""
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                      width:
                                                                        "45px",
                                                                      height:
                                                                        "45px",
                                                                      borderRadius:
                                                                        "50%",
                                                                      objectFit:
                                                                        "cover",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                            ) : (
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    localStorage?.getItem(
                                                                      "profileColorCode"
                                                                    ) || "",
                                                                }}
                                                                onClick={() =>
                                                                  navigate(
                                                                    `/profile/${post?.userId}`
                                                                  )
                                                                }
                                                                className={`capitalize relative inline-flex items-center 
                    justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                                              >
                                                                <span className="font-medium text-white">
                                                                  {localStorage
                                                                    ?.getItem(
                                                                      "name"
                                                                    )
                                                                    ?.substring(
                                                                      0,
                                                                      1
                                                                    )}
                                                                </span>
                                                              </div>
                                                            )}
                                                            <Input
                                                              value={
                                                                particularPostReply
                                                              }
                                                              onChange={(e) =>
                                                                setParticularPostReply(
                                                                  e.target.value
                                                                )
                                                              }
                                                              placeholder="Type your reply..."
                                                              onPressEnter={() =>
                                                                sendReply(
                                                                  item?.commentId,
                                                                  particularPostReply,
                                                                  post?.postId
                                                                )
                                                              }
                                                              maxLength={1000}
                                                              disabled={isReplySending} // Disable input when sending
                                                              />
                                                                  {isReplySending?
                                                                       <Spin className="msg-send-icon" style={{borderRadius:"50%"}}/>
                                                                       :
                                                            <Button
                                                              onClick={() =>
                                                                sendReply(
                                                                  item?.commentId,
                                                                  particularPostReply,
                                                                  post?.postId
                                                                )
                                                              }
                                                              className="msg-send-icon"
                                                              shape="circle"
                                                              icon={
                                                                <SendOutlined />
                                                              }
                                                              disabled={isReplySending}
                                                            />
}
                                                          </div>
                                                        ) : (
                                                          <div className="reply-chat-input">
                                                            {localStorage?.getItem(
                                                              "profileUrl"
                                                            ) !== "null" ? (
                                                              <div
                                                                className={`capitalize relative inline-flex items-center 
                    justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                                                onClick={() =>
                                                                  navigate(
                                                                    `/profile/${particularPost?.userId}`
                                                                  )
                                                                }
                                                              >
                                                                <span className="font-medium ">
                                                                  <img
                                                                    src={
                                                                      localStorage?.getItem(
                                                                        "profileUrl"
                                                                      ) || ""
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                      width:
                                                                        "45px",
                                                                      height:
                                                                        "45px",
                                                                      borderRadius:
                                                                        "50%",
                                                                      objectFit:
                                                                        "cover",
                                                                    }}
                                                                  />
                                                                </span>
                                                              </div>
                                                            ) : (
                                                              <div
                                                                style={{
                                                                  backgroundColor:
                                                                    localStorage?.getItem(
                                                                      "profileColorCode"
                                                                    ) || "",
                                                                }}
                                                                onClick={() =>
                                                                  navigate(
                                                                    `/profile/${particularPost?.userId}`
                                                                  )
                                                                }
                                                                className={`capitalize relative inline-flex items-center 
                    justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                                              >
                                                                <span className="font-medium text-white">
                                                                  {localStorage
                                                                    ?.getItem(
                                                                      "name"
                                                                    )
                                                                    ?.substring(
                                                                      0,
                                                                      1
                                                                    )}
                                                                </span>
                                                              </div>
                                                            )}
                                                            <Input
                                                              placeholder="Replies have been disabled"
                                                              disabled={true}
                                                            />
                                                            <Button
                                                              className="msg-send-icon"
                                                              shape="circle"
                                                              disabled={true}
                                                              icon={
                                                                <SendOutlined />
                                                              }
                                                            />
                                                          </div>
                                                        )}
                                                        {particularPostReply?.length >
                                                          0 && (
                                                          <p className="commentRemainingCharacters">
                                                            {
                                                              particularPostReply?.length
                                                            }{" "}
                                                            / 1000
                                                          </p>
                                                        )}
                                                      </>
                                                    )}
                                                    {commentRepliesMap[
                                                      item?.commentId
                                                    ]?.map((reply, index) => (
                                                      <div
                                                        className="flex m-3 items-center replyComment"
                                                        key={index}
                                                      >
                                                        {reply?.userProfileUrl ? (
                                                          <div className="capitalize relative py-1 mr-4 rounded-full flex items-center justify-center">
                                                            <img
                                                              src={
                                                                reply?.userProfileUrl
                                                              }
                                                              style={{
                                                                width: "45px",
                                                                height: "45px",
                                                                borderRadius:
                                                                  "50%",
                                                                objectFit:
                                                                  "cover",
                                                              }}
                                                              alt="commentProfile"
                                                            />
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className="capitalize relative text-[#FFFFFF] w-10 h-10 p-1 mr-4 rounded-full flex items-center justify-center"
                                                            style={{
                                                              backgroundColor:
                                                                reply?.profileColorCode,
                                                            }}
                                                          >
                                                            {reply?.author?.substring(
                                                              0,
                                                              1
                                                            )}
                                                          </div>
                                                        )}
                                                        <div className="reply-content-container">
                                                          <div className="reply-content">
                                                            {reply?.author
                                                              ?.length > 0 && (
                                                              <p>
                                                                <strong>
                                                                  {
                                                                    reply?.author
                                                                  }
                                                                </strong>
                                                              </p>
                                                            )}
                                                            <h3>
                                                              {reply?.content}
                                                            </h3>
                                                            <div className="flex justify-end">
                                                              <TIMEAGO
                                                                timestamp={
                                                                  reply?.createdAt
                                                                }
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ))}
                                                  </div>
                                                )
                                              )}

                                              {postCommentsMap[post?.postId]
                                                ?.length > 0 &&
                                                postCommentsMap[post?.postId]
                                                  ?.length !==
                                                  post?.noOfComments && (
                                                  <button
                                                    onClick={loadMoreComments}
                                                    className="load-more-button"
                                                  >
                                                    Load More Comments...
                                                  </button>
                                                )}
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <div className="msg-chat-input">
                                            {localStorage?.getItem(
                                              "profileUrl"
                                            ) !== "null" ? (
                                              <div
                                                className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                                onClick={() =>
                                                  navigate(
                                                    `/profile/${particularPost?.userId}`
                                                  )
                                                }
                                              >
                                                <span className="font-medium ">
                                                  <img
                                                    src={
                                                      localStorage?.getItem(
                                                        "profileUrl"
                                                      ) || ""
                                                    }
                                                    alt=""
                                                    style={{
                                                      width: "45px",
                                                      height: "45px",
                                                      borderRadius: "50%",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </span>
                                              </div>
                                            ) : (
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    localStorage?.getItem(
                                                      "profileColorCode"
                                                    ) || "",
                                                }}
                                                onClick={() =>
                                                  navigate(
                                                    `/profile/${particularPost?.userId}`
                                                  )
                                                }
                                                className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                              >
                                                <span className="font-medium text-white">
                                                  {localStorage
                                                    ?.getItem("name")
                                                    ?.substring(0, 1)}
                                                </span>
                                              </div>
                                            )}
                                            <Input
                                              placeholder="Join the community to comment"
                                              disabled={true}
                                            />
                                            <Button
                                              className="msg-send-icon"
                                              shape="circle"
                                              disabled={true}
                                              icon={<SendOutlined />}
                                            />
                                          </div>
                                          <div className="comment-section">
                                            {postCommentsMap?.map(
                                              (item: any, index: any) => (
                                                <div
                                                  className="flex m-3 items-center"
                                                  key={index}
                                                >
                                                  {item?.userProfileUrl ? (
                                                    <div
                                                      className="capitalize relative py-1 mr-4
                        rounded-full flex items-center justify-center"
                                                    >
                                                      <img
                                                        src={
                                                          item?.userProfileUrl
                                                        }
                                                        style={{
                                                          width: "45px",
                                                          height: "45px",
                                                          borderRadius: "50%",
                                                          objectFit: "cover",
                                                        }}
                                                        alt="commentProfile"
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className="capitalize relative  text-[#FFFFFF] w-10 h-10 p-1 mr-4
                        rounded-full flex items-center justify-center"
                                                      style={{
                                                        backgroundColor:
                                                          item?.profileColorCode,
                                                      }}
                                                    >
                                                      {item?.author?.substring(
                                                        0,
                                                        1
                                                      )}
                                                    </div>
                                                  )}

                                                  <div className="comment-content">
                                                    <p>
                                                      <strong>
                                                        {item?.author}
                                                      </strong>
                                                    </p>
                                                    <h3>{item?.content}</h3>
                                                    <div className="flex justify-end">
                                                      <TIMEAGO
                                                        timestamp={
                                                          item?.createdAt
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="flex flex-col items-center pt-20 pb-10">
                                <img
                                  src={image}
                                  style={{ width: "100px", height: "100px" }}
                                  alt=""
                                />
                                <div className="text-gray-500 text-2xl pt-5">
                                  {" "}
                                  No Posts{" "}
                                </div>
                              </div>
                            )}
                          </>
                        </>
                      ) : (
                        <div className="h-full">
                          <div className="flex flex-col items-center pt-20 pb-10">
                            <img
                              src={image}
                              style={{ width: "100px", height: "100px" }}
                              alt=""
                            />
                            <div className="text-gray-500 text-2xl pt-5">
                              {" "}
                              Join a community to see Posts{" "}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
              <div
                className={`feedsRightSide ${
                  isFeedsRightsideVisible ? "visible" : ""
                }`}
                ref={feedsComponentRef}
                onClick={() => setIsFeedsRightsideVisible(false)}
              >
                <div>
                  <p className="RightSideTextLabel">Trending</p>
                  <div className="RightSideThum">
                    {trending?.length === 0 ? (
                      trendingLoader ? (
                        <div style={{ minHeight: "8rem" }}>
                          <div className="loader my-3">
                            <Loader />{" "}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center w-full h-full pt-[50%]">
                          <img
                            src={image}
                            style={{ width: "60px", height: "60px" }}
                            alt=""
                          />
                          {/* <img src={image} style={{width:"100px", height:"100px"}}/> */}
                          <div className="text-gray-500 text-xl py-3">
                            {" "}
                            No communities{" "}
                          </div>
                        </div>
                      )
                    ) : trendingLoader ? (
                      <div style={{ minHeight: "8rem" }}>
                        <div className="loader my-3">
                          <Loader />{" "}
                        </div>
                      </div>
                    ) : (
                      trending?.map((trending, index) => (
                        <div className="RightSideThumCol" key={index}>
                          <Tooltip title={trending?.name}>
                            <div
                              onClick={() => {
                                navigate(`/communityPreview/${trending?.id}`, {
                                  state: {
                                    communityMemberStatus: trending?.admin
                                      ? "admincommunity"
                                      : "othercommunity",
                                    from : 0
                                  },
                                });
                              }}
                              className="RightSideThumImg cursor-pointer"
                            >
                              <img
                                className="trending rounded-md trending-images"
                                src={
                                  trending?.communityProfileUrl !== null
                                    ? trending?.communityProfileUrl
                                    : COMMUNITY
                                }
                                alt=""
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="hamburgerTrending" onClick={toggleFeedsRightside}>
                {isFeedsRightsideVisible ? (
                  <span>X</span>
                ) : (
                  <span>Trending</span>
                )}
              </div>
            </div>
          </div>
          <div
            className={createPopup ? "overlay show" : "overlay"}
            onClick={() => setCreatePopup(false)}
          ></div>
        </>
      )}
      <div className="feeds-modal">
        <Modal
          open={shareModal}
          footer={false}
          transitionName="ant-modal-slide-up"
          width={"100%"}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "86.5vh",
            overflowY: "hidden",
            padding: "0px",
            width: "100%",
          }}
          // centered
          onCancel={() => {
            setShareModal(false);
          }}
          title={
            <div className="flex flex-row items-center justify-center pt-3">
              Share Post
            </div>
          }
        >
          <div className="flex flex-row justify-center py-5 px-10">
            {/* <div className="flex flex-col items-center">
                <LinkOutlined
                  className="text-3xl"
                  onClick={() => {
                    setShareModal(false);
                    onShareClick();
                  }}
                />
                <span
                  onClick={() => {
                    setShareModal(false);
                    onShareClick();
                  }}
                >
                  Via Link
                </span>
              </div> */}
            <div className="flex flex-col items-center justify-center">
              <UsergroupAddOutlined
                className="text-3xl"
                onClick={() => {
                  setShareModal(false);
                  setSharePopup(true);
                }}
              />
              <span
                onClick={() => {
                  setShareModal(false);
                  setSharePopup(true);
                }}
              >
                Via Communities
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          open={toggleImgVideoPost}
          title={
            <div>
              <div className="feeds-header">
                {particularPost?.userProfileUrl ? (
                  <div
                    style={{
                      backgroundColor: `${particularPost?.profileColorCode}`,
                    }}
                    className="feeds-header-avatar rounded-full"
                  >
                    <img
                      src={particularPost?.userProfileUrl}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: `${particularPost?.profileColorCode}`,
                    }}
                    className="feeds-header-avatar"
                  >
                    {particularPost?.userName?.substring(0, 1)}
                  </div>
                )}

                <div className="p-0 pl-2" style={{ width: "90%" }}>
                  <p>
                    <span>{particularPost?.userName}</span>
                    <span className="ml-1 mr-1">&#8594;</span>
                    <span>{particularPost?.communityName}</span>
                  </p>
                  <div className="text-sm">
                    <TIMEAGO timestamp={particularPost?.createdAt} />
                  </div>
                </div>
              </div>
              <div
                className="downArrow showMobileButton"
                onClick={handleCancel}
              >
                <img src={downArrow} alt="" />
              </div>
            </div>
          }
          width={"40%"}
          // {`${particularPost.metaData?.length  || particularPost.noOfComments >=6 ? 'calc(90vh - 150px)' : 'min-height:250px'}`}
          className="modalpopup feedsExtendModal"
          // bodyStyle={{ height:'calc(90vh - 150px)' , overflowY: "scroll" }}
          bodyStyle={{
            overflowY: "scroll",
            height:
              particularPost?.combinedURLs?.length > 0 ||
              particularPost?.noOfComments >= 3 ||
              particularPost?.content?.length > 1000
                ? "auto"
                : "250px",
            maxHeight: "calc(90vh - 150px)",
          }}
          onCancel={handleCancel}
          footer={[
            particularPost?.member ? (
              <>
                {!particularPost?.communityBlocked &&
                !particularPost?.memberBlocked ? (
                  <div className="msg-chat-input">
                    {localStorage?.getItem("profileUrl") !== "null" ? (
                      <div
                        className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                        onClick={() =>
                          navigate(`/profile/${particularPost?.userId}`)
                        }
                      >
                        <span className="font-medium ">
                          <img
                            src={localStorage?.getItem("profileUrl") || ""}
                            alt=""
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor:
                            localStorage?.getItem("profileColorCode") || "",
                        }}
                        onClick={() =>
                          navigate(`/profile/${particularPost?.userId}`)
                        }
                        className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                      >
                        <span className="font-medium text-white">
                          {localStorage?.getItem("name")?.substring(0, 1)}
                        </span>
                      </div>
                    )}
                    <Input
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Type your comments..."
                      onPressEnter={() => {
                        sendComments();
                      }}
                      maxLength={1000}
                      disabled={isSending} // Disable input when sending
                    />
                    {isSending?
                         <Spin className="msg-send-icon" style={{borderRadius:"50%"}}/>
                         :
                    <Button
                      onClick={() => {
                        sendComments();
                      }}
                      className="msg-send-icon"
                      shape="circle"
                      icon={<SendOutlined />}
                      disabled={isSending} // Disable input when sending
                    />
          }
                  </div>
                ) : (
                  <div className="msg-chat-input">
                    {localStorage?.getItem("profileUrl") !== "null" ? (
                      <div
                        className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full`}
                      >
                        <span className="font-medium ">
                          <img
                            src={localStorage?.getItem("profileUrl") || ""}
                            alt=""
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        </span>
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor:
                            localStorage?.getItem("profileColorCode") || "",
                        }}
                        className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                      >
                        <span className="font-medium text-white">
                          {localStorage?.getItem("name")?.substring(0, 1)}
                        </span>
                      </div>
                    )}
                    <Input
                      placeholder="Comments have been disabled"
                      disabled={true}
                    />
                    <Button
                      className="msg-send-icon"
                      shape="circle"
                      disabled={true}
                      icon={<SendOutlined />}
                    />
                  </div>
                )}
                {comment?.length > 0 && (
                  <p className="modalCommentRemainingCharacters">
                    {comment?.length} / 1000
                  </p>
                )}
              </>
            ) : (
              <div className="msg-chat-input">
                {localStorage?.getItem("profileUrl") !== "null" ? (
                  <div
                    className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                    onClick={() =>
                      navigate(`/profile/${particularPost?.userId}`)
                    }
                  >
                    <span className="font-medium ">
                      <img
                        src={localStorage?.getItem("profileUrl") || ""}
                        alt=""
                        style={{
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor:
                        localStorage?.getItem("profileColorCode") || "",
                    }}
                    onClick={() =>
                      navigate(`/profile/${particularPost?.userId}`)
                    }
                    className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                  >
                    <span className="font-medium text-white">
                      {localStorage?.getItem("name")?.substring(0, 1)}
                    </span>
                  </div>
                )}
                <Input
                  placeholder="Join the community to comment"
                  disabled={true}
                />
                <Button
                  className="msg-send-icon"
                  shape="circle"
                  disabled={true}
                  icon={<SendOutlined />}
                />
              </div>
            ),
          ]}
        >
          <div
            className={`${
              particularPost?.combinedURLs?.length === 0 &&
              particularPost.noOfComments === 0
                ? "FeedPostContent flex flex-col"
                : "feedsPostContentFontSize flex flex-col"
            } `}
          >
            <div className="flex flex-row tagContainer flex-wrap">
              {particularPost?.tags
                ?.filter((postTag: any) => postTag?.toLowerCase() !== "general")
                ?.map((tag: any) => (
                  <div className="flex flex-row tag">{tag}</div>
                ))}
            </div>
            {postlink(particularPost?.content)}
          </div>
          {popupImgVideo && (
            <>
              <div className="feeds-img-video-container1">
                <div className="postsImage">
                  <>
                    {imgPrevNext === 0 ? (
                      ""
                    ) : (
                      <div
                        className="prev-icon"
                        onClick={() =>
                          setImgPrevNext((prevState: any) =>
                            prevState - 1 <= 0 ? 0 : prevState - 1
                          )
                        }
                      >
                        <LeftCircleOutlined />
                      </div>
                    )}

                    <div
                      style={{
                        position: "relative",
                        width: "85%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      // className="imageWrapper"
                    >
                      {popupImgVideo?.type === "video" ? (
                        <video className="video" controls>
                          <source src={popupImgVideo?.url} type="video/mp4" />
                        </video>
                      ) : imageType.some((fileType) =>
                          popupImgVideo?.url?.includes(fileType)
                        ) ? (
                        <img
                          className="image"
                          src={popupImgVideo?.url}
                          alt=""
                          // width="100%"
                          // height="200px"
                          style={{
                            borderRadius: "10px",

                            // transition: "transform 0.3s ease",
                          }}
                        />
                      ) : popupImgVideo?.url?.includes("youtu") ? (
                        <iframe
                          title="youtube"
                          loading="lazy"
                          src={`https://www.youtube.com/embed/${getVideoId(
                            popupImgVideo?.url
                          )}`}
                          style={{ backgroundColor: "black" }}
                          width="100%"
                          srcDoc={`<style>
                            * {
                            padding: 0;
                            margin: 0;
                            overflow: hidden;
                            }
                            
                            body, html {
                              height: 100%;
                            }
                            
                            img, svg {
                              position: absolute;
                              width: 100%;
                              top: 0;
                              bottom: 0;
                              margin: auto;
                            }
                            
                            svg {
                              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                              transition: all 250ms ease-in-out;
                            }
                            
                            body:hover svg {
                              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                              transform: scale(1.2);
                            }
                          </style>
                          <a href='https://www.youtube.com/embed/${getVideoId(
                            popupImgVideo?.url
                          )}?autoplay=1'>
                            <img src='https://img.youtube.com/vi/${getVideoId(
                              popupImgVideo?.url
                            )}/hqdefault.jpg' alt='Coffee Recipe Javascript Project'><div class='play-button'>
                            <button class="ytp-large-play-button ytp-button ytp-large-play-button-red-bg" aria-label="Play" title="Play"><svg version="1.1"  width='50' height='50' viewBox='0 0 60 50'><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></button>
                          </div> </a>
                          `}
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          // style={{height:"350px",width:"600px"}}
                          className="singlePost"
                        />
                      ) : (
                        <LinkPreview
                          url={popupImgVideo?.url}
                          from={"MODAL"}
                          noOfLinks={particularPost?.youtubeURL?.length}
                        />
                      )}
                    </div>
                    {particularPost?.combinedURLs?.length - 1 ===
                    imgPrevNext ? (
                      ""
                    ) : (
                      <div
                        className="next-icon"
                        onClick={() =>
                          setImgPrevNext((prevState: any) =>
                            prevState + 1 >
                            particularPost?.combinedURLs?.length - 1
                              ? prevState
                              : prevState + 1
                          )
                        }
                      >
                        <RightCircleOutlined />
                      </div>
                    )}
                  </>
                </div>
              </div>
            </>
          )}
          <div className="post-like-share">
            <div className="flex">
              {LikeAndDisLikeComponent(particularPost, true)}
            </div>

            <div
              className="flex items-center cursor-pointer !m-0"
              onClick={(event) => {
                if (
                  !particularPost.communityBlocked &&
                  !particularPost?.memberBlocked
                )
                  displaySharePost(particularPost, "modal");
                event?.stopPropagation();
              }}
            >
              <ShareAltOutlined
                className={
                  !particularPost?.communityBlocked &&
                  !particularPost?.memberBlocked
                    ? "like-share-icon"
                    : "like-share-icon disabled"
                }
                style={{
                  color:
                    !particularPost?.communityBlocked &&
                    !particularPost?.memberBlocked
                      ? "black"
                      : "gray",
                }}
              />
              <span>Share</span>
            </div>
          </div>

          <div className="comment-section">
            {postComments[particularPost?.postId]?.map(
              (item: any, index: any) => (
                <>
                  <div className="flex m-3 items-center" key={index}>
                    {item?.userProfileUrl ? (
                      <div
                        className="capitalize relative py-1 mr-4
                              rounded-full flex items-center justify-center"
                      >
                        <img
                          src={item?.userProfileUrl}
                          alt=""
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="capitalize relative  text-[#FFFFFF] w-10 h-10 p-1 mr-4
                              rounded-full flex items-center justify-center"
                        style={{ backgroundColor: item?.profileColorCode }}
                      >
                        {item?.author?.substring(0, 1)}
                      </div>
                    )}

                    <div className="comment-content-container">
                      <div className="comment-content">
                        <p>
                          <strong>{item?.author}</strong>
                        </p>
                        <h3>{item?.content}</h3>
                        <div className="flex justify-end">
                          <TIMEAGO timestamp={item?.createdAt} />
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-end">
                        <div
                          className="flex justify-end reply-button"
                          onClick={() => {
                            replyToComment(item?.commentId);
                          }}
                        >
                          Reply
                        </div>
                        {item?.noOfReplies > 0 && (
                          <div
                            className="flex justify-end noOfReplies ml-1"
                            onClick={() => {
                              handleModalViewReplies(item?.commentId);
                            }}
                          >
                            <i className="bi bi-dot"></i> {item?.noOfReplies}{" "}
                            {item?.noOfReplies > 1 ? "Replies" : "Reply"}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {replyingToCommentId === item?.commentId && (
                    <>
                      {!particularPost?.communityBlocked &&
                      !particularPost?.memberBlocked ? (
                        <div className="reply-chat-input">
                          {localStorage?.getItem("profileUrl") !== "null" ? (
                            <div
                              className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                              onClick={() =>
                                navigate(`/profile/${particularPost?.userId}`)
                              }
                            >
                              <span className="font-medium ">
                                <img
                                  src={
                                    localStorage?.getItem("profileUrl") || ""
                                  }
                                  alt=""
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor:
                                  localStorage?.getItem("profileColorCode") ||
                                  "",
                              }}
                              onClick={() =>
                                navigate(`/profile/${particularPost?.userId}`)
                              }
                              className={`capitalize relative inline-flex items-center 
                          justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                            >
                              <span className="font-medium text-white">
                                {localStorage?.getItem("name")?.substring(0, 1)}
                              </span>
                            </div>
                          )}
                          <Input
                            value={particularPostReply}
                            onChange={(e) =>
                              setParticularPostReply(e.target.value)
                            }
                            placeholder="Type your reply..."
                            onPressEnter={() =>
                              sendReply(
                                item?.commentId,
                                particularPostReply,
                                particularPost?.postId
                              )
                            }
                            maxLength={1000}
                            disabled={isReplySending} // Disable input when sending
                            />
                                {isReplySending?
                                     <Spin className="msg-send-icon" style={{borderRadius:"50%"}}/>
                                     :
                          <Button
                            onClick={() =>
                              sendReply(
                                item?.commentId,
                                particularPostReply,
                                particularPost?.postId
                              )
                            }
                            className="msg-send-icon"
                            shape="circle"
                            icon={<SendOutlined />}
                            disabled={isReplySending}
                          />
}
                        </div>
                      ) : (
                        <div className="reply-chat-input">
                          {localStorage?.getItem("profileUrl") !== "null" ? (
                            <div
                              className={`capitalize relative inline-flex items-center 
                                      justify-center w-10 h-10 overflow-hidden  rounded-full`}
                            >
                              <span className="font-medium ">
                                <img
                                  src={
                                    localStorage?.getItem("profileUrl") || ""
                                  }
                                  alt=""
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor:
                                  localStorage?.getItem("profileColorCode") ||
                                  "",
                              }}
                              className={`capitalize relative inline-flex items-center 
                                      justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                            >
                              <span className="font-medium text-white">
                                {localStorage?.getItem("name")?.substring(0, 1)}
                              </span>
                            </div>
                          )}
                          <Input
                            placeholder="Replies have been disabled"
                            disabled={true}
                          />
                          <Button
                            className="msg-send-icon"
                            shape="circle"
                            disabled={true}
                            icon={<SendOutlined />}
                          />
                        </div>
                      )}
                      {particularPostReply?.length > 0 && (
                        <p className="commentRemainingCharacters">
                          {particularPostReply?.length} / 1000
                        </p>
                      )}
                    </>
                  )}
                  {commentRepliesMap[item?.commentId]?.map((reply, index) => (
                    // Display each reply under the respective comment
                    <div
                      className="flex m-3 items-center replyComment"
                      key={index}
                    >
                      {reply?.userProfileUrl ? (
                        <div
                          className="capitalize relative py-1 mr-4
                        rounded-full flex items-center justify-center"
                        >
                          <img
                            src={reply?.userProfileUrl}
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            alt="commentProfile"
                          />
                        </div>
                      ) : (
                        <div
                          className="capitalize relative  text-[#FFFFFF] w-10 h-10 p-1 mr-4
                        rounded-full flex items-center justify-center"
                          style={{ backgroundColor: reply?.profileColorCode }}
                        >
                          {reply?.author?.substring(0, 1)}
                        </div>
                      )}
                      {/* <span className="flex flex-col w-full items-end"> */}
                      {/* <div className="flex flex-col w-full"> */}
                      <div className="reply-content-container">
                        <div className="reply-content">
                          {reply?.author?.length > 0 && (
                            <p>
                              <strong>{reply?.author}</strong>
                            </p>
                          )}
                          <h3>{reply?.content}</h3>
                          <div className="flex justify-end">
                            <TIMEAGO timestamp={reply?.createdAt} />
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                      {/* </span> */}
                    </div>
                    // ...
                  ))}
                </>
              )
            )}
          </div>
          {postComments[particularPost?.postId]?.length > 0 &&
            postComments[particularPost?.postId]?.length !==
              particularPost?.noOfComments && (
              <button
                onClick={loadMoreModalComments}
                className="load-more-button"
              >
                Load More Comments...
              </button>
            )}
        </Modal>
      </div>
      {sharePopup && (
        <FeedsComponentPopup
          onCancelClick={() => {
            setSharePopup(false);
          }}
          onSaveClick={() => {
            setSharePopup(false);
            setToggleRefresh(!toggleRefresh);
            // getPosts(0, "createFeed");
          }}
          communityPostContent={sharePost?.content}
          metaData={sharePost?.metaData}
          from={"CommunityPosts"}
          tags={sharePost?.tags?.filter(
            (postTag: any) => postTag?.toLowerCase() !== "general"
          )}
        />
      )}
      {showPoll && (
        <div className="pollPopup">
          <Modal
            centered
            open
            className="pollModal"
            // width={700}
            title={
              <div
                className="modalTitle flex flex-row items-center"
                style={{
                  padding: "10px 0px 10px 10px",
                  backgroundImage: "linear-gradient(270deg, #804C31, #67702E)",
                  color: "white",
                  borderRadius: "8px 8px 0 0",
                  //  backgroundColor:"#804C31", borderRadius:"5px 5px 0 0", color:"white"
                }}
              >
                <i className="bi bi-bar-chart-fill"></i>
                <span className="px-2">Poll</span>
              </div>
            }
            onCancel={() => {
              const resetRemainingPollUsers: any = {};
              particularPostPoll?.pollOutput?.options?.forEach((poll: any) => {
                resetRemainingPollUsers[poll.option] = null;
              });
              setRemainingPollUsers(resetRemainingPollUsers);
              setShowPoll(false);
            }}
            footer={false}
          >
            <div className="pollModalContainer">
              <span className="text-[#06be51] md:text-lg text-sm font-medium mb-5">
                Total votes : {particularPostPoll?.pollOutput?.totalVotes}
              </span>
              {particularPostPoll?.pollOutput?.options?.map(
                (poll: any, index: any) => (
                  <>
                    <div
                      key={index}
                      className="flex flex-row items-center py-2 pl-5 pollModalOptions"
                      style={{
                        justifyContent: "space-between",
                        backgroundImage:
                          "linear-gradient(270deg, #e2e5e6, #e2e5e6)",
                        backgroundSize: `${
                          (poll?.votes /
                            particularPostPoll?.pollOutput?.totalVotes) *
                          100
                        }%`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "#f8f8f8",
                      }}
                    >
                      <div className="poll-radio-option">{poll?.option}</div>
                      <div className="text-[16px] pr-3 text-[#00000] font-normal">
                        <span>
                          {poll?.votes} {poll?.votes === 1 ? "vote" : "votes"}
                        </span>
                      </div>
                    </div>
                    <div className="two-columns-grid">
                      {poll?.users?.length > 0 ? (
                        (poll?.users?.length > 3
                          ? poll?.users?.slice(0, 3)
                          : poll?.users
                        )?.map((user: any, index: any) => (
                          <div
                            key={index}
                            className="flex flex-row items-center mx-5 pollUser"
                          >
                            {user?.profileUrl ? (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember`}
                                //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }

                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <img src={user?.profileUrl} />
                              </div>
                            ) : (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember ${
                                  index === 0 ? "" : "" // Apply green border to the first element
                                }`}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <span
                                  className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                                  style={{
                                    color: "white",
                                    fontSize: "1.15rem",
                                  }}
                                >
                                  <span className="memberSubstring">
                                    {user?.member?.name?.substring(0, 1)}
                                  </span>
                                </span>
                              </div>
                            )}

                            <div
                              className="flex items-center flex-col modalmemberName"
                              style={{ color: "black" }}
                            >
                              <span className="postMembertitle capitalize ml-2">
                                <span className="modalmemberName">
                                  {user?.member?.name}
                                </span>
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      {poll?.users?.length > 3 &&
                        remainingPollUsers[poll?.option] === null && (
                          <div
                            className="flex flex-row items-center mx-5 pollViewAll"
                            style={{ color: "#06be51" }}
                            onClick={() => {
                              handleViewAllUsers(poll?.option);
                            }}
                          >
                            View All
                          </div>
                        )}

                      {remainingPollUsers[poll?.option]?.map(
                        (user: any, index: any) => (
                          <div
                            key={index}
                            className="flex flex-row items-center mx-5 pollUser"
                          >
                            {user?.profileUrl ? (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember`}
                                //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                onClick={() => navigate(`/profile/${user?.id}`)}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <img src={user?.profileUrl} alt="" />
                              </div>
                            ) : (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember ${
                                  index === 0 ? "" : "" // Apply green border to the first element
                                }`}
                                onClick={() => navigate(`/profile/${user?.id}`)}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <span
                                  className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                                  style={{
                                    color: "white",
                                    fontSize: "1.15rem",
                                  }}
                                  onClick={() =>
                                    navigate(`/profile/${user?.id}`)
                                  }
                                >
                                  <span className="memberSubstring">
                                    {user?.member?.name?.substring(0, 1)}
                                  </span>
                                </span>
                              </div>
                            )}

                            <div
                              className="flex items-center flex-col modalmemberName"
                              style={{ color: "black" }}
                              onClick={() => navigate(`/profile/${user?.id}`)}
                            >
                              <span className="postMembertitle capitalize ml-2">
                                <span className="modalmemberName">
                                  {user?.member?.name}
                                </span>
                              </span>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default FeedsComponent;
