import React, { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CommunityPreviewComponent from "../Components/CommunityPreview/CommunityPreviewComponent";
import "../Components/CommunityPreview/CommunityPreview.css";
import { toast } from "react-toastify";
import { request } from "../Utils/request";

type Props = {};

const CommunityPreview = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate('/profile')
    }
    return
  }

  useEffect(() => {
    if (!token?.length) {
      localStorage.clear()
      navigate("/login");
    }
    if(profileScore === null){
      meApiCall()
    }
    else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    }
    // eslint-disable-next-line no-empty-pattern
  }, [navigate, token?.length]);

  return (
    <div>
      <div
        className="pageWrapper"
        style={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <div className="previewwrapper" style={{ margin: "0px 15px 0px 15px" }}>
          <div className="w-full">
            {token?.length && <CommunityPreviewComponent id={params?.id} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityPreview;
