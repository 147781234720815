import React, { useEffect, useRef, useState } from "react";
import {  isMobileOnly } from "../../Utils/mobileSettings";
import { Link } from "react-router-dom";
import bellicon from "../../assets/newUiImages/bellicon.svg";
import Received from "../RequestComponent/CommunityRequest";
import {  LeftOutlined } from "@ant-design/icons";
import navlogo from "../../assets/newUiImages/navlogo.svg";
import Sent from "../RequestComponent/Notification";
import whiteHamburger from "../../assets/newUiImages/whiteHamburger.png";
import "./Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Popover } from "antd";
import profileIcon from "../../assets/svg icons/Group 7469.svg";
import logOut from "../../assets/svg icons/Group 7472.svg";
import Home from "../../Pages/Home";
import { request, clearCache } from "../../Utils/request";
import {  signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { isIOS } from "react-device-detect";
type Props = {
  // parentCallback: Function;
};

const Header = (props: Props) => {
  // const [accessToken, setAccessToken] = useState<string | null>("");
  const [userInfo, setUserInfo] = useState<any>({});
  const [profileClick, setProfileClick] = useState(false);
  const [requestPopup, setRequestPopup] = useState<Boolean>(false);
  const [sentPopup, setSentPopup] = useState<Boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);
  const requestRef = useRef<HTMLDivElement>(null);
  const sentRef = useRef<HTMLDivElement>(null);
  const [searchResults, setSearchResults] = useState<any[]>([]); // State to store search results
  const [showSearchResults, setShowSearchResults] = useState(false);
  // const [profileUrl, setProfileUrl] = useState<string>("");
  const accessToken = localStorage.getItem("token");
  const profileUrl = localStorage?.getItem("profileUrl") || "";
  const [internetSpeed, setInternetSpeed] = useState<boolean>(false);

  const currentUser = localStorage.getItem("role");
  const onLogClick = () => {
    setProfileClick(false);
    localStorage.clear();
   
    // props.parentCallback("login");
  };

  const location = useLocation();

  const fetchDeviceToken = async () => {
    try {
      console.log("INSIDE FETCH FUNC in header")
        const token =  localStorage.getItem("deviceToken");
        const version =  localStorage.getItem("deviceOsVersion") || "0";
        const type = localStorage.getItem("deviceType");
        const payload = {
          method: "POST",
          body: JSON.stringify({
            deviceToken: token,
            deviceType: type,
            deviceOsVersion: parseInt(version),
          }),
          headers: {
            'Content-Type': 'application/json'
          }
        };
        request(`/member/current/device-token/delete`, payload).then(resp => {
            // Temporarily store the device values
      const tempStorage = {
        deviceToken: token,
        deviceOsVersion: version,
        deviceType: type
      };

      // Clear local storage
            console.log("RESP: ", resp)
          localStorage.clear();

                     // Restore the device values
      localStorage.setItem("deviceToken", tempStorage.deviceToken || "");
      localStorage.setItem("deviceOsVersion", tempStorage.deviceOsVersion);
      localStorage.setItem("deviceType", tempStorage.deviceType || "");


          clearCache();
          navigate("/login");
        }) 
        // if(response){
        //   console.log("RESP: ", response)
        //   localStorage.clear();
        //   clearCache()
        //   navigate("/login");
        // }

    } catch (error: any) {
      console.error("Error fetching device token: " + error.message);
    }
  };

  const items = [
    {
      icon: logOut,
      linkTo: "/login",
      label: "Logout",
      onclick: () => {
        signOut(auth).then(() => {
          const deviceToken = localStorage.getItem("deviceToken")
          const deviceType = localStorage.getItem("deviceType")
          if (deviceToken?.length && (deviceType === "ANDROID" || deviceType === "IOS"))
          // if (typeof window?.AndroidJSBridge?.getDeviceToken === "function" || isIOS)
             {
            fetchDeviceToken( )
          }else{
            localStorage.clear();
          clearCache();
          navigate("/login");
          }
          // console.log("LOGGED OUT")
        }).catch((error) => {
          console.log("ERROR",error)
        });
        // localStorage.clear();
        // navigate("/login");
        // props.parentCallback("logout");
      },
    },
  ];
  if (currentUser !== "ADMIN") {
    items.unshift({
      icon: profileIcon,
      label: "Profile",
      linkTo: "/profile",
      onclick: () => {
        setProfileClick(false);
        navigate(`/profile/${localStorage.getItem("id")}`);
        // props.parentCallback("profile");
      },
    });
  }

  const popOverContents = (
    <ul className="popupOver">
      {items?.map((items, index) => (
        <div className="flex popupContent gap-2  py-2" key={index}>
          <img src={items.icon} alt="" />
          <div onClick={() => items.onclick()}>
            <span className="cursor-pointer !m-0 popOverLabel">
              {items.label}
            </span>
          </div>
        </div>
      ))}
    </ul>
  );

  useEffect(() => {
    const checkConnectionSpeed = () => {
      // Use type assertion to inform TypeScript that connection exists
      const connection = (navigator as any).connection;

      if (connection) {
        const { downlink } = connection;

        // Calculate speed in kilobits per second
        const speedKbps = downlink * 1024;

        console.log(`Internet speed: ${speedKbps.toFixed(2)} kb/s`);

        // Update state if speed is less than 50 kb/s
        if (speedKbps < 50) {
          setInternetSpeed(true);
        } else {
          setInternetSpeed(false);
        }
      } else {
        console.warn("navigator.connection API not supported.");
      }
    };

    checkConnectionSpeed();

    // Listen for changes in the network connection
    (navigator as any).connection?.addEventListener(
      "change",
      checkConnectionSpeed
    );

    return () => {
      // Remove the event listener on component unmount
      (navigator as any).connection?.removeEventListener(
        "change",
        checkConnectionSpeed
      );
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        // Click occurred outside the div
        setProfileClick(false);
      }
    };

    const handleClickRequestPopupOutside = (event: MouseEvent) => {
      if (
        requestRef.current &&
        !requestRef.current.contains(event.target as Node)
      ) {
        setRequestPopup(false);
      }
    };

    const handleClickSentpopupOutside = (event: MouseEvent) => {
      if (sentRef.current && !sentRef.current.contains(event.target as Node)) {
        setSentPopup(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    window.addEventListener("click", handleClickRequestPopupOutside);
    window.addEventListener("click", handleClickSentpopupOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("click", handleClickRequestPopupOutside);
      window.removeEventListener("click", handleClickSentpopupOutside);
    };
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUserInfo(JSON.parse(user || ""));
      // setProfileUrl(profileUrl || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const clearSearchText = () => {
    setSearchText("");
  };
  const handleSearch = async (value: string) => {
    // Clear the search text first
    // clearSearchText();

    // Construct the API URL with the search text

    try {
      // Fetch data from the API
      const resp = await request(
        `/member/current/community/search?communityName=${value}`
      );
      if (resp) {
        const datafetch = resp;
        setShowSearchResults(true);
        setSearchResults(datafetch); // Update search results state with fetched datasetUserInfo
      } else {
        // setLoader(false);
        setShowSearchResults(false);
        console.log("Something goes wrong");
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const searchContainerRef = useRef(null);

  const closeSearchbar = () => {
    setIsSearchExpanded(false);
  };

  useEffect(() => {
    // Function to handle clicks outside the search bar
    const handleClickOutsideevent = (event: MouseEvent) => {
      // Get the search container element
      const targetelement = event.target as Element;
      // Check if the click target is not within the search container's DOM hierarchy
      if (
        isSearchExpanded &&
        targetelement &&
        !targetelement.closest(".searchtoggle")
      ) {
        setIsSearchExpanded(false);
      }
    };
    window.addEventListener("click", handleClickOutsideevent);
    return () => {
      window.removeEventListener("click", handleClickOutsideevent);
    };
  }, [isSearchExpanded]);

  const toggleLeftNav = () => {
    setIsLeftNavVisible(!isLeftNavVisible);
  };
  const toggleSearchBar = () => {
    setTimeout(() => {
      setIsSearchExpanded((prevExpanded) => !prevExpanded);
    }, 0);
  };

  const isMatrimonyProfilePage = location.pathname.startsWith(
    "/matrimony/matrimony-profile/"
  );
  const isChat = location.pathname.startsWith("/chat");
  const isCommnityPreviewPage =
    location.pathname.startsWith("/communityPreview/");
  const isFeedsPreviewPage = location.pathname.startsWith("/feeds/");
  const isProfilePreviewPage = location.pathname.startsWith("/profile/");

  const [isLeftNavVisible, setIsLeftNavVisible] = useState(false);

  useEffect(() => {
    if (isLeftNavVisible) {
      if (typeof window != "undefined" && window.document) {
        const containerWithOutSideBar =
          document.getElementById("feedsPageContainer");
        if (containerWithOutSideBar) {
          containerWithOutSideBar.style.position = "fixed";
        }
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        const containerWithOutSideBar =
          document.getElementById("feedsPageContainer");
        if (containerWithOutSideBar) {
          containerWithOutSideBar.style.position = "unset";
        }
      }
    }
  }, [isLeftNavVisible]);
  return (
    <div>
      {window.location.pathname !== "/login" ? (
        <div
          className={
            isChat && isMobileOnly
              ? ""
              : "bg-white flex shadow-md px-8 pb-4 HeaderWrapper"
          }
        >
          {isMobileOnly ? (
            <>
              {isChat ? (
                <></>
              ) : (
                <>
                  <div style={{width:"100%"}}>
                    {isMatrimonyProfilePage ||
                    isCommnityPreviewPage ||
                    isFeedsPreviewPage ||
                    isProfilePreviewPage ? (
                      <div className="leftNavAndHeaderWrapper flex flex-row" style={{width:"100%"}}>
                        <button
                        style={{width:"100%",display:"flex",flexDirection:"row",alignItems:"center"}}
                         onClick={() =>{
                          console.log("check history",window.history.length,window.location.pathname?.includes('communityPreview'),window.location.pathname?.includes('feeds'))
                          if (window.history.length === 1  && (window.location.pathname?.includes('communityPreview') || window.location.pathname?.includes('feeds'))) {
                            console.log("navigating to feeds")
                              navigate('/feeds')
                          }else{
                            window.history.back()
                          }}}>
                          <LeftOutlined className="text-white" />
                          <span className="mobileLogoText">VamshaVruksha</span>
                        </button>
                      </div>
                    ) : (
                      <div className="leftNavAndHeaderWrapper " style={{width:"100%"}}>
                        {window.location.pathname !== "/login" ? (
                          <div
                            className="leftNavShow"
                            onClick={(e) => toggleLeftNav()}
                          >
                            <img src={whiteHamburger} alt="" />
                          </div>
                        ) : (
                          <></>
                        )}

                        <div className="responsiveNav">
                          <Home
                            isLeftNavVisible={isLeftNavVisible}
                            toggleLeftNav={toggleLeftNav}
                          />
                        </div>
                        <div
                          className="showlogomobile headerlogo flex flex-row"
                          style={{width:"100%"}}
                          onClick={() =>{
                            if(currentUser !== "ADMIN"){
                             navigate("/")}}}
                        >
                          {" "}
                          {/* <img src={navlogo} alt="" /> */}
                          <span className="mobileLogoText">
                            VamshaVruksha
                          </span>{" "}
                        </div>
                        <div className="showlogodesktop">
                          {" "}
                          {currentUser === "ADMIN" ?
                          <span className="vamshavrukshaHeading">
                          VamshaVruksha
                        </span>
                        :
                          <Link to={"/"}>
                            <span className="vamshavrukshaHeading">
                              VamshaVruksha
                            </span>
                          </Link>}
                        </div>
                        <ul className="HeaderUL">
                          {accessToken?.length && currentUser !== "ADMIN" ? (
                            <>
                              <div
                                ref={requestRef}
                                className="messageBox ml-2 relative bi bi-envelope-fill text-white cursor-pointer showlogodesktop"
                                onClick={() => {
                                  // setRequestPopup(true);
                                  navigate("/requests");
                                }}
                              >
                                {requestPopup ? (
                                  <div className="receivedePopup absolute w-24 bg-white text-black  z-10 shadow-lg rounded-md p-3">
                                    <Received />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>{" "}
                              <div
                                ref={sentRef}
                                className="bellicon relative showlogodesktop"
                                onClick={() => {
                                  navigate("/notification");
                                }}
                              >
                                <img
                                  src={bellicon}
                                  alt=""
                                  className="cursor-pointer bellIcon"
                                />
                                {sentPopup ? (
                                  <div className="receivedePopup absolute w-24 bg-white top-10 right-20 z-10 shadow-lg rounded-md p-3">
                                    <Sent />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>{" "}
                            </>
                          ) : (
                            <></>
                          )}

                          {accessToken && localStorage.getItem("name") ? (
                            <div ref={divRef}>
                              <Popover
                                content={popOverContents}
                                placement="bottomRight"
                              >
                                {profileUrl !== "null" ? (
                                  <div className="relative cursor-pointer flex items-center justify-center showlogodesktop">
                                    <img
                                      src={profileUrl}
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                      }}
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor:
                                        localStorage.getItem(
                                          "profileColorCode"
                                        ) || "#fde3cf",
                                    }}
                                    className=" showlogodesktop capitalize relative cursor-pointer  text-white dark:text-gray-300  w-10 h-10 p-1 rounded-full ring-2  dark:ring-gray-500 flex items-center justify-center"
                                    onClick={() => {
                                      setProfileClick(true);
                                    }}
                                  >
                                    {localStorage
                                      ?.getItem("name")
                                      ?.substring(0, 1)
                                       ||
                                      userInfo?.displayName?.substring(0, 1) ||
                                      userInfo?.email?.substring(0, 1)
                                      }
                                  </div>
                                )}
                              </Popover>
                            </div>
                          ) : (
                            <>
                              {window.location.pathname !== "/login" ? (
                                <ul className="linksDiv">
                                  <Link
                                    id="logId"
                                    onClick={onLogClick}
                                    to={"/login"}
                                    className=""
                                  >
                                    {accessToken?.length ? "Logout" : "Login"}
                                  </Link>
                                  {/* <span>|</span>
              <li>
                <Link to={"/signUp"}>Sign up</Link>
              </li> */}
                                </ul>
                              ) : (
                                <> </>
                              )}
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="leftNavAndHeaderWrapper ">
              {window.location.pathname !== "/login" ? (
                <div className="leftNavShow" onClick={(e) => toggleLeftNav()}>
                  <img src={whiteHamburger} alt="" />
                </div>
              ) : (
                <></>
              )}

              <div className="responsiveNav">
                <Home
                  isLeftNavVisible={isLeftNavVisible}
                  toggleLeftNav={toggleLeftNav}
                />
              </div>
              <div className="showlogomobile headerlogo">
                {" "}
                <img src={navlogo} alt="" />{" "}
              </div>

              <div className="showlogodesktop">
                {" "}
                {currentUser === "ADMIN" ?
                  <span className="vamshavrukshaHeading">VamshaVruksha</span>
                  :
                  <Link to={"/"}>
                  <span className="vamshavrukshaHeading">VamshaVruksha</span>
                </Link>}
              </div>
              <ul className="HeaderUL">
                {accessToken?.length && currentUser !== "ADMIN" ? (
                  <>
                    <div
                      ref={requestRef}
                      className="messageBox ml-2 relative bi bi-envelope-fill text-white text-2xl cursor-pointer showlogodesktop"
                      onClick={() => {
                        // setRequestPopup(true);
                        navigate("/requests");
                      }}
                    >
                      {requestPopup ? (
                        <div className="receivedePopup absolute w-24 bg-white text-black  z-10 shadow-lg rounded-md  p-3">
                          <Received />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>{" "}
                    <div
                      ref={sentRef}
                      className="bellicon relative showlogodesktop"
                      onClick={() => {
                        // setSentPopup(true);
                        navigate("/notification");
                      }}
                    >
                      <img
                        src={bellicon}
                        alt=""
                        className="cursor-pointer bellIcon"
                      />
                      {sentPopup ? (
                        <div className="receivedePopup absolute w-24 bg-white top-10 right-20 z-10 shadow-lg rounded-md p-3">
                          <Sent />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>{" "}
                  </>
                ) : (
                  <></>
                )}

                {accessToken && localStorage.getItem("userExist") === "true" ? (
                  <div ref={divRef}>
                    <Popover content={popOverContents} placement="bottomRight">
                      {profileUrl !== "null" ? (
                        <div className="relative cursor-pointer flex items-center justify-center showlogodesktop">
                          <img
                            src={profileUrl}
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor:
                              localStorage.getItem("profileColorCode") ||
                              "#fde3cf",
                          }}
                          className=" showlogodesktop capitalize relative cursor-pointer  text-white dark:text-gray-300 p-1 rounded-full ring-2  dark:ring-gray-500 flex items-center justify-center profileIcon"
                          onClick={() => {
                            setProfileClick(true);
                          }}
                        >
                          {localStorage?.getItem("name")?.substring(0, 1) || userInfo?.email?.substring(0, 1) ||
                            userInfo?.displayName?.substring(0, 1)
                            }
                        </div>
                      )}
                    </Popover>
                  </div>
                ) : (
                  <>
                    {window.location.pathname !== "/login" ? (
                      <ul className="linksDiv">
                        <Link
                          id="logId"
                          onClick={onLogClick}
                          to={"/login"}
                          className=""
                        >
                          {accessToken?.length ? "Logout" : "Login"}
                        </Link>
                        {/* <span>|</span>
              <li>
                <Link to={"/signUp"}>Sign up</Link>
              </li> */}
                      </ul>
                    ) : (
                      <> </>
                    )}
                  </>
                )}
              </ul>
            </div>
          )}
          <div
            className={isLeftNavVisible ? "overlay show" : "overlay"}
            onClick={() => setIsLeftNavVisible(false)}
          ></div>
        </div>
      ) : (
        <></>
      )}
      {internetSpeed && isMobileOnly && (
        <div className="bg-[#FFA713] h-8 w-full flex justify-center items-center z-10">
          <p className="text-white">Low network</p>
        </div>
      )}
    </div>
  );
};

export default Header;
