import React, { useEffect } from "react";
// import MultiStepForm from "../Components/MatrimonyForms/MultiStepForm";
import { useNavigate } from "react-router-dom";
// import { Button } from "antd";
import MatrimonyTab from "../Components/MatrimonyTabs/MatrimonyTab";
import { toast } from "react-toastify";
import { request } from "../Utils/request";

const Matrimony = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const profileScore = localStorage.getItem("profileScore");

  const meApiCall = async () => {
    const meApiResp = await request("/member/current/me");
    localStorage.setItem("profileScore", meApiResp?.profileScore);
    if (meApiResp?.profileScore !== 100) {
      navigate('/profile')
    }
    return
  }

  useEffect(() => {
    if (!token?.length) {
      localStorage.clear();
      navigate("/login");
    }
    if(profileScore === null){
      meApiCall()
    }
    else if (token?.length && profileScore !== "100") {
      navigate("/profile");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="">
      <div className="containerWithOutSideBar">
        <div className="mainContentCol">
          <div className="">
            {token?.length && (
              <div className="">
                <div className="">
                  <div className="tabPanelBox" style={{ padding: "0" }}>
                    <MatrimonyTab />
                  </div>
                  <div className="topPanelHead"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Matrimony;
