import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import {
  Modal,
  Form,
  Select,
  Button,
  Input,
  Space,
  Switch,
  Checkbox,
  Tabs,
} from "antd";
import type { TabsProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import icons from "../../assets/hobbiesIcons/icons";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import "./FeedsComponentPopup.css";
import { isMobile } from "../../Utils/mobileSettings";
import { request, multiFormRequest } from "../../Utils/request";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import { getToken } from "../../Utils/timeago";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import PostText from "./PostText";
import { Option } from "antd/es/mentions";
import LinkPreview from "./LinkPreview";
import FeedsPoll from "./FeedsPoll";
import FormItem from "antd/es/form/FormItem";
import Compressor from "compressorjs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

interface createFeedsPopupProps {
  onCancelClick: Function;
  onSaveClick: Function;
  communityPostContent?: any;
  metaData?: any;
  from?: string;
  defaultCommunity?: string;
  tags?: any[];
}

interface valuesProps {
  categoryId?: string;
  type?: string;
  postContent?: string;
  askQuestion?: string;
  addComment?: string;
  days?: string;
  hours?: string;
  poll?: boolean;
  options?: any[];
  showVoters?: boolean;
  multipleOptions?: boolean;
  tags?: any[];
}
const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

const FeedsComponentPopup = (props: createFeedsPopupProps) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [form] = Form.useForm();
  const [abusiveLoader, setAbusiveLoader] = useState<Boolean>(false);
  const [isUploadDisabled, setIiUploadDisabled] = useState<number>(0);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [updatedSelectedFiles, setUpdatedSelectedFiles] = useState<File[]>([]);
  const [fileURLS, setFileURLS] = useState<any[]>([]);
  const [youtubeURLS, setYoutubeURLS] = useState<any[]>([]);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user") ?? "null");
  const [saveDisable, setSaveDisable] = useState(true);
  const [mediaArray, setMediaArray] = useState<any>([]);
  const [text, setText] = useState<any>("");
  const [pollQuestion, setPollQuestion] = useState<any>("");
  const [pollComment, setPollComment] = useState<any>("");
  const [postContentUrl, setPostContentUrl] = useState();
  const [urls, setUrls] = useState<string[]>([]);
  const [content, setContent] = useState("");
  const test = useRef<HTMLDivElement>(null);
  const id = localStorage.getItem("id");
  const [postType, setPostType] = useState("post");
  const [optionIndex, setOptionIndex] = useState(0);
  const [currentDay, setCurrentDay] = useState(0);
  const [selectedPollType, setSelectedPollType] = useState(true);
  const [multipleSelect, setMultipleSelect] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [tagOptions, setTagOptions] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [communityTags, setCommunityTags] = useState<any>(props?.tags || []);
  const [searchTerm, setSearchTerm] = useState<string>("");
  // const [selectedCommunity, setSelectedCommunity] = useState<any>("");
  const [popupCommunityList, setPopupCommunityList] = useState<any>([]);
  const [communityListLoader, setCommunityListLoader] = useState<Boolean>(true);

  // const [activeTab,setActiveTab] = useState('post')

  const toolbarOptions = [
    [{ header: [3, 4, 5, 6, false] }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    // [{ font: [] }],
    [{ align: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["clean"],
  ];

  const modules = {
    toolbar: toolbarOptions,
  };

  const items = [
    {
      key: "post",
      label: `Create Post`,
    },
    {
      key: "poll",
      label: `Create Poll`,
    },
  ];

  const getCommunityCategories = async () => {
    try {
      const communityResp = await request(`/member/current/communities-list`);
      setPopupCommunityList(communityResp ?? []);
      if (communityResp && props?.defaultCommunity) {
        const community = communityResp.filter(
          (community: any) => community.communityId === props?.defaultCommunity
        );
        setCommunityTags(community[0]?.tags ?? []);
        form.setFieldsValue({ tags: community[0]?.tags });
      }
      setCommunityListLoader(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getCommunityCategories();
  }, []);

  const handleChange = (value: any) => {
    if (value.length <= 3) {
      setSelectedTags(value);
      handleSearch("");
      formValidation();
    }
    // setSelectedTags(value);
    // handleSearch(''); // Clear the search term
    // formValidation();
  };
  useEffect(() => {
    handleSearch("");
  }, [props?.tags]);

  const handleSearch = async (searchTerm: any) => {
    setSearchTerm(searchTerm);
    const tagsResp = await request(`/member/tags?searchTerm=${searchTerm}`);
    setTagOptions(tagsResp ?? []);
  };
  // const getTags = async () => {
  //   const tagsResp = await request(
  //     `/member/tags`
  //   );
  //   // const communityDropdownResp:any =  await axios.get(`${BASE_URL}/member/current/communities-list`,{
  //   //   headers:{
  //   //     Authorization: 'Bearer ' + token
  //   //   }
  //   // })

  //   setTagOptions(tagsResp ?? []);
  //   // setCommunityListLoader(false);
  // };

  const imageURL: any = [];
  useEffect(() => {
    const res = getToken();
    // getTags()
  }, []);
  useEffect(() => {
    const newupdatedSelectedFiles = [...selectedFiles];
    let type = "";
    props?.metaData?.forEach((metadata: any) => {
      if (metadata.type === "photo") {
        type = "image/url";
      } else {
        type = "";
      }
      const fileWithMetadata = {
        file: new File([], (metadata.name = metadata?.url?.toString()), {
          type: type,
        }),
      };
      newupdatedSelectedFiles.push(fileWithMetadata?.file);
      imageURL.push(metadata?.url);
    });

    // setSelectedFiles(newupdatedSelectedFiles);
    setUpdatedSelectedFiles(newupdatedSelectedFiles);
    setFileURLS(imageURL);
  }, [props?.metaData]);

  useEffect(() => {
    formValidation();
  }, [selectedFiles, postType]);

  const onFinish = async (values: valuesProps) => {
    let pollBlob: any;
    let abusiveResult: any;
    setAbusiveLoader(true);
    let imageFormData = new FormData();

    if (selectedFiles) {
      selectedFiles.forEach((file, index) => {
        imageFormData.append(
          `files`,
          new Blob([file]),
          file.name.toLowerCase()
        );
      });
    }
    if (
      postType === "post" &&
      id &&
      values?.categoryId &&
      values?.postContent
    ) {
      pollBlob = new Blob(
        [
          JSON.stringify({
            userId: id,
            type: "POST",
            communityId: values?.categoryId,
            content: addHttpToHref(values?.postContent),
            fileURL: fileURLS,
            youtubeURL: youtubeURLS,
            tags: values?.tags ? values?.tags : [],
          }),
        ],
        {
          type: "application/json",
        }
      );

      imageFormData.append("data", pollBlob);
    }

    if (postType === "poll" && values?.categoryId && values?.askQuestion) {
      pollBlob = new Blob(
        [
          JSON.stringify({
            communityId: values?.categoryId,
            type: "POLL",
            pollInput: {
              question: values?.askQuestion,
              comment: values?.addComment,
              expiryDays: values?.days || 0,
              expiryHours: values?.hours || 0,
              pollTypeEnum: values?.poll ? "OPEN" : "HIDDEN",
              options:
                values?.options?.map((item: any) => item?.option?.trim()) || [],
              selectTypeEnum:
                values?.multipleOptions || multipleSelect
                  ? "MULTI_SELECT"
                  : "SINGLE_SELECT",
              showVoters: values?.showVoters || false,
            },
          }),
        ],
        {
          type: "application/json",
        }
      );
      imageFormData.append("data", pollBlob);
    }

    abusiveResult =
      postType === "post"
        ? await CheckBadWordsExist(values.postContent)
        : await CheckBadWordsExist(values.askQuestion);
    if (abusiveResult?.status === true) {
      if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
        try {
          const resp: any = await multiFormRequest(
            `/member/current/post`,
            imageFormData,
            "post"
          );
          if (resp) {
            setAbusiveLoader(false);
            if (resp?.status === 200) {
              toast.success("Post has been successfully published");
            } else if (resp?.data?.status === "REQUEST") {
              toast.success("Post request successfully dispatched");
            } else if (resp?.response?.status === 400) {
              toast.error(resp?.response?.data?.message);
            }
            props?.onSaveClick();
          } else {
            setAbusiveLoader(false);
            toast.error("Unsuccessful attempt at post creation");
          }
        } catch (error: any) {
          setAbusiveLoader(false);
          if (error?.response?.status === 500) {
            toast.error("Internal server error");
          }
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          }
          setAbusiveLoader(false);
        }
      } else if (abusiveResult?.profanity) {
        setAbusiveLoader(false);
        toast.error("Prohibited from generating abusive content");
      }
    } else {
      setAbusiveLoader(false);
      toast.error(abusiveResult?.message || "Something went wrong");
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files ?? []);

    const newFiles: any = files.filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      );
    });

    let selectedImages = selectedFiles.filter((file) =>
      file?.type?.startsWith("image/")
    );
    let selectedVideos = selectedFiles.filter((file) =>
      file?.type?.startsWith("video/")
    );
    let updatedSelectedImages = updatedSelectedFiles.filter((file) =>
      file?.type?.startsWith("image/")
    );
    let updatedSelectedVideos = updatedSelectedFiles.filter((file) =>
      file?.type?.startsWith("video/")
    );

    for (let i = 0; i < newFiles?.length; i++) {
      const file = newFiles[i];
      if (!(file?.type?.includes("image/") || file?.type?.includes("video/"))) {
        toast.error("Invalid format");
        return;
      }
      if (
        file?.type?.startsWith("image/") &&
        updatedSelectedImages?.length < 3
      ) {
        if (file.size > 1024 * 1024 && file.size / (1024 * 1024) < 10) {
          setImageLoading(true);
          try {
            const compressedResult: File = await new Promise(
              (resolve, reject) => {
                new Compressor(file, {
                  quality: 0.6,
                  success: (compressedFile: any) => {
                    if (compressedFile instanceof File) {
                      return resolve(compressedFile);
                    } else {
                      const compressedFileFromBlob = new File(
                        [compressedFile],
                        file.name,
                        {
                          type: compressedFile.type,
                        }
                      );
                      return resolve(compressedFileFromBlob);
                    }
                  },
                  error: (err: any) => {
                    reject(err);
                    toast.error("File upload failed. Please try again later.");
                  },
                });
              }
            );

            selectedImages.push(compressedResult);
            setImageLoading(false);
          } catch (error) {
            console.error("Compression error:", error);
            setImageLoading(false);
          }
        } else {
          selectedImages.push(file);
          setImageLoading(false);
        }
        setImageLoading(false);
      } else if (
        file.type.startsWith("image/") &&
        updatedSelectedImages?.length === 3
      ) {
        toast.error("You are allowed a maximum of three images");
      }

      if (
        file?.type?.startsWith("video/") &&
        updatedSelectedVideos?.length < 3
      ) {
        selectedVideos.push(file);
      } else if (
        file.type.startsWith("video/") &&
        updatedSelectedVideos?.length === 3
      ) {
        toast.error("You are allowed a maximum of three videos");
      }
    }
    let newArray = [...mediaArray];
    files.forEach((item: any) => {
      const formattedSize: number = item?.size / (1024 * 1024);
      if (
        (item.type.startsWith("image/") && formattedSize > 10) ||
        (item.type.startsWith("video/") && formattedSize > 50)
      ) {
        toast.error(
          `${item.name} ${
            item.type.startsWith("image") && formattedSize > 10
              ? "Exceeds 10 megabytes"
              : "Exceeds 50 megabytes"
          }   `
        );
        newArray = [
          ...newArray,
          {
            name: item.name,
            hasError: true,
          },
        ];
      }
    });
    setMediaArray(newArray);

    setSelectedFiles([...selectedImages, ...selectedVideos]);
    const isUploadDisabled = selectedImages?.length + selectedVideos?.length;
    setIiUploadDisabled(isUploadDisabled);

    if (
      updatedSelectedImages?.length < 3 ||
      updatedSelectedVideos?.length < 3
    ) {
      setUpdatedSelectedFiles([...updatedSelectedFiles, ...newFiles]);
    }
    formValidation();
  };

  const handleDelete = (index: number) => {
    const mediaArrayCopy = [...mediaArray];
    let deletingFileName = mediaArrayCopy.find(
      (item) => item?.name === updatedSelectedFiles[index]?.name
    )?.name;

    if (deletingFileName?.length) {
      const mediaArrayIndex = mediaArray.findIndex(
        (item: any) => item?.name === updatedSelectedFiles[index]?.name
      );
      mediaArrayCopy.splice(mediaArrayIndex, 1);
      setMediaArray(mediaArrayCopy);
    }

    const updatedFiles = [...updatedSelectedFiles];
    const deletedFile = updatedFiles[index];
    const isFileInSelectedFiles = selectedFiles.includes(selectedFiles[index]);
    const isFileInFileURLS = fileURLS.includes(deletedFile.name);

    if (isFileInSelectedFiles) {
      setSelectedFiles((prevSelectedFiles) => {
        const updatedSelected = [...prevSelectedFiles];
        const fileIndex = updatedSelected.findIndex(
          (file) => file === selectedFiles[index]
        );
        if (fileIndex !== -1) {
          updatedSelected.splice(fileIndex, 1);
        }
        return updatedSelected;
      });
    }

    if (isFileInFileURLS) {
      setFileURLS((prevFileURLS) => {
        const updatedURLS = [...prevFileURLS];
        const fileIndex = updatedURLS.findIndex(
          (url) => url === deletedFile.name
        );
        if (fileIndex !== -1) {
          updatedURLS.splice(fileIndex, 1);
        }
        return updatedURLS;
      });
    }

    updatedFiles.splice(index, 1);
    setUpdatedSelectedFiles(updatedFiles);
    formValidation();
  };

  const HandleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const formValidation = () => {
    if (postType === "post") {
      const postContent = form?.getFieldValue("postContent");
      const categoryId = form?.getFieldValue("categoryId");
      if (postContent && categoryId) {
        setSaveDisable(mediaArray.some((item: any) => item?.hasError));
      } else {
        setSaveDisable(true);
      }
    } else if (postType === "poll") {
      const pollQuestion = form?.getFieldValue("askQuestion");
      const categoryId = form?.getFieldValue("categoryId");
      const options = form?.getFieldValue("options");
      setOptionIndex(options.length);
      if (
        pollQuestion &&
        options[0]?.option?.length &&
        options[1]?.option?.length &&
        categoryId
      ) {
        // if (pollQuestion) {
        setSaveDisable(false);
      } else {
        setSaveDisable(true);
      }
    }
  };

  function addHttpToHref(htmlString: any) {
    const hrefRegex = /href="(?!https)([^"]*?)"/g;

    // Replace function to add https:// if not already present
    return htmlString.replace(hrefRegex, (match: any, p1: any) => {
      return `href="https://${p1}"`;
    });
  }

  function getVideoId(url: any) {
    const patterns = [
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch(?:\/|.+?)?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([a-zA-Z0-9_-]{11}).*/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11}).*/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  }

  function removeDuplicatesByVideoId(urls: any) {
    const uniqueVideoIds = new Set();
    const uniqueUrls: any = [];

    urls.forEach((url: any) => {
      const videoId = getVideoId(url);
      if (!uniqueVideoIds.has(videoId)) {
        uniqueVideoIds.add(videoId);
        uniqueUrls.push(url);
      }
    });

    return uniqueUrls;
  }

  const stripHtmlTags = (text:any) => {
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  };

  const handleInputChange = (
    e: any | null | undefined,
    from: any | null | undefined
  ) => {
    const textContent = e || ""; 
  
    // Step 1: Extract URLs from <a> tags
    let extractedUrlsFromATags = Array.from(
      textContent.matchAll(/<a[^>]+href="([^"]+)"[^>]*>([^<]+)<\/a>/g),
      (match:any) => match[1]
    );
    // Step 2: Strip HTML tags from remaining content
    let cleanText = stripHtmlTags(textContent);
  // Step 3: Ensure there's a space between concatenated URLs
  cleanText = cleanText.replace(/(https?:\/\/)/g, " $1");
  // Step 4: Split by spaces to handle multiple URLs in a single string
  let potentialUrls = cleanText.split(/\s+/);
    // Step 5: Apply the regex to each split part to identify valid URLs
    const extractedUrlsFromCleanText = potentialUrls.filter((url:any) => 
      url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch(?:\/|.+?)?v=|embed\/|v\/|.+\?v=|live\/|shorts\/)|youtu\.be\/[a-zA-Z0-9_-]{11})[^&\s]*/)
    );
    // Combine all extracted URLs
    const allExtractedUrls = [...extractedUrlsFromATags, ...extractedUrlsFromCleanText];
    // Remove duplicates
    const uniqueUrls = removeDuplicatesByVideoId(allExtractedUrls);

    // Update state
    setUrls(uniqueUrls);
    setYoutubeURLS(uniqueUrls);
    setText(textContent);
  };
  

  useEffect(() => {
    if (props?.communityPostContent) {
      handleInputChange(props?.communityPostContent, "SHARE");
    }
  }, [props?.communityPostContent]);


  return (
    <div>
      <div id="postPopup">
        <Modal
          className="postPopup"
          title={
            props.from === "CommunityPosts"
              ? `Share Post`
              : `What's on your mind, ${
                  localStorage.getItem("name")?.length
                    ? localStorage.getItem("name")
                    : ""
                }?`
          }
          open
          onCancel={() => {
            props.onCancelClick();
          }}
          style={{ width: "850px", top: 20 }}
          footer
        >
          <div className="relative">
            {props.from !== "CommunityPosts" && (
              <div className="select-Dropdown">
                <div className="tabsWrapper">
                  {items?.map((tabOptions: any) => (
                    <div
                      className={`${
                        tabOptions?.key === postType ? "activeTab" : ""
                      } optionsTab`}
                      onClick={() => {
                        setPostType(tabOptions?.key);
                      }}
                    >
                      <p>{tabOptions?.label}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <Form
              form={form}
              onFinish={(values) => {
                onFinish(values);
              }}
              onChange={formValidation}
              className={`postForm ${
                abusiveLoader || communityListLoader ? "blurLoader" : ""
              }`}
              name="createForm"
              labelCol={{ span: 8 }}
              initialValues={{
                postContent: props?.communityPostContent,
                days: 0,
                hours: currentDay === 0 ? 1 : 0,
                poll: true,
                showVoters: false,
                multipleOptions: false,
                categoryId: props?.defaultCommunity,
                tags: communityTags,
              }}
            >
              {(abusiveLoader || imageLoading || communityListLoader) && (
                <div className="loader">
                  <Modal className="loaderModal" open centered footer>
                    <Loader />
                  </Modal>
                </div>
              )}

              {postType === "poll" ? (
                <div className="mt-2">
                  <div className="flex flex-row w-full justify-between toggleContainer">
                    <div className="switchPost">
                      <Form.Item
                        name="poll"
                        // label="Type of post"
                        label={
                          <div className="flex flex-row items-center">
                            <span className="text-md poll-label ">
                              {" "}
                              Type of poll
                            </span>
                          </div>
                        }
                        rules={[
                          {
                            required: false,
                            message: "select if you want to show poll ",
                          },
                        ]}
                      >
                        <Switch
                          checkedChildren="Open"
                          unCheckedChildren="Closed"
                          className=" w-17 bg-[#959595]"
                          defaultChecked={true}
                          onChange={() =>
                            setSelectedPollType(!selectedPollType)
                          }
                        />
                      </Form.Item>
                    </div>
                    {selectedPollType && (
                      <div className="switchPost">
                        <Form.Item
                          name="showVoters"
                          // label="Type of post"
                          label={
                            <div className="flex flex-row items-center">
                              <span className="text-md poll-label ">
                                {" "}
                                Show voters
                              </span>
                            </div>
                          }
                          rules={[
                            {
                              required: false,
                              message: "select if you want to show voters ",
                            },
                          ]}
                        >
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            className=" w-17 bg-[#959595]"
                            defaultChecked={false}
                          />
                        </Form.Item>
                      </div>
                    )}
                  </div>
                  <Form.Item
                    className="FeedsLabelName"
                    label="Choose a community"
                    name="categoryId"
                    // rules={[{ required: true, message: "Select community " }]}
                  >
                    <Select
                      className="FeedsDropDown"
                      onChange={() => {
                        formValidation();
                      }}
                      disabled={props?.defaultCommunity ? true : false}
                      notFoundContent={"Please join a community"}
                    >
                      {popupCommunityList?.length ? (
                        <>
                          {popupCommunityList?.map((category: any) => (
                            <Select.Option
                              className="selectOptions"
                              key={category?.communityId}
                              value={category?.communityId}
                              disabled={
                                category?.block || category?.communityBlocked
                              }
                            >
                              <span className="categoryDropdown">
                                {category?.name || "bb"}
                              </span>
                            </Select.Option>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </Select>
                  </Form.Item>

                  <Form.Item name="askQuestion">
                    <Input
                      className="input-box"
                      placeholder="Ask a question"
                      maxLength={200}
                      onChange={(e) => setPollQuestion(e.target.value)}
                    />
                  </Form.Item>
                  {pollQuestion?.length > 0 && (
                    <p className="remainingCharacters">
                      {pollQuestion?.length} / 200
                    </p>
                  )}
                  <Form.Item name="addComment">
                    <Input
                      className="input-box"
                      placeholder="Add a comment"
                      maxLength={200}
                      onChange={(e) => setPollComment(e.target.value)}
                    />
                  </Form.Item>
                  {pollComment?.length > 0 && (
                    <p className="remainingCharacters">
                      {pollComment?.length} / 200
                    </p>
                  )}

                  <div className="optionsList">
                    <Form.List name="options" initialValue={[{}, {}]}>
                      {(fields, { add, remove }) => (
                        <div className="options-list">
                          {fields.map(({ key, name, ...restField }, index) => (
                            <Space
                              key={key}
                              style={{ display: "flex", marginBottom: 8 }}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "option"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing Option",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      const optionsArray =
                                        getFieldValue(`options`);

                                      let currentOptionDuplicate =
                                        optionsArray.find(
                                          (option: any, index: Number) =>
                                            option?.option?.toLowerCase() ===
                                              value?.toLowerCase() &&
                                            index !== name
                                        );
                                      if (currentOptionDuplicate) {
                                        setSaveDisable(true);
                                        return Promise.reject(
                                          new Error("No duplicates are allowed")
                                        );
                                      }
                                      setSaveDisable(false);

                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  name={`option${index + 1}`}
                                  className="input-box"
                                  placeholder={`Option ${index + 1}`}
                                />
                              </Form.Item>
                              {index >= 2 ? (
                                // <MinusCircleOutlined onClick={() => remove(name)} />
                                <span
                                  className="bi bi-x text-xl  cursor-pointer"
                                  onClick={() => {
                                    remove(name);
                                    formValidation();
                                  }}
                                ></span>
                              ) : null}
                            </Space>
                          ))}
                          {optionIndex !== 10 && (
                            <div className="dynamic-options">
                              <div>
                                <Form.Item>
                                  <div
                                    className="text-[#a3c844] cursor-pointer poll-label  "
                                    // type="dashed"
                                    onClick={() => {
                                      add();
                                      formValidation();
                                    }}
                                  >
                                    + Add an option
                                  </div>
                                </Form.Item>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Form.List>
                  </div>

                  <div className="flex font-semibold w-full text-end pollCheckbox">
                    <div>
                      <Form.Item
                        name="multipleOptions"
                        // label="Let people select multiple options"
                      >
                        <Checkbox
                          className=""
                          defaultChecked={multipleSelect}
                          onChange={(e) => {
                            setMultipleSelect(e?.target?.checked);
                          }}
                        >
                          Let people select multiple options
                        </Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                  <div className="poll-expire">
                    <div className="mr-2">
                      <Form.Item
                        className="poll-label"
                        name="days"
                        label="Poll Expires in: Days"
                      >
                        <Select
                          defaultValue={0}
                          placement="topRight"
                          className="select-type-dropdown-exp"
                          placeholder="Select Days"
                          onChange={(e: any) => {
                            setCurrentDay(e);
                          }}
                        >
                          {[...Array(15)].map((item, index) => (
                            <>
                              <Select.Option key={index} value={index}>
                                {index}
                              </Select.Option>
                            </>
                          ))}

                          {/* Add more options as needed */}
                        </Select>
                      </Form.Item>
                    </div>

                    <div>
                      <Form.Item
                        className="poll-label"
                        name="hours"
                        label="Hours"
                      >
                        <Select
                          placement="topRight"
                          defaultValue={currentDay === 0 ? 1 : 0}
                          className="select-type-dropdown-exp"
                          placeholder="Select hours"
                        >
                          {[...Array(23)].map((item, ind) => (
                            <>
                              <Select.Option key={ind + 1} value={ind + 1}>
                                {/* {ind+1} */}
                                {currentDay === 0 ? ind + 1 : ind}
                              </Select.Option>
                            </>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-6">
                  <div style={{ width: "100%" }}>
                    {urls[0]?.length > 0 && text?.length > 0 && (
                      <div className="feedsLinkContainer">
                        <LinkPreview url={urls[0]} from={"CREATE"} />
                      </div>
                    )}
                    <Form.Item
                      className="FeedsLabelName"
                      label="Share your message"
                      name="postContent"
                      rules={[
                        {
                          required: true,
                          message: "Some information about post",
                        },
                      ]}
                    >
                      {/* <TextArea
                        className="FeedsCommunityDescription"
                        onChange={(e) => handleInputChange(e, "INPUT")}
                        style={{
                          overflowX: "hidden",
                          overflowY: "scroll",
                          width: "100%",
                        }}
                        maxLength={user?.email === "info@vamshavruksha.in" ? undefined : 1000}
                      /> */}
                      <ReactQuill
                        theme="snow"
                        style={{}}
                        modules={modules}
                        className="scrollbar-hide shareMessageInput react-quill"
                        value={text?.length > 0 ? text : ""}
                        onChange={(
                          e: any,
                          delta: any,
                          source: string,
                          editor: any
                        ) => {
                          let textContent = editor?.getText()?.trim();
                          if (textContent.length > 0) {
                            handleInputChange(e, "INPUT");
                            setSaveDisable(false);
                          } else {
                            setSaveDisable(true);
                          }
                        }}
                      />
                    </Form.Item>
                    {user?.email !== "info@vamshavruksha.in" &&
                      text?.length > 0 && (
                        <p className="remainingCharacters">
                          {text?.length} / 1000
                        </p>
                      )}
                  </div>
                  <Form.Item
                    className="FeedsLabelName"
                    label="Choose a community"
                    name="categoryId"
                    rules={[{ required: true, message: "Select community " }]}
                  >
                    <Select
                      className="FeedsDropDown"
                      onChange={(value: any) => {
                        let communityTagsData = popupCommunityList?.filter(
                          (community: any) => community?.communityId === value
                        )[0]?.tags;
                        if (!props?.tags || props?.tags.length === 0) {
                          setCommunityTags(communityTagsData);
                          form.setFieldsValue({ tags: communityTagsData });
                          handleSearch("");
                        }
                        formValidation();
                      }}
                      disabled={props?.defaultCommunity ? true : false}
                    >
                      {popupCommunityList?.length ? (
                        <>
                          {popupCommunityList
                            ?.filter(
                              (com: any) =>
                                com?.admin ||
                                (!com.admin && !com?.postsOnlyByAdmin)
                            )
                            ?.map((category: any) => (
                              <Select.Option
                                className="selectOptions"
                                key={category?.communityId}
                                value={category?.communityId}
                                disabled={
                                  category?.block || category?.communityBlocked
                                }
                              >
                                <span className="categoryDropdown">
                                  {category?.name || "bb"}
                                </span>
                              </Select.Option>
                            ))}
                        </>
                      ) : (
                        ""
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="TagsLabelName FeedsDropDown"
                    label="Choose tags"
                    name="tags"
                    rules={[{ required: false, message: "Select tags " }]}
                  >
                    <Select
                      className="TagsDropDown"
                      onChange={handleChange}
                      allowClear
                      showSearch
                      mode="multiple"
                      maxTagCount={"responsive"}
                      onSearch={handleSearch}
                      filterOption={false}
                      defaultValue={communityTags}
                      autoClearSearchValue={true}
                      notFoundContent={
                        searchTerm?.length > 0 ? (
                          <span style={{ color: "gray" }}>Tag not Found</span>
                        ) : (
                          <span style={{ color: "gray" }}>Search tags</span>
                        )
                      }
                    >
                      {/* {hobbyOptions.map((group) => (
                                        <Select.OptGroup
                                          key={group.label}
                                          label={<span style={{fontSize:"18px", fontWeight:"bold", color:"black"}}>{group.label}</span>}
                                        >
                                          {group.options.map((option) => (
                                            <Option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              <div className="flex">
                                                {option.icon} {option.label}
                                              </div>
                                            </Option>
                                          ))}
                                        </Select.OptGroup>
                                      ))} */}
                      {tagOptions?.map((group: any) => (
                        // <Select.OptGroup
                        //   key={group.name}
                        //   label={<span style={{fontSize:"18px", fontWeight:"bold", color:"black"}}>{group.label}</span>}
                        // >
                        <Option
                          key={group.name}
                          value={group.name}
                          disabled={
                            selectedTags.length >= 3 &&
                            !selectedTags.includes(group.name)
                          }
                        >
                          <div className="flex">{group.name}</div>
                        </Option>
                        // </Select.OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="wrapbuttons mt-10 pt-2">
                    <Form.Item className="my-3 flex justify-center items-center">
                      {isMobile ? (
                        <div
                          className=" popupImageButton  float-right "
                          onClick={(event) => {
                            HandleClick();
                            event.stopPropagation();
                          }}
                        >
                          <input
                            style={{ display: "none" }}
                            ref={hiddenFileInput}
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, video/*"
                            multiple
                            onChange={handleFileChange}
                            disabled={isUploadDisabled >= 6 ? true : false}
                          />
                          <i
                            className="bi bi-camera-fill"
                            style={{
                              background: "#686f2e",
                              color: "white",
                              padding: "7px 17px",
                              borderRadius: "5px",
                            }}
                          ></i>
                        </div>
                      ) : (
                        <div className="">
                          <div
                            className="text-center"
                            onClick={(event) => {
                              HandleClick();
                              event.stopPropagation();
                            }}
                          >
                            <input
                              style={{ display: "none" }}
                              ref={hiddenFileInput}
                              type="file"
                              accept="image/png, image/jpeg, image/jpg, video/*"
                              multiple
                              onChange={handleFileChange}
                              disabled={isUploadDisabled >= 6 ? true : false}
                            />
                            <i
                              className="bi bi-camera-fill cursor-pointer addImage"
                              style={{
                                // background: "#686f2e",
                                color: "gray",
                                padding: "2px 17px",
                                borderRadius: "5px",
                              }}
                            ></i>
                            <p className="text-gray pl-3">
                              Add an Image / Video
                            </p>
                          </div>
                        </div>
                      )}
                      {updatedSelectedFiles?.length > 0 && (
                        <div>
                          <ul className="flex gap-1 w-full flex-wrap justify-center">
                            {updatedSelectedFiles?.map((file, index) => (
                              <li
                                key={index}
                                className=" my-3 relative"
                                style={{
                                  width: `calc(100% / ${selectedFiles?.length} - 5%)`,
                                }}
                              >
                                {file?.type?.startsWith("image/") ? (
                                  file?.type?.includes("url") ? (
                                    <img
                                      src={file.name}
                                      alt={file.name}
                                      className="postImage h-[200px] w-full border border-gray-400 object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={file.name}
                                      className="postImage h-[150px] w-full border border-gray-400 object-cover"
                                    />
                                  )
                                ) : (
                                  <video
                                    src={URL.createObjectURL(file)}
                                    controls
                                    style={{ height: "100%" }}
                                  />
                                )}
                                <button
                                  type="button"
                                  className="bi bi-trash-fill text-[#7F4C31] text-xl selectfile-delete-icon"
                                  onClick={(event) => {
                                    handleDelete(index);
                                    event.stopPropagation();
                                  }}
                                ></button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {/* <div
                  className="BrowseButton popupImageButton  float-right "
                  onClick={(event) => {
                    HandleClick();
                    event.stopPropagation();
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, video/*"
                    multiple
                    onChange={handleFileChange}
                    disabled={isUploadDisabled >= 6 ? true : false}
                  />
                  Browse
                </div> */}
                    </Form.Item>
                  </div>
                </div>
              )}

              <Form.Item wrapperCol={{ offset: 20 }}>
                <Button
                  disabled={saveDisable}
                  className="popupButton postbutton"
                  type="primary"
                  htmlType="submit"
                >
                  {props.from === "CommunityPosts" ? "Share" : "Post"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default FeedsComponentPopup;
