import React, { useEffect, useState, useRef } from "react";
import {
  API_TIMEOUT_INTERVAL,
  cacheResponse,
  getCachedResponse,
  request,
} from "../../Utils/request";
import Loader from "../Loader/Loader";
import dayjs from "dayjs";
import banner from "../../assets/images/community-banner.png";
import "./UserProfile.css";
import location from "../../assets/matrimonyIcons/location.svg";
import work from "../../assets/matrimonyIcons/work or profession.svg";
import { Button, Form} from "antd";
import CommunityPosts from "../CommunityPreview/CommunityPosts";
import LatestActivity from "../CommunityPreview/LatestActivity";
import back from "../../assets/matrimonyIcons/back.svg";
import { useNavigate } from "react-router";
import UpdateProfile from "./UpdateProfile";
import date from "../../assets/matrimonyIcons/date.svg";
import privateCommunity from "../../assets/images/privateCommunity.png";
import family from "../../assets/svg icons/familyDetails.svg";
import { isMobile } from "../../Utils/mobileSettings";
import { Modal } from "antd";
import religion from "../../assets/matrimonyIcons/religion.svg";
import familyName from "../../assets/matrimonyIcons/family details.svg";
import icons from "../../assets/hobbiesIcons/icons";
import occupation from "../../assets/newUiImages/occupation.svg";
import university from "../../assets/newUiImages/university.svg";
import { EditOutlined } from "@ant-design/icons";
type Props = {
  uid?: string;
};


const creativeCategories = [
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.writing} />,
    hobby: "Writing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.cooking} />,
    hobby: "Cooking",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.singing} />,
    hobby: "Singing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.photography} />,
    hobby: "Photography",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.instrument} />,
    hobby: "Playing Instruments",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.art} />,
    hobby: "Painting",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.DIY} />,
    hobby: "DIY crafts",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.dancing} />,
    hobby: "Dancing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.acting} />,
    hobby: "Acting",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.poetry} />,
    hobby: "Poetry",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.poetry} />,
    hobby: "Book restoration",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.gardening} />,
    hobby: "Gardening",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.blogging} />,
    hobby: "Blogging",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.content} />,
    hobby: "Content creation",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.designing} />,
    hobby: "Designing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.doodling} />,
    hobby: "Crocheting",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.doodling} />,
    hobby: "Doodling",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.pets} />,
    hobby: "Pets",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.foodie} />,
    hobby: "Foodie",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.vegan} />,
    hobby: "Vegan",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.news} />,
    hobby: "News & Politics",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.socialService} />,
    hobby: "Social service",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.entre} />,
    hobby: "Entrepreneurship",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.homeDecor} />,
    hobby: "Home Decor",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.invest} />,
    hobby: "Investments",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.fashionBeauty} />,
    hobby: "Fashion & Beauty",
  },
];

const funCategories = [
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.movies} />,
    hobby: "Movies",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.music} />,
    hobby: "Music",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.travel} />,
    hobby: "Travelling",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.reading} />,
    hobby: "Reading",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.sports} />,
    hobby: "Sports",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.social} />,
    hobby: "Social Media",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.gaming} />,
    hobby: "Gaming",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.bingewatching} />,
    hobby: "Binge-watching",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.biking} />,
    hobby: "Biking",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.clubbing} />,
    hobby: "Clubbing",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.shopping} />,
    hobby: "Shopping",
  },
  // {
  //   icons: icons,
  //   hobby: "Theatre & Events"
  // },
  // {
  //   icons: icons,
  //   hobby: "Anime"
  // },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.standup} />,
    hobby: "Stand ups",
  },
];

const fitnessCategories = [
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.running} />,
    hobby: "Runnning",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.walking} />,
    hobby: "Walking",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.cycling} />,
    hobby: "Cycling",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.yoga} />,
    hobby: "Yoga & Meditation",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.hiking} />,
    hobby: "Trekking",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.workingOut} />,
    hobby: "Working Out",
  },
  {
    icon: <img alt="" style={{ height: "20px" }} src={icons.swimming} />,
    hobby: "Swimming",
  },
];

function getIconForHobby(hobby: string) {
  const allCategories = [
    ...creativeCategories,
    ...funCategories,
    ...fitnessCategories,
  ];

  const category = allCategories?.find((item) => item.hobby === hobby);
  return category ? category.icon : null;
}

interface Post {
  postId: string;
  metaData?: string[];
  youtubeURL?: string | null;
}

const UserProfile = (props: Props) => {
  const [image, setImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [profile, setProfile] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [postsLoader, setPostsLoader] = useState<Boolean>(true);
  const [posts, setPosts] = useState<any[]>([]);
  const [showFamilyMembersModal, setShowFamilyMembersModal] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const [form]: any = Form.useForm();
  let apiCount = 0;

  const getCommunityPosts = async () => {
    let requestPromise;
    if (
      profile?.user === true ||
      (profile?.user === false && profile?.type === "PUBLIC")
    ) {
      requestPromise = request(
        profile?.user
          ? "/member/current/my/posts"
          : `/member/current/public/posts/${props.uid}`
      );
    } else {
      setPostsLoader(false);
      return;
    }

    let timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, API_TIMEOUT_INTERVAL)
    );
    apiCount++;

    try {
      const resp = await Promise.race([requestPromise, timeoutPromise]);

      if (resp) {
        const postsWithCombinedURLs = (resp as Post[]).map((post: Post) => ({
          ...post,
          combinedURLs: (post.metaData || [])
            .concat(post.youtubeURL || [])
            .filter((url: string | null) => url),
        }));

        setPostsLoader(false);
        setPosts(postsWithCombinedURLs);
        cacheResponse("/member/current/my/posts", postsWithCombinedURLs);
      } else {
        // Use cached data if available
        const cachedResponse = await getCachedResponse(
          "/member/current/my/posts"
        );

        if (cachedResponse) {
          const cachedData = await cachedResponse.json();
          setPosts(cachedData);
          setPostsLoader(false);
          if (apiCount < 3) {
            getCommunityPosts();
          }
        } else {
          console.error("Failed to get cached data");
        }
      }
    } catch (error) {
      // Handle timeout or other errors
      console.error("Error in getCommunityPosts:", error);
      setPostsLoader(false);
    }
  };

  useEffect(() => {
    const getProfileData = async () => {
      const makeApiCall = localStorage.getItem("userExist");
      if (makeApiCall === "true") {
        const resp = await request(`/member/current/user/${props.uid}`);
        if (resp === "loader") {
          setLoading(false);
        }
        if (resp) {
          if (resp?.user && resp.profileScore !== 100) {
            setShowEditModal(true);
          }
          localStorage.setItem("profileColorCode", resp?.profileColorCode);
          setProfile(resp);
          form.setFieldsValue(resp);
          form.resetFields();
          setLoading(false);
          setImage(resp?.profileUrl);
          setBannerImage(resp?.bannerUrl);
        }
      } else {
        setShowEditModal(true);
      }
    };
    getProfileData();
  }, [form, props.uid]);

  useEffect(() => {
    getCommunityPosts();
  }, [form, props.uid, profile?.type, profile?.user]);

  useEffect(() => {
    if (showEditModal) {
      if (typeof window != "undefined" && window.document) {
        const containerWithOutSideBar =
          document.getElementById("userProfileDiv");
        if (containerWithOutSideBar) {
          containerWithOutSideBar.style.position = "fixed";
        }
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        const containerWithOutSideBar =
          document.getElementById("userProfileDiv");
        if (containerWithOutSideBar) {
          containerWithOutSideBar.style.position = "unset";
        }
      }
    }
  }, [showEditModal]);

  return (
    <>
      {loading ? (
        <div className="loader mt-32">
          <Loader />{" "}
        </div>
      ) : (
        <div>
          {isMobile || window?.innerWidth <= 1100 ? (
            <div>
              <img
                src={bannerImage ? bannerImage : banner}
                className="profileCover"
                // style={{ width: "100%", objectFit: "cover", height: "400px" }}
                alt="community banner"
              />
              <div className="flex flex-row">
                <div className="profileImage profileImgContainer">
                  {image ? (
                    <img
                      className="text-9xl text-gray-400  profileImage"
                      alt="preview"
                      src={image}
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <div
                      className={`capitalize relative inline-flex items-center items-center justify-center overflow-hidden profileImageThumbnail`}
                      style={{
                        backgroundColor: profile?.profileColorCode,
                        width: "120px",
                        height: "120px",
                        borderRadius: "50%",
                      }}
                    >
                      <span className="text-6xl text-white dark:text-gray-300">
                        {profile?.member?.name?.substring(0, 1)}
                      </span>
                    </div>
                  )}
                </div>
                <div className="mt-1 profileInfo">
                  <span
                    className="mr-3 ml-5 mt-3 text-2xl"
                    style={{ fontWeight: "600" }}
                  >
                    {profile?.member?.name}
                  </span>
                  {profile?.user ? (
                    <div className="joingroupProfile mr-10">
                      <Button
                        style={{
                          width: "fit-content",
                          color: "#804C31",
                          borderWidth: "2px",
                          borderColor: "#804C31",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="joinProfileButton"
                        onClick={() => setShowEditModal(true)}
                      >
                        <EditOutlined />
                        Edit profile
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  padding: "0px 15px",
                  position: "relative",
                  top: "-35px",
                }}
              >
                <p
                  className="userRightSideTextLabel"
                  style={{ fontWeight: "600", marginBottom: "2px" }}
                >
                  Intro
                </p>
                <div className="flex flex-col">
                  {profile?.familyName && (
                    <div className="flex flex-row items-center text-gray-500 mt-3 text-md">
                      <img
                        src={familyName}
                        width={"20px"}
                        className="mr-3"
                        alt=""
                      />
                      {profile?.familyName}
                    </div>
                  )}
                  {profile?.member?.occupation && (
                    <div className="flex flex-row items-center text-gray-500 mt-3 text-md">
                      <img
                        src={occupation}
                        width={"25px"}
                        className="mr-2"
                        alt=""
                      />
                      {profile?.member?.occupation}
                    </div>
                  )}
                  {profile?.member?.workplace && (
                    <div className="flex flex-row items-center mt-3 text-gray-500 text-md">
                      <img src={work} width={"25px"} className="mr-2" alt="" />{" "}
                      Works at {profile?.member?.workplace}
                    </div>
                  )}
                  {profile?.member?.university && (
                    <div className="flex flex-row items-center mt-3 text-gray-500 text-md">
                      <img
                        src={university}
                        width={"25px"}
                        className="mr-2"
                        alt=""
                      />{" "}
                      Went to {profile?.member?.university}
                    </div>
                  )}
                  {profile?.member?.currentState && (
                    <div className="flex flex-row items-center mt-3 text-gray-500 text-md">
                      <img
                        src={location}
                        width={"25px"}
                        className="mr-2"
                        alt=""
                      />{" "}
                      From {profile?.member?.currentState}{" "}
                    </div>
                  )}
                  {profile?.religion && (
                    <div className="flex flex-row items-center mt-3 text-gray-500 text-md">
                      <img
                        src={religion}
                        width={"25px"}
                        className="mr-2"
                        alt=""
                      />{" "}
                      {profile?.religion}{" "}
                      {profile?.caste && (
                        <>
                          {"| "}
                          {profile?.caste}
                        </>
                      )}{" "}
                      {profile?.subCaste && (
                        <>
                          {"| "}
                          {profile?.subCaste}
                        </>
                      )}
                    </div>
                  )}
                  <div className="flex flex-row items-center mt-3 text-gray-500 text-md">
                    <img src={date} width={"25px"} className="mr-2" alt="" />{" "}
                    Joined on {dayjs(profile?.createdAt).format("MMM YYYY")}{" "}
                  </div>
                  {profile?.relationships?.length > 0 &&
                    profile?.showFamilyTree && (
                      <div
                        className="flex flex-row items-center mt-3 text-gray-500 text-md"
                        onClick={() => setShowFamilyMembersModal(true)}
                      >
                        <img
                          src={family}
                          width={"25px"}
                          className="mr-2"
                          alt=""
                        />{" "}
                        Family & Relationships
                      </div>
                    )}
                  {profile?.hobbies && (
                    <div
                      style={{
                        padding: "15px",
                        paddingLeft: "0px",
                        paddingBottom: "0px",
                        paddingTop: "15px",
                        position: "relative",
                        left: "0px",
                      }}
                    >
                      <p
                        className="userRightSideTextLabel"
                        style={{ fontWeight: "600", marginBottom: "2px" }}
                      >
                        Hobbies & Interests
                      </p>
                      <div className="flex items-center">
                        <div className="text-gray-500 text-3xl"></div>
                        <div className="text-gray-500 userHasLockedProfile">
                          {profile?.hobbies?.map((hobby: any, index: any) => (
                            <div
                              className="w-full md:w-1/2 lg:w-1/3 xl:w-1/5 text-lg "
                              key={index}
                            >
                              <div className="w-9 text-lg p-2 flex items-center">
                                {getIconForHobby(hobby)}&nbsp;&nbsp;
                                <span className="whitespace-nowrap">
                                  {hobby}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {showFamilyMembersModal && (
                  <Modal
                    centered
                    open
                    title={
                      <div
                        className="modalTitle"
                        style={{ padding: "10px 0px 0px 10px" }}
                      >
                        Family & Relationships
                      </div>
                    }
                    onCancel={() => {
                      setShowFamilyMembersModal(false);
                    }}
                    footer={false}
                  >
                    <div className="modalContainer">
                      {profile?.relationships?.map(
                        (member: any, index: any) => (
                          <div
                            key={index}
                            className="flex flex-row items-center py-2 pl-5"
                          >
                            {member?.profileUrl ? (
                              <img
                                className="text-9xl text-gray-400  dropdownProfileImage mr-2"
                                alt="preview"
                                src={member?.profileUrl}
                                style={{ objectFit: "cover" }}
                              />
                            ) : (
                              <div
                                className={`capitalize relative inline-flex items-center items-center justify-center overflow-hidden mr-2`}
                                style={{
                                  backgroundColor: member?.profileColorCode,
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                }}
                              >
                                {member?.name ? (
                                  <span className="text-2xl text-white dark:text-gray-300">
                                    {member?.name?.substring(0, 1)}
                                  </span>
                                ) : (
                                  <span className="text-2xl text-white dark:text-gray-300">
                                    {member?.email?.substring(0, 1)}
                                  </span>
                                )}
                              </div>
                            )}

                            <div>
                              {member?.name ? (
                                <p>{member?.name}</p>
                              ) : (
                                <p>{member?.email}</p>
                              )}
                              <p className="text-slate-500 text-sm">
                                {member?.relation}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Modal>
                )}

                {profile?.type === "PRIVATE" && profile?.user === false ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="my-10"
                  >
                    <img
                      src={privateCommunity}
                      style={{ height: "30%", width: "40%" }}
                      alt=""
                      className=""
                    />
                    <p className="text-2xl" style={{ textAlign: "center" }}>
                      This is a private account
                    </p>
                    {/* <p className="text-2xl mt-2 mb-5 text-slate-500">The user has locked their activity</p> */}
                  </div>
                ) : (
                  <div style={{ paddingTop: "3px" }}>
                    {postsLoader ? (
                      <div className="w-full flex justify-center">
                        <Loader />
                      </div>
                    ) : (
                      <CommunityPosts
                        id={props.uid}
                        posts={posts}
                        from="Profile"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <img
                src={back}
                style={{
                  position: "absolute",
                  width: "2%",
                  margin: "1%",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                className="backBtn"
                onClick={() => navigate(-1)}
                alt=""
              />

              <img
                src={bannerImage ? bannerImage : banner}
                className="profileCover"
                // style={{ width: "100%", objectFit: "cover", height: "400px" }}
                alt="community banner"
              />
              <div className="mt-2">
                <div
                  className=" flex flex-row w-[100]"
                  style={{ marginBottom: "-30px" }}
                >
                  <div
                    className="profileImgContainer"
                    // style={{ position: "relative", bottom: "100px", left: "20px" }}
                  >
                    <div className="profileImage">
                      {image ? (
                        <img
                          className="text-9xl text-gray-400  profileImage"
                          alt="preview"
                          src={image}
                          style={{ objectFit: "cover" }}
                        />
                      ) : (
                        <div
                          className={`capitalize relative inline-flex items-center w-full h-full items-center justify-center overflow-hidden rounded-full`}
                          style={{
                            backgroundColor: profile?.profileColorCode,
                          }}
                        >
                          <span className="text-9xl text-white dark:text-gray-300">
                            {profile?.member?.name?.substring(0, 1)}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-1">
                    <span className="font-bold profileName">
                      {profile?.member?.name}
                    </span>
                    {profile?.user ? (
                      <div className="joingroup mr-10">
                        <Button
                          style={{
                            width: "fit-content",
                            color: "#804C31",
                            borderWidth: "2px",
                            borderColor: "#804C31",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => setShowEditModal(true)}
                        >
                          <EditOutlined />
                          Edit profile
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="ml-5 mr-5 pb-5">
                <div className="userfeedsContainer">
                  <div className="userfeedsLeftSide">
                    <div className="userfeedsPostContainer">
                      {profile?.type === "PRIVATE" &&
                      profile?.user === false ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          className="my-10"
                        >
                          <img
                            src={privateCommunity}
                            style={{ height: "30%", width: "20%" }}
                            alt=""
                            className=""
                          />
                          <p className=" privateAccount">
                            This is a private account
                          </p>
                          {/* <p className="text-2xl mt-2 mb-5 text-slate-500">The user has locked their activity</p> */}
                        </div>
                      ) : postsLoader ? (
                        <div className="flex w-full justify-center">
                          <Loader />
                        </div>
                      ) : (
                        <CommunityPosts
                          id={props.uid}
                          posts={posts}
                          from="Profile"
                        />
                      )}
                    </div>
                  </div>
                  <div className="userfeedsRightContainer">
                    <div className="userfeedsRight">
                      <div className="userfeedsRightSideTop">
                        <p className="userRightSideTextLabel">Intro</p>
                        <div className="flex flex-col">
                          {profile?.familyName && (
                            <div className="flex flex-row items-center text-gray-500 introItem">
                              <img
                                src={familyName}
                                width={"20rem"}
                                className="mr-2"
                                alt=""
                              />
                              {profile?.familyName}
                            </div>
                          )}
                          {profile?.member?.occupation && (
                            <div className="flex flex-row items-center text-gray-500 mt-2  introItem">
                              <img
                                src={occupation}
                                width={"20rem"}
                                className="mr-2"
                                alt=""
                              />
                              {profile?.member?.occupation}
                            </div>
                          )}
                          {profile?.member?.workplace && (
                            <div className="flex flex-row items-center mt-2 text-gray-500 introItem">
                              <img
                                src={work}
                                alt=""
                                width={"20rem"}
                                className="mr-2"
                              />{" "}
                              Works at {profile?.member?.workplace}
                            </div>
                          )}
                          {profile?.member?.university && (
                            <div className="flex flex-row items-center mt-2 text-gray-500 introItem">
                              <img
                                src={university}
                                width={"22rem"}
                                alt=""
                                className="mr-2"
                              />{" "}
                              Went to {profile?.member?.university}
                            </div>
                          )}
                          <div className="flex flex-row items-center mt-2 text-gray-500 introItem">
                            <img
                              src={location}
                              alt=""
                              width={"20rem"}
                              className="mr-2"
                            />{" "}
                            From {profile?.member?.currentState}{" "}
                          </div>
                          {profile?.religion && (
                            <div className="flex flex-row items-center mt-2 text-gray-500 introItem">
                              <img
                                src={religion}
                                alt=""
                                width={"20rem"}
                                className="mr-2"
                              />{" "}
                              {profile?.religion}{" "}
                              {profile?.caste && (
                                <>
                                  {" | "}
                                  {profile?.caste}
                                </>
                              )}{" "}
                              {profile?.subCaste && (
                                <>
                                  {" | "}
                                  {profile?.subCaste}
                                </>
                              )}
                            </div>
                          )}
                          <div className="flex flex-row items-center mt-2 text-gray-500 introItem">
                            <img
                              src={date}
                              width={"20rem"}
                              className="mr-2"
                              alt=""
                            />{" "}
                            Joined on{" "}
                            {dayjs(profile?.createdAt).format("MMM YYYY")}{" "}
                          </div>
                        </div>
                      </div>
                      {profile?.hobbies?.length > 0 && (
                        <div className="userfeedsRightSideMiddle">
                          <>
                            <p className="RightSideTextLabel">
                              Hobbies & Interests
                            </p>
                            <div className="flex items-center">
                              <div className="text-gray-500 text-3xl"></div>
                              <div className="text-gray-500  pb-5 userHasLockedProfile">
                                {profile?.hobbies?.map(
                                  (hobby: any, index: any) => (
                                    <div
                                      className="w-full md:w-1/2 lg:w-1/3 xl:w-1/5 "
                                      key={index}
                                    >
                                      <div className="w-9 p-2 flex items-center">
                                        {getIconForHobby(hobby)}&nbsp;&nbsp;
                                        <span className="whitespace-nowrap">
                                          {hobby}
                                        </span>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        </div>
                      )}

                      {profile?.relationships?.length > 0 &&
                        profile?.showFamilyTree && (
                          <div className="userfeedsRightSideMiddle">
                            {profile?.type === "PRIVATE" &&
                            profile?.user === false ? (
                              <>
                                <p className="RightSideTextLabel">
                                  Family & Relationships
                                </p>
                                <div className="flex flex-col items-center">
                                  <div className="text-gray-500 text-3xl">
                                  </div>
                                  <div className="text-gray-500  pb-5 userHasLockedProfile">
                                    {" "}
                                    This user has locked{" "}
                                    {profile?.member?.gender === "MALE"
                                      ? "his"
                                      : "her"}{" "}
                                    profile{" "}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <p className="userRightSideTextLabel">
                                  Family & Relationships
                                </p>
                                <div className="flex flex-col pb-5">
                                  {profile?.relationships?.map(
                                    (member: any, index: any) => (
                                      <div className="flex flex-row items-center py-2">
                                        {member?.profileUrl ? (
                                          <img
                                            className="text-9xl text-gray-400  dropdownProfileImage mr-2"
                                            alt="preview"
                                            src={member?.profileUrl}
                                            style={{ objectFit: "cover" }}
                                          />
                                        ) : (
                                          <div
                                            className={`capitalize relative inline-flex items-center items-center justify-center overflow-hidden mr-2 familyMemberThumbnail`}
                                            style={{
                                              backgroundColor:
                                                member?.profileColorCode,
                                            }}
                                          >
                                            {member?.name ? (
                                              <span className="text-2xl text-white dark:text-gray-300">
                                                {member?.name?.substring(0, 1)}
                                              </span>
                                            ) : (
                                              <span className="text-2xl text-white dark:text-gray-300">
                                                {member?.email?.substring(0, 1)}
                                              </span>
                                            )}
                                          </div>
                                        )}
                                        <div>
                                          {member?.name ? (
                                            <p className="familyMemberName">
                                              {member?.name}
                                            </p>
                                          ) : (
                                            <p className="familyMemberName">
                                              {member?.email}
                                            </p>
                                          )}
                                          <p className="text-slate-500  familyMemberRelationship">
                                            {member?.relation}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      <div className="userfeedsRightSideMiddle">
                        {profile?.type === "PRIVATE" &&
                        profile?.user === false ? (
                          <>
                            <p className="RightSideTextLabel">
                              Latest Activity
                            </p>
                            <div className="flex flex-col items-center">
                              <div className="text-gray-500 text-3xl">
                              </div>
                              <div className="text-gray-500 pb-5 userHasLockedProfile">
                                {" "}
                                This user has locked{" "}
                                {profile?.member?.gender === "MALE"
                                  ? "his"
                                  : "her"}{" "}
                                profile{" "}
                              </div>
                            </div>
                          </>
                        ) : postsLoader ? (
                          <div className="flex w-full justify-center">
                            <Loader />
                          </div>
                        ) : (
                          <LatestActivity
                            id="65043082903f2636dbe3c885"
                            type={profile?.type}
                            member={true}
                            posts={posts}
                            from="Profile"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showEditModal && (
        <div id="userProfileDiv">
          <UpdateProfile onClose={() => setShowEditModal(false)} />
        </div>
      )}
    </>
  );
};

export default UserProfile;
