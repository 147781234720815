import React, { useState, useEffect, useCallback } from "react";
import loginLogo from "../../assets/LoginImages/LoginLogo.svg";
import GmailIcon from "../../assets/LoginImages/gmail.svg";
import userLoginIcon from "../../assets/LoginImages/normalLogin.svg";
import loginFamilyPic from "../../assets/LoginImages/emailVerified.png";
import PhoneIcon from "../../assets/LoginImages/Phone.svg";
import { isMobile, isDesktop } from "../../Utils/mobileSettings";
import newloader from "../../assets/loader-new.gif";
import { Checkbox, Input } from "antd";
import jwt_decode from "jwt-decode";
import app from "../../firebase";
// import TermsAndPrivacyModal from "../StaticPageModals/TermsOfUseModal";
import TermsAndPrivacyModal from "../StaticPageModals/TermsOfUseModal";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  OAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  getAuth,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Phone from "../GoogleSignIn/PhoneSignin/Phone";
import { request } from "../../Utils/request";
import Loader from "../Loader/Loader";
import "./LoginComponent.css";
import { multiFormRequest } from "../../Utils/request";
import axios from "axios";
import { isIOS } from "react-device-detect";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Users {
  name?: string;
  id?: string;
}

const LoginComponent = (props: Users) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const auth = getAuth(app);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registerClick, setRegisterClick] = useState(false);
  const [forgotPasswordClick, setForgotPasswordClick] = useState(false);
  const [activeComponent, setActiveComponent] = useState("login");
  const [showText, setShowText] = useState(true);
  const [loader, setLoader] = useState<Boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<Boolean>(false);
  const [termsAgreed, setTermsAgreed] = useState<Boolean>(false);
  const [showTermsModal, setShowTermsModal] = useState<Boolean>(false);
  const [policyValue, setPolicyValue] = useState("");
  const [deviceToken,setDeviceToken] = useState("");


  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const loginOptionsArray = [
    { icon: PhoneIcon, label: "", type: "phone" },
    { icon: GmailIcon, label: "", type: "gmail" },
    { icon: userLoginIcon, label: "", type: "login" },
  ];


  const checkUserRegistrationStatus = async (user: any) => {};
  useEffect(() => {
    // This will hide the text after 3 seconds
    const timeout = setTimeout(() => {
      setShowText(false);
    }, 1500);

    return () => {
      // Clear the timeout if the component unmounts before the 3 seconds elapse
      clearTimeout(timeout);
      // responseGoogle();
    };
  }, []);

  useEffect(() => {
    const deleted = localStorage.getItem("userDeleted");
    if (deleted?.length) {
      toast.error("Current user account does not exist");
      localStorage.clear();
    }
  }, []);

  const fetchDeviceToken = async () => {
    try {
      const actualDeviceToken =  localStorage.getItem("deviceToken");
      if(actualDeviceToken) {
      // toast.success("Deive token on register click"+actualDeviceToken);
        setDeviceToken(actualDeviceToken)
      }else{
        if (typeof window?.AndroidJSBridge?.getDeviceToken === "function") {
          const devicetoken = await window.AndroidJSBridge.getDeviceToken();
          const version = await window?.AndroidJSBridge?.getDeviceOsVersion();
          setDeviceToken(devicetoken);
          console.log("Token", devicetoken);
          // toast.success("Android token "+devicetoken)
          localStorage.setItem("deviceToken", devicetoken);
          localStorage.setItem("deviceOsVersion", version);
          localStorage.setItem("deviceType", "ANDROID");
        }else{
          // toast.success("in else of fetchdevicetoken")
          const handleIOSEvent = (e:any) => {
            return new Promise((resolve, reject) => {
              try {
                const deviceToken = e.detail.data;
                setDeviceToken(deviceToken)
                // toast.success(`Token in login: ${deviceToken}`);
                localStorage.setItem("deviceToken", deviceToken);
                localStorage.setItem("deviceOsVersion", "0");
                localStorage.setItem("deviceType", "IOS");
                resolve(deviceToken);
              } catch (error) {
                reject(error);
              }
            });
          };
        
          const eventListener = (e:any) => {
            handleIOSEvent(e)
              .then((deviceToken) => {
                console.log(`Token received and processed: ${deviceToken}`);
                // toast.success(`Token received and processed: ${deviceToken}`);
              })
              .catch((error) => {
                console.error(`Error processing event: ${error}`);
                // toast.error(`Error processing event: ${error}`);
              });
          };
          window.addEventListener("iosEvent", eventListener);
        }
      }
    } catch (error: any) {
      console.error("Error fetching device token: " + error.message);
    }
  };
useEffect(() =>{
  // if(registerClick){
  fetchDeviceToken()
// }
},[deviceToken])

console.log("Fetching device token",deviceToken)

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) return checkUserRegistrationStatus(user);
    });
    return () => unSubscribe();
  }, []);


  const DesktopLogin = async (result: any) => {
    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    try {
      const user = result.user;
      if (user) {
        const idToken = await user.getIdToken();
        localStorage.setItem("token", idToken);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("firebaseId", user?.uid);
        localStorage.setItem("loginInVia", "gmail");
        const emailLoginRespBody = {
          method: "POST",
          body: JSON.stringify({
            email: result?.user?.providerData[0]?.email?.toLowerCase(),
          }),
        };
        const emailLoginResp = await request(
          `/member/current/user/exists`,
          emailLoginRespBody
        );
        localStorage.setItem("userExist", emailLoginResp?.userExists);

        const fireBaseRequestBody = {
          method: "POST",
          body: JSON.stringify({
            fireBaseUID: user?.uid,
            signUpVia: "GOOGLE",
            token: `Bearer ${idToken}`,
            email: result?.user?.providerData[0]?.email?.toLowerCase(),
          }),
        };

        if (emailLoginResp?.signUpVia?.google && emailLoginResp?.userExists) {
          meApiCall(emailLoginResp?.userExists);
        }

        if (
          emailLoginResp.userExists &&
          emailLoginResp?.signUpVia?.google === false
        ) {
          try {
            const firebaseMappingResp = await request(
              `/member/current/add/firebase/account`,
              fireBaseRequestBody
            );
            if (
              firebaseMappingResp?.signUpVia?.google &&
              firebaseMappingResp?.userExists
            ) {
              meApiCall(firebaseMappingResp?.userExists);
            } else {
              setLoader(false);
              navigate(`/profile`, { replace: true });
            }
            localStorage.setItem("userExist", emailLoginResp?.userExists);
          } catch (error: any) {
            toast.error(error?.message || "Something went wrong");
            return;
          }
        } else if (
          emailLoginResp.userExists &&
          emailLoginResp?.signUpVia?.google
        ) {
          meApiCall(emailLoginResp?.userExists);
        } else if (!emailLoginResp.userExists) {
          let imageFormData = new FormData();
          const blob = new Blob(
            [
              JSON.stringify({
                email:
                  result?.user?.providerData[0]?.email?.toLowerCase() || "",
                role: "MEMBER",
                firebaseUID: localStorage.getItem("firebaseId"),
                signUpVia: "GOOGLE",
              }),
            ],
            {
              type: "application/json",
            }
          );
          imageFormData.append("data", blob);

          const resp: any = await multiFormRequest(
            `/member/current/create/by-email`,
            imageFormData,
            "post"
          );
          // setButtonLoader(false);
          localStorage.setItem("loginInVia", "gmail");
          if (resp?.data?.id) {
            meApiCall(resp?.userExist);
            localStorage.setItem("userExist", "true");
          } else {
            toast.error("Something went wrong");
          }
        } else {
          navigate(`/profile`, { replace: true });
        }
      }
    } catch {
      toast.error("Something went wrong");
    }
  };

  const signUpClick = async () => {
    const loginVia = localStorage.getItem("loginInVia");

    if (emailPattern?.test(email)) {
      try {
        setButtonLoader(true);
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        const idToken = await user.getIdToken(true);
        localStorage.setItem("token", idToken);
        localStorage.setItem("firebaseId", user?.uid);
        localStorage.setItem("user", JSON.stringify(user));

        const LoginRespBody = {
          method: "POST",
          body: JSON.stringify({
            email: user.providerData[0].email?.toLowerCase(),
          }),
        };
        const signUpLoginResp = await request(
          `/member/current/user/exists`,
          LoginRespBody
        );
        localStorage.setItem("userExist", signUpLoginResp?.userExists);
        const fireBaseRequestBody = {
          method: "POST",
          body: JSON.stringify({
            fireBaseUID: user?.uid,
            signUpVia: "IN_APP_EMAIL",
            token: `Bearer ${idToken}`,
            email: email?.toLowerCase(),
          }),
        };
        if (
          signUpLoginResp?.userExists &&
          signUpLoginResp?.emailVerified === false &&
          signUpLoginResp?.signUpVia?.in_APP_EMAIL === false
        ) {
          const firebaseMappingResp = await request(
            `/member/current/add/firebase/account`,
            fireBaseRequestBody
          );

          if (
            firebaseMappingResp?.signUpVia?.in_APP_EMAIL &&
            firebaseMappingResp?.userExists &&
            firebaseMappingResp?.emailVerified === false
          ) {
            toast.success(
              `To complete the registration process, please confirm your email by clicking the link in the verification mail we sent to ${email?.toLowerCase()}.`
            );
          } else {
            setShowText(false);
            meApiCall(firebaseMappingResp?.userExists);
          }
        } else {
          let imageFormData = new FormData();
          const blob = new Blob(
            [
              JSON.stringify({
                email: email?.toLowerCase(),
                role: "MEMBER",
                firebaseUID: localStorage.getItem("firebaseId"),
                signUpVia:
                  loginVia === "email"
                    ? "IN_APP_EMAIL"
                    : loginVia === "phone"
                    ? "IN_APP_PHONE"
                    : "GOOGLE",
                deviceToken: deviceToken
              }),
            ],
            {
              type: "application/json",
            }
          );
          imageFormData.append("data", blob);

          const resp: any = await multiFormRequest(
            `/member/current/create/by-email`,
            imageFormData,
            "post"
          );
          setButtonLoader(false);
          localStorage.setItem("loginInVia", "email");
          if (resp?.emailVerified) {
          } else {
            toast.success(
              `To complete the registration process, please confirm your email by clicking the link in the verification mail we sent to ${email?.toLowerCase()}.`
            );
          }
        }
      } catch (error: any) {
        setButtonLoader(false);
        if (error?.message?.split("/")[1]?.includes("email")) {
          toast.error(
            "This email has already been registered; kindly log in to your account"
          );
        } else {
          setButtonLoader(false);
          if (
            error?.message?.includes("WEAK_PASSWORD") ||
            password?.length < 6
          ) {
            toast.error("Ensure your password contains at least 6 characters");
          } else {
            if (
              error?.message?.includes("missing-password") ||
              password?.length === 0
            ) {
              toast.error("Kindly input your password");
            } else {
              toast.error(error?.message || "Something went wrong");
            }
          }
        }
      }
    } else {
      if (email?.length === 0 && password?.length === 0) {
        toast.error(
          "Ensure every field is filled with the necessary information"
        );
      }
      toast.error("Please enter a valid email address");
    }
  };

  const meApiCall = async (userExists: boolean) => {
    if (userExists) {
      const decoded: any = await jwt_decode(
        localStorage.getItem("token") || ""
      );
      console.log("decoded", decoded);
      if (decoded?.role === "ADMIN") {
        const meApiResp = await request("/admin/me");
        if (meApiResp?.id) {
          localStorage.setItem("id", meApiResp?.id);
          localStorage.setItem("role", meApiResp?.role);
          localStorage.setItem("profileColorCode", meApiResp?.profileColorCode);
          localStorage.setItem("profileUrl", meApiResp?.profileUrl);
          localStorage.setItem("profileScore", meApiResp?.profileScore);
          navigate("/admin", { replace: true });
        }
      } else if (decoded?.role === "MEMBER") {
        const meApiResp = await request("/member/current/me");
        if (meApiResp?.id) {
          if (meApiResp?.role === "MEMBER") {
            if (meApiResp?.id) {
              localStorage.setItem("id", meApiResp?.id);
              localStorage.setItem("role", meApiResp?.role);
              localStorage.setItem("name", meApiResp?.member?.name);
              localStorage.setItem(
                "profileColorCode",
                meApiResp?.profileColorCode
              );
              localStorage.setItem("profileUrl", meApiResp?.profileUrl);
              localStorage.setItem("profileScore", meApiResp?.profileScore);

              if (meApiResp?.profileScore === 100) {
                navigate("/feeds", { replace: true });
              } else {
                navigate(`/profile`, { replace: true });
              }
            }
          }
        } else {
          toast.error("Failed to login. Please try again later.");
          setLoader(false);
          setShowText(false);
        }
      }
    } else {
      navigate(`/profile`, { replace: true });
    }
    setLoader(false);
  };

  const OnLoginClick = async (e: any) => {
    //user exists in firebase
    e.preventDefault();
    try {
      setButtonLoader(true);
      const response = await signInWithEmailAndPassword(auth, email, password);
      const user = response.user;
      if (user) {
        const idToken = await user.getIdToken(true);
        localStorage.setItem("token", idToken);
        localStorage.setItem("uid", user.uid);
        localStorage.setItem("firebaseId", user?.uid);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("loginInVia", "email");
        localStorage.setItem("user", JSON.stringify(user));

        const LoginRespBody = {
          method: "POST",
          body: JSON.stringify({
            email: email?.toLowerCase(),
          }),
        };

        const LoginResp = await request(
          `/member/current/user/exists`,
          LoginRespBody
        );
        if (LoginResp?.message?.includes("Failed to fetch")) {
          setButtonLoader(false);
          toast.error("CORS error");
          return;
        }
        localStorage.setItem("userExist", LoginResp?.userExists);
        const fireBaseRequestBody = {
          method: "POST",
          body: JSON.stringify({
            fireBaseUID: user?.uid,
            signUpVia: "IN_APP_EMAIL",
            token: `Bearer ${idToken}`,
            email: email,
          }),
        };

        if (
          LoginResp?.emailVerified === false &&
          LoginResp?.userExists &&
          LoginResp?.signUpVia?.in_APP_EMAIL === false
        ) {
          try {
            const firebaseMappingResp = await request(
              `/member/current/add/firebase/account`,
              fireBaseRequestBody
            );
            if (
              firebaseMappingResp?.signUpVia?.in_APP_EMAIL &&
              firebaseMappingResp?.userExists &&
              firebaseMappingResp?.emailVerified === false
            ) {
              meApiCall(firebaseMappingResp?.userExists);
            } else {
              navigate(`/profile`, { replace: true });
            }
            localStorage.setItem("userExist", LoginResp?.userExists);
          } catch (error: any) {
            toast.error(error?.message || "Something went wrong!");
            return;
          }
        } else if (
          LoginResp?.emailVerified === false &&
          LoginResp?.userExists &&
          LoginResp?.signUpVia?.in_APP_EMAIL === true
        ) {
          toast.success(
            `Your account is pending verification, please confirm your email by clicking the link in the verification mail we sent to ${email?.toLowerCase()}.`
          );
          setButtonLoader(false);
        } else {
          setShowText(true);
          meApiCall(LoginResp?.userExists);
          setLoader(false);
          setButtonLoader(false);
        }
      }
    } catch (e: any) {
      setLoader(false);
      setButtonLoader(false);
      if(e?.message?.includes('auth/invalid-login-credentials')) {
        toast.error('Invalid login credentials')
      }
      else if (e?.message?.split("/")[1]?.includes("password")) {
        toast.error(
          "Incorrect password. Try again or click Forgot password to reset it"
        );
      } else if (e?.message?.split("/")[1]?.includes("email")) {
        toast.error("Please provide a valid email address.");
      } else if (
        (e?.message?.split("/")[1]?.includes("not") &&
          e?.message?.split("/")[1]?.includes("found")) ||
        e?.message?.split("/")[1]?.includes("invalid-login-credentials") 
      ) {
        setLoader(true);
        setButtonLoader(false);
        toast.error(
          "Email address not recognized. Please register to create an account."
        );
      } else if (
        e?.message?.split("/")?.includes("invalid") ||
        e?.message?.split("/").includes("credentials") ||
        e?.message?.split("/")?.includes("invalid-login-credentials")
      ) {
        setLoader(true);
        setButtonLoader(false);
        toast.error("Invalid login credentials");
      } else {
        setLoader(false);
        setButtonLoader(false);
        toast.error(`Invalid login credentials`);
      }
      setLoader(false);
      setButtonLoader(false);
    }
  };

  const onForgotPasswordClick = async () => {
    if (email) {
      setButtonLoader(true);
      try {
        const res = await axios.post(`${BASE_URL}/member/forgot-password`, {
          email: email,
        });

        if (res.data) {
          toast.success(
            "Password reset information has been sent to your registered email"
          );
          setButtonLoader(false);
        } else {
          toast.error("Something went wrong");
          setButtonLoader(false);
        }
      } catch (error: any) {
        console.error("Error during password reset request:", error);
        setButtonLoader(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error("Please enter a valid email address");
    }
  };

  const onAppleSignInClick = async () => {
    const provider = new OAuthProvider("apple.com");
    try {
      const res = await signInWithPopup(auth, provider).then((result) => {
        const user = result.user;
      });
    } catch (e: any) {}
  };

  const onGoogleSignInClick = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.addScope("email");
    provider.setCustomParameters({
      prompt: "select_account",
    });
    try {
      const res = await signInWithPopup(auth, provider)
        .then((result) => {
          setLoader(true);
          DesktopLogin(result);
        })
        .catch((error) => {});
    } catch (e: any) {}
  };

  const renderComponent = (type: string) => {
    if (type === "phone") {
      return <Phone parentCallback={() => {}} />;
    }
    if (type === "login") {
      return (
        <div className="login">
          {showText &&
            (isMobile || window?.innerWidth <= 1100 ? (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(255, 255, 255)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 9999,
                }}
              >
                <img
                  src={newloader}
                  style={{ width: "120px", height: "120px" }}
                  alt=""
                  className="mt-5"
                />
              </div>
            ) : (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(255, 255, 255)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 9999,
                }}
              >
                <img
                  alt=""
                  src={loginLogo}
                  style={{
                    width: "300px",
                    height: "300px",
                    marginBottom: "5px",
                  }}
                />
                <p style={{ color: "white", fontSize: "20px" }}>
                  <img
                    src={newloader}
                    style={{ width: "150px", height: "120px" }}
                    alt=""
                    className="mt-5"
                  />
                </p>
              </div>
            ))}
          <form className="loginComponentForm">
            <Input
              placeholder="Email"
              id="email-address"
              name="email"
              type="email"
              required
              className="inputfield inputWrapper email text-lg font-normal"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <div className="w-full">
              {!forgotPasswordClick && (
                <>
                  <div className="passwordWrapper">
                    <Input.Password
                      style={{ outline: "none" }}
                      placeholder="Password"
                      className="inputfield inputWrapper password text-lg  font-normal"
                      required
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  {registerClick && (
                    <>
                      <div className="passwordWrapper">
                        <Input.Password
                          placeholder="Confirm Password"
                          style={{ outline: "none" }}
                          className="inputfield inputWrapper password text-lg font-normal"
                          required
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                      </div>

                      <div>
                        <Checkbox
                          onChange={(e: any) => {
                            setTermsAgreed(!termsAgreed);
                          }}
                          className="checkboxmulti"
                        >
                          I agree to the{" "}
                          <span
                            className="textColor"
                            onClick={() => {
                              setShowTermsModal(true);
                              setPolicyValue("privacyPolicy");
                            }}
                          >
                            Privacy Policy
                          </span>{" "}
                          and{" "}
                          <span
                            className="textColor"
                            onClick={() => {
                              setShowTermsModal(true);
                              setPolicyValue("TermsOfService");
                            }}
                          >
                            {" "}
                            Terms of Service{" "}
                          </span>
                          by registering.
                        </Checkbox>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <button
              style={{
                opacity: registerClick
                  ? !(
                      termsAgreed &&
                      email?.length &&
                      password?.length &&
                      confirmPassword?.length &&
                      registerClick
                    )
                    ? 0.5
                    : 1
                  : 1,
              }}
              id="loginAndSignUpButton"
              disabled={
                registerClick
                  ? !(
                      termsAgreed &&
                      email?.length &&
                      password?.length &&
                      confirmPassword?.length &&
                      registerClick
                    )
                    ? true
                    : false
                  : false
              }
              onClick={(e) => {
                console.log(
                  termsAgreed && email && password && confirmPassword
                );

                e.preventDefault();
                const buttonText =
                  document.getElementById("loginAndSignUpButton")?.innerText ||
                  "";
                if (buttonText === "Login") {
                  OnLoginClick(e);
                } else if (buttonText === "Register") {
                  if (
                    password?.toLocaleLowerCase() ===
                    confirmPassword?.toLocaleLowerCase()
                  ) {
                    localStorage.setItem("loginInVia", "email");
                    signUpClick();
                  } else {
                    toast.error(
                      "Passwords do not match. Please ensure both passwords are the same and try again."
                    );
                  }
                } else {
                  onForgotPasswordClick();
                }
              }}
              className="loginComponentButton relative"
            >
              {buttonLoader ? (
                <div className="loader w-[50px]">
                  {" "}
                  <Loader />
                </div>
              ) : (
                ""
              )}
              {registerClick
                ? "Register"
                : !forgotPasswordClick
                ? "Login"
                : "Send Email"}
            </button>
            <div
              className={
                registerClick
                  ? "flex justify-center items-center mt-1 p-1"
                  : "forgotPasswordWrapper"
              }
            >
              <button
                id="loginButton"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    document
                      ?.getElementById("loginButton")
                      ?.innerText.toLowerCase() === "login"
                  )
                    setForgotPasswordClick(false);
                  else setForgotPasswordClick(true);
                }}
                className="forgotPasswordText"
              >
                {registerClick
                  ? ""
                  : forgotPasswordClick
                  ? "Login"
                  : "Forgot password"}
              </button>
              <button
                id="button"
                onClick={(e) => {
                  e.preventDefault();
                  setForgotPasswordClick(false);
                  const text =
                    document?.getElementById("button")?.innerText || "";
                  if (text?.toLocaleLowerCase() === "login") {
                    setRegisterClick(false);
                  } else {
                    setRegisterClick(true);
                  }
                }}
                className="forgotPasswordText"
              >
                {registerClick ? "Login" : "Register now"}
              </button>
            </div>
          </form>
        </div>
      );
    }
    if (type === "gmail") {
      localStorage.setItem("activeComponent", activeComponent);
      return <div className=""></div>;
    }
  };

  useEffect(() => {
    window.otpless = (otplessUser: any) => {
      if (otplessUser?.token) {
        navigate("/");
        // props.parentCallback();
      }
    };
  }, []);

  return (
    <>
      {loader ? (
        isMobile || window?.innerWidth <= 1100 ? (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999,
            }}
          >
            <img
              src={newloader}
              style={{ width: "120px", height: "120px" }}
              alt=""
              className="mt-5"
            />
          </div>
        ) : (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "rgba(255, 255, 255)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 9999,
            }}
          >
            <img
              src={loginLogo}
              style={{ width: "300px", height: "300px", marginBottom: "5px" }}
              alt=""
            />
            <p style={{ color: "white", fontSize: "20px" }}>
              <img
                src={newloader}
                style={{ width: "150px", height: "120px" }}
                alt=""
                className="mt-5"
              />
            </p>
          </div>
        )
      ) : (
        <div className="LoginWrapper">
          <div className="logincontainerWithOutSideBar loginPage">
            <div className="LoginMainContentCol">
              <div className="loginRow">
                <div className="loginImageCol loginCol-L">
                  <img className="Login-L-Img" src={loginFamilyPic} alt="" />
                </div>
                <div className="loginCol loginCol-L">
                  <div className="loginColWrapper">
                    <div className="logoAndTittleWrapper">
                      <img
                        className="cursor-pointer"
                        alt=""
                        onClick={() => {
                          navigate("/", { replace: true });
                          // props.parentCallback("/");
                        }}
                        src={loginLogo}
                      />
                      <span className="logoText">VamshaVruksha</span>
                    </div>
                    {renderComponent(activeComponent)}

                    <div>
                      <div className="loginColForm">
                        <div
                          className="loginComponentOptions"
                          style={{ gap: "50px" }}
                        >
                          {loginOptionsArray?.map((item) => {
                            if (item.type === "gmail") {
                              if (isDesktop && window?.innerWidth >= 1100) {
                                return (
                                  <div
                                    className={`google-login-container`}
                                    key={item.type}
                                  >
                                    <button
                                      onClick={() => {
                                        setActiveComponent(item.type);
                                        onGoogleSignInClick();
                                      }}
                                    >
                                      <img src={item.icon} alt="" />
                                      <div
                                        className={`${
                                          item.type === activeComponent
                                            ? " mt-2 border-b-4 border-[#869031]"
                                            : ""
                                        }`}
                                      ></div>
                                    </button>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            } else {
                              return (
                                <div className={``} key={item.type}>
                                  <img
                                    alt=""
                                    className="cursor-pointer loginOptions"
                                    src={item.icon}
                                    onClick={() => {
                                      setActiveComponent(item.type);
                                    }}
                                  />
                                  <div
                                    className={`${
                                      item.type === activeComponent
                                        ? "mt-2 border-b-4 border-[#869031] "
                                        : ""
                                    }`}
                                  ></div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img />
          </div>
          <div></div>
        </div>
      )}
      <TermsAndPrivacyModal
        modalValue={policyValue}
        isModalVisible={showTermsModal}
        handleCancel={() => setShowTermsModal(false)}
        handleOk={() => setShowTermsModal(false)}
      />
    </>
  );
};

export default LoginComponent;
