import React, { useState, useEffect } from "react";
import { request } from "../../Utils/request";
import Loader from "../Loader/Loader";
import {
  SendOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  LikeOutlined,
  LikeTwoTone,
  DislikeTwoTone,
  DislikeOutlined,
  ShareAltOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EditOutlined,
  UserAddOutlined,
  CheckOutlined,
  LeftOutlined,
  IssuesCloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import newloader from "../../assets/loader-new.gif";
import { TIMEAGO } from "../../Utils/timeago";
import CommunityPosts from "./CommunityPosts";
import Community from "../../assets/images/community.jpg";
import connection from "../../assets/images/connection_3.png";
import "./CommunityPreview.css";
import Trending from "./Trending";
import LatestActivity from "./LatestActivity";
import { toast } from "react-toastify";
import { Button } from "antd";
import EditCommunityPopup from "./EditCommunityPopup";
import privateCommunity from "../../assets/images/privateCommunity.png";
import back from "../../assets/matrimonyIcons/back.svg";
import { useNavigate } from "react-router";
import * as Survey from "survey-react";
import religionForm from "../Commuinty/religionForm.json";
import { Modal } from "antd";
import { isMobile } from "../../Utils/mobileSettings";
import getCategoryImages from "./CategoryImages";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import AddnewMemberPopup from "./AddnewMemberPopup";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  id?: string;
};
interface Post {
  postId: string;
  reported?: boolean;
  metaData?: string[];
  youtubeURL?: string | null;
}
function CommunityPreviewComponent(props: Props) {
  const location = useLocation();
  const communityMemberStatus = location?.state?.communityMemberStatus;

  const [communityData, setCommunityData] = useState<any>([]);
  const [communityCategory, setCommunityCategory] = useState<any[]>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const [postsLoader, setPostsLoader] = useState<Boolean>(true);
  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [members, setMembers] = useState<any>([]);
  const [posts, setPosts] = useState<any[]>([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [formDataObject, setFormDataObject] = useState();
  const [showMembersModal, setShowMembersModal] = useState<boolean>(false);
  const [addNewMember, setAddNewMember] = useState<Boolean>(false);
  const [religionFormData, setReligionFormData] = useState();
  const [tags, setTags] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [postRefresh, setPostRefresh] = useState<boolean>(false);

  const navigate = useNavigate();

  const getcommunityData = async () => {
    const resp = await request(`/member/current/community/${props.id}`);
    if (resp) {
      const datafetch = resp;
      setLoader(false);
      if (datafetch?.categoryName === "Religion community") {
        const categoryResponse = await request(
          `/member/communities/categories`
        );
        const religionCategory = categoryResponse.find(
          (category: any) => category.name === "Religion community"
        )?.formData;
        setReligionFormData(religionCategory);
      }
      try {
        datafetch?.surveyJSFormData &&
          datafetch?.surveyJSFormData?.communityData &&
          setFormDataObject(
            JSON.parse(datafetch?.surveyJSFormData?.communityData)
          );
      } catch (err) {
        setFormDataObject(datafetch?.surveyJSFormData?.communityData);
      }

      setCommunityData(datafetch);
    } else {
      setLoader(false);
    }
  };
  const getMembersData = async () => {
    try {
      if (
        communityData?.type === "PUBLIC" &&
        !communityData?.communityBlocked &&
        !communityData?.memberBlocked
      ) {
        const resp = await request(
          `/member/current/community/${props.id}/members`
        );
        if (resp) {
          const datafetch = resp;
          setLoader(false);
          setMembers(datafetch);
        } else {
          setLoader(false);
        }
      }
      if (
        communityData?.member === true &&
        communityData?.type === "PRIVATE" &&
        !communityData?.communityBlocked &&
        !communityData?.memberBlocked
      ) {
        const resp = await request(
          `/member/current/community/${props.id}/members`
        );
        if (resp) {
          const datafetch = resp;
          setLoader(false);
          setMembers(datafetch);
        } else {
          setLoader(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
    }
  };
  useEffect(() => {
    getMembersData();
  }, [props.id, communityData.type]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getcommunityData();
  }, [props.id]);

  const getCommunityTags = async () => {
    const tagsResp = await request(
      `/member/current/community/${props.id}/posts/tags`
    );
    delete tagsResp["General"];
    let newCategories: any = {
      All: [],
    };

    // Add the remaining fields from the original object
    for (let key in tagsResp) {
      if (tagsResp.hasOwnProperty(key)) {
        newCategories[key] = tagsResp[key];
      }
    }

    setTags(newCategories);
  };

  useEffect(() => {
    if (currentPage === 0) {
      getCommunityTags();
    }
  }, [currentPage]);

  const getCommunityPosts = async (page?: number) => {
    try {
      let resp;
      if (
        communityData?.type === "PUBLIC" ||
        (communityData?.member === true && communityData?.type === "PRIVATE")
      ) {
        let tagsQueryParam =
          selectedTag === "All" ? "" : `&tags=${selectedTag}`;
        if (!communityData?.admin || communityMemberStatus === "othercommunity") {
          console.log("IN OTHER COMMUNITY")
          resp = await request(
            `/member/current/community/${props.id}/posts?page=${
              page || 0
            }&size=10${tagsQueryParam}`
          );
        } else {
          resp = await request(
            `/member/current/community/admin/${props.id}/posts?page=${
              page || 0
            }&size=10${tagsQueryParam}`
          );
        }
        if (resp?.content?.length >= 0) {
          if (!resp?.content || resp?.content?.length === 0) {
            setHasMore(false);
            setPostsLoader(false);
          } else {
            const postsWithCombinedURLs = (resp?.content as Post[])
              .map((post: Post) => ({
                ...post,
                combinedURLs: (post.metaData || [])
                  .concat(post.youtubeURL || [])
                  .filter((url: string | null) => url),
              }))
              .filter((post) => post?.reported === false);

            if (page === 0) {
              setPosts(postsWithCombinedURLs);
              if (postsWithCombinedURLs?.length === 0) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
            } else {
              setPosts([...posts, ...postsWithCombinedURLs]);
            }

            // setPosts(postsWithCombinedURLs);
            setPostsLoader(false);
          }
          // else {
          //   setPostsLoader(false);
          // }
        }
      } else if (
        communityData?.member === false &&
        communityData?.type === "PRIVATE"
      ) {
        setPostsLoader(false);
        setHasMore(false);
      }
    } catch (error) {
      console.log("error", error);
      setPostsLoader(false);
      setHasMore(false);
    }
  };

  useEffect(() => {
    getCommunityPosts(currentPage);
  }, [props.id, communityData.type, communityData.member, currentPage]);
  useEffect(() => {
    setCurrentPage(0);
    getCommunityPosts(0);
  }, [selectedTag, postRefresh]);

  const handleJoinCommunity = async (communityId: any) => {
    try {
      const body = {
        method: "POST",
      };
      const response = await request(
        `/member/current/community/join/${communityId}`,
        body
      );
      if (response) {
        setCommunityData({ ...communityData, requested: true });
        toast.success("Your request has been sent for approval!");
      }
    } catch (error) {
      toast.error("Request already sent");
    }
  };

  function refreshPosts() {
    setPostRefresh(!postRefresh);
  }

  const categoryImages = getCategoryImages(communityData?.categoryName);
  return (
    <>
      {loader ? (
        <div className="loader mt-32">
          <Loader />{" "}
        </div>
      ) : (
        <div className="feedsWrapper">
          <div className="feedsContent">
            <div className="communityfeedsLeftSide" id="scrollableDiv">
              <InfiniteScroll
                dataLength={posts?.length}
                next={() => {
                  setCurrentPage(currentPage + 1);
                }}
                hasMore={hasMore}
                loader={
                  currentPage !== 0 && (
                    <div className="flex flex-row items-center justify-center">
                      <img
                        src={newloader}
                        style={{ width: "80px", height: "80px" }}
                        className="my-3"
                        alt=""
                      />
                    </div>
                  )
                }
                // scrollableTarget="scrollableDiv"
              >
                <div className="communityfeedsPostContainer">
                  <div className="">
                    <img
                      src={back}
                      alt=""
                      onClick={() => navigate(-1)}
                      className="communityPreviewBack"
                    />
                    <img
                      src={communityData?.communityBannerUrl}
                      style={{
                        width: "100%",
                        height: "450px",
                        objectFit: "cover",
                      }}
                      className="communitypreviewbanner"
                      alt="banner"
                    />
                    <div className="flex flex-col">
                      <div className="flex flex-row -mb-20 communityPreviewWrap">
                        <img
                          src={
                            communityData?.communityProfileUrl ||
                            categoryImages.profileUrl
                          }
                          className="communityDP"
                          alt="dp"
                        />
                        <div
                          className="flex flex-col aboutCommunityWrapper CommunityInfo"
                          style={{ width: "100%" }}
                        >
                          <div className="flex flex-row justify-between aboutCommunityInfo">
                            <div className="flex flex-col ">
                              <div className="flex gap-3 ">
                                <span className="communityName">
                                  {communityData?.name}
                                </span>
                              </div>

                              <span className="mb-5 flex flex-row items-center ">
                                {communityData?.categoryId && (
                                  <span className="categoryName">
                                    {communityData?.categoryName} |{" "}
                                  </span>
                                )}

                                {communityData?.type === "PRIVATE" ? (
                                  <span className="categoryName">
                                    <EyeInvisibleOutlined className="mx-1" />
                                    private group
                                  </span>
                                ) : (
                                  <span className="categoryName">
                                    <EyeOutlined className="mx-1" />
                                    public group
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="flex flex-col items-center aboutwrap">
                              <div className="flex flex-row mr-10 postsMembersInfo">
                                {communityData?.member === false &&
                                communityData.type === "PRIVATE" ? (
                                  <span className="flex flex-col items-center postsInfo">
                                    <EyeInvisibleOutlined
                                      className="flex justify-center items-center"
                                      style={{
                                        marginRight: "auto",
                                        marginLeft: "auto",
                                      }}
                                    />
                                    <span className="uppercase text-sm text-slate-500 ml-2">
                                      posts
                                    </span>
                                  </span>
                                ) : (
                                  <span className="flex flex-col items-center postsInfo">
                                    {communityData?.noOfPosts}
                                    <span className="uppercase text-sm text-slate-500 ml-2">
                                      posts
                                    </span>
                                  </span>
                                )}
                                <span className="ml-10 text-5xl flex flex-col items-center membersInfo">
                                  {communityData?.noOfParticipants}
                                  <span className="uppercase text-sm text-slate-500 ml-2">
                                    members
                                  </span>
                                </span>
                              </div>
                              {communityData?.admin === true ? (
                                <div className="joingroup joinGroupButtonAlign">
                                  <Button
                                    style={{
                                      width: "fit-content",
                                      color: "#804C31",
                                      borderWidth: "2px",
                                      borderColor: "#804C31",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      if (
                                        !communityData?.communityBlocked &&
                                        !communityData?.memberBlocked
                                      )
                                        setCreatePopup(true);
                                    }}
                                    disabled={
                                      communityData?.communityBlocked ||
                                      communityData?.memberBlocked
                                    }
                                  >
                                    <EditOutlined />
                                    <span className="showDesktop">
                                      Edit group info
                                    </span>
                                    <span className="showMobileButton ml-2">
                                      Edit
                                    </span>
                                  </Button>
                                  {communityData?.categoryName.includes(
                                    "Religion"
                                  ) ? (
                                    <Button
                                      style={{
                                        width: "fit-content",
                                        color: "#804C31",
                                        borderWidth: "2px",
                                        borderColor: "#804C31",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() => {
                                        if (
                                          !communityData?.communityBlocked &&
                                          !communityData?.memberBlocked
                                        )
                                          setShowSurvey(true);
                                      }}
                                      disabled={
                                        communityData?.communityBlocked ||
                                        communityData?.memberBlocked
                                      }
                                    >
                                      <InfoCircleOutlined />
                                      More Info
                                    </Button>
                                  ) : null}
                                  {createPopup && (
                                    <EditCommunityPopup
                                      id={communityData.id}
                                      communityData={communityData}
                                      religionFormData={religionFormData}
                                      onCancelClick={() => {
                                        setCreatePopup(false);
                                      }}
                                      onSaveClick={() => {
                                        toast.success(
                                          "Community Details Updated successfully"
                                        );

                                        getcommunityData();
                                      }}
                                      communityCategory={communityCategory}
                                    />
                                  )}
                                </div>
                              ) : communityData?.member === true ? (
                                <div className="joingroup joinGroupButtonAlign">
                                  <Button
                                    style={{
                                      width: "fit-content",
                                      color: "#804C31",
                                      borderWidth: "2px",
                                      borderColor: "#804C31",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CheckOutlined />
                                    Joined
                                  </Button>
                                  {communityData?.categoryName.includes(
                                    "Religion"
                                  ) ? (
                                    <Button
                                      style={{
                                        width: "fit-content",
                                        color: "#804C31",
                                        borderWidth: "2px",
                                        borderColor: "#804C31",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() => {
                                        setShowSurvey(true);
                                      }}
                                    >
                                      <InfoCircleOutlined />
                                      More Info
                                    </Button>
                                  ) : null}
                                </div>
                              ) : communityData?.requested === false ? (
                                <div className="joingroup joinGroupButtonAlign">
                                  <Button
                                    style={{
                                      width: "fit-content",
                                      color: "#804C31",
                                      borderWidth: "2px",
                                      borderColor: "#804C31",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() =>
                                      handleJoinCommunity(communityData.id)
                                    }
                                  >
                                    <UserAddOutlined />
                                    Join group
                                  </Button>
                                </div>
                              ) : (
                                <div className="joingroup joinGroupButtonAlign">
                                  <Button
                                    style={{
                                      width: "fit-content",
                                      color: "#a3c844",
                                      borderWidth: "2px",
                                      borderColor: "#a3c844",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IssuesCloseOutlined />
                                    Requested
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="px-10 communitypreviewdesc">
                        <p className="communityDataDescription">
                          {communityData?.communityBlocked ? (
                            <div className="blockedTextMessage">
                              This community has been blocked.
                            </div>
                          ) : communityData?.memberBlocked ? (
                            <div className="blockedTextMessage">
                              You have been blocked from this community by the
                              Admin.
                            </div>
                          ) : (
                            <div className="text-[#67702E]">
                              {communityData?.description}
                            </div>
                          )}
                        </p>

                        {communityData?.member === true ||
                        communityData?.type === "PUBLIC" ? (
                          <div className="flex">
                            {communityData.admin &&
                              !communityData?.communityBlocked &&
                              !communityData?.memberBlocked && (
                                <div
                                  onClick={() => {
                                    setAddNewMember(true);
                                    if (
                                      typeof window != "undefined" &&
                                      window.document
                                    ) {
                                      const feedsContent =
                                        document.getElementById(
                                          "communityWrapper"
                                        );
                                      if (feedsContent) {
                                        feedsContent.style.position = "fixed";
                                      }
                                    }
                                  }}
                                  className="flex flex-col items-center"
                                >
                                  <button className="feedscreatecommunityButton">
                                    <div className="feedsPlusButton">
                                      <i className="bi bi-gear"></i>
                                    </div>
                                  </button>
                                  <span className="postMembertitle1 capitalize pt-2 ">
                                    Community Settings
                                  </span>
                                </div>
                              )}
                            {members?.length > 0 && (
                              <div className="membersList">
                                {(isMobile || window?.innerWidth <= 1100
                                  ? members?.slice(0, 3)
                                  : members?.slice(0, 5)
                                )?.map((member: any, index: any) => (
                                  <div key={index} className={`eachMember`}>
                                    {!member?.profileIncomplete ? (
                                      member?.profileUrl ? (
                                        <div
                                          className={`capitalize relative inline-flex items-center  justify-center overflow-hidden rounded-lg member`}
                                          //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                          onClick={() =>
                                            navigate(
                                              `/profile/${member?.userId}`
                                            )
                                          }
                                          style={{
                                            backgroundColor:
                                              member.profileColorCode,
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img
                                            src={member?.profileUrl}
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          className={`capitalize relative inline-flex items-center  justify-center overflow-hidden rounded-lg member ${
                                            index === 0 ? "" : "" // Apply green border to the first element
                                          }`}
                                          onClick={() =>
                                            navigate(
                                              `/profile/${member?.userId}`
                                            )
                                          }
                                          style={{
                                            backgroundColor:
                                              member.profileColorCode,
                                            cursor: "pointer",
                                          }}
                                        >
                                          {index === 0 ? (
                                            <span
                                              className="text-5xl text-white dark:text-gray-300 h-12 flex flex-col items-center cursor-pointer justify-center"
                                              style={{ color: "white" }}
                                              onClick={() =>
                                                navigate(
                                                  `/profile/${member?.userId}`
                                                )
                                              }
                                            >
                                              <span>
                                                {member?.author?.substring(
                                                  0,
                                                  1
                                                )}
                                              </span>

                                              <p className="text-xs">ADMIN</p>
                                            </span>
                                          ) : (
                                            <span
                                              className="text-5xl text-white dark:text-gray-300 h-12 flex flex-col items-center cursor-pointer justify-center"
                                              style={{ color: "white" }}
                                              onClick={() =>
                                                navigate(
                                                  `/profile/${member?.userId}`
                                                )
                                              }
                                            >
                                              <span className="memberSubstring">
                                                {member?.author?.substring(
                                                  0,
                                                  1
                                                )}
                                              </span>
                                            </span>
                                          )}
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className={`capitalize relative inline-flex items-center  justify-center overflow-hidden rounded-lg member`}
                                        //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                        onClick={() =>
                                          toast.warn(
                                            "User has an incomplete profile"
                                          )
                                        }
                                        style={{
                                          backgroundColor: "#e0e0e0",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <i
                                          className="bi bi-person-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "gray",
                                          }}
                                        />
                                      </div>
                                    )}

                                    <div
                                      className="flex items-center flex-col memberName cursor-pointer"
                                      onClick={() =>
                                       {!member?.profileIncomplete ? navigate(`/profile/${member?.userId}`) : toast.warn(
                                        "User has an incomplete profile"
                                      )}
                                      }
                                    >
                                      <span className="postMembertitle capitalize pt-2 ">
                                        {index === 0 ? (
                                          <>
                                            <i className="bi bi-person-gear text-xl"></i>
                                            <span className="">
                                              {member?.author}
                                            </span>
                                          </>
                                        ) : (
                                          <span className="memberName">
                                            {!member?.profileIncomplete
                                              ? member?.author
                                              : member?.mobile
                                              ? `+91 ${member?.mobile
                                                  ?.toString()
                                                  ?.slice(2)}`
                                              : member?.email.split("@")[0]}
                                            {/* {!member?.profileIncomplete ? member?.author : member?.mobile ?`+91 ${member?.mobile?.toString()?.slice(2,)}` : <span style={{textTransform:"lowercase"}}>{member?.email}</span> } */}
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                                {(
                                  isMobile || window?.innerWidth <= 1100
                                    ? members?.length > 3
                                    : members?.length > 5
                                ) ? (
                                  <div className="eachMember">
                                    <div
                                      className="capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-lg member"
                                      onClick={() => setShowMembersModal(true)}
                                      style={{
                                        backgroundColor:
                                          "rgba(185 ,185 ,185,0.5)",
                                      }}
                                    >
                                      <i className="bi bi-three-dots"></i>
                                    </div>
                                    <div
                                      className="flex items-center flex-col memberName cursor-pointer"
                                      onClick={() => setShowMembersModal(true)}
                                    >
                                      <span className="postMembertitle capitalize pt-2 ">
                                        <span className="memberName">
                                          View More
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                                {showMembersModal && (
                                  <Modal
                                    centered
                                    open
                                    title={
                                      <div
                                        className="modalTitle"
                                        style={{ padding: "10px 0px 0px 10px" }}
                                      >
                                        Community Members
                                      </div>
                                    }
                                    onCancel={() => {
                                      setShowMembersModal(false);
                                    }}
                                    footer={false}
                                  >
                                    <div className="modalContainer">
                                      {members?.map(
                                        (member: any, index: any) => (
                                          <div
                                            key={index}
                                            className="flex flex-row items-center py-2 pl-5"
                                          >
                                            {!member?.profileIncomplete ? (
                                              member?.profileUrl ? (
                                                <div
                                                  className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                                                  //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                                  onClick={() =>
                                                    navigate(
                                                      `/profile/${member?.userId}`
                                                    )
                                                  }
                                                  style={{
                                                    backgroundColor:
                                                      member.profileColorCode,
                                                  }}
                                                >
                                                  <img
                                                    src={member?.profileUrl}
                                                    alt=""
                                                  />
                                                </div>
                                              ) : (
                                                <div
                                                  className={`capitalize relative inline-flex items-center cursor-pointer justify-center overflow-hidden rounded-full modalmember ${
                                                    index === 0 ? "" : "" // Apply green border to the first element
                                                  }`}
                                                  onClick={() =>
                                                    navigate(
                                                      `/profile/${member?.userId}`
                                                    )
                                                  }
                                                  style={{
                                                    backgroundColor:
                                                      member.profileColorCode,
                                                  }}
                                                >
                                                  <span
                                                    className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                                                    style={{
                                                      color: "white",
                                                      fontSize: "1.15rem",
                                                    }}
                                                    onClick={() =>
                                                      navigate(
                                                        `/profile/${member?.userId}`
                                                      )
                                                    }
                                                  >
                                                    <span className="memberSubstring">
                                                      {member?.author?.substring(
                                                        0,
                                                        1
                                                      )}
                                                    </span>
                                                  </span>
                                                </div>
                                              )
                                            ) : (
                                              <div
                                                className={`capitalize relative inline-flex items-center cursor-pointer justify-center overflow-hidden rounded-full modalmember ${
                                                  index === 0 ? "" : "" // Apply green border to the first element
                                                }`}
                                                onClick={() =>
                                                  navigate(
                                                    `/profile/${member?.userId}`
                                                  )
                                                }
                                                style={{
                                                  backgroundColor: "#e0e0e0",
                                                }}
                                              >
                                                <i
                                                  className="bi bi-person-fill"
                                                  style={{
                                                    fontSize: "30px",
                                                    color: "gray",
                                                  }}
                                                />
                                              </div>
                                            )}

                                            <div
                                              className="flex items-center flex-col modalmemberName cursor-pointer"
                                              style={{ color: "black" }}
                                              onClick={() =>
                                                navigate(
                                                  `/profile/${member?.userId}`
                                                )
                                              }
                                            >
                                              <span className="postMembertitle capitalize ml-2">
                                                {index === 0 ? (
                                                  <>
                                                    <i className="bi bi-person-gear text-xl"></i>
                                                    <span className="">
                                                      {member?.author}
                                                    </span>
                                                  </>
                                                ) : (
                                                  <div className="flex flex-col items-start">
                                                    <span className="modalmemberName">
                                                      {!member?.profileIncomplete ? (
                                                        member?.author
                                                      ) : member?.mobile ? (
                                                        `+91 ${member?.mobile
                                                          ?.toString()
                                                          ?.slice(2)}`
                                                      ) : (
                                                        <span
                                                          style={{
                                                            textTransform:
                                                              "lowercase",
                                                          }}
                                                        >
                                                          {member?.email}
                                                        </span>
                                                      )}
                                                    </span>
                                                    {member?.profileIncomplete && (
                                                      <span className="text-xs text-slate-500">
                                                        Incomplete profile
                                                      </span>
                                                    )}
                                                  </div>
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Modal>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          communityData?.type === "PRIVATE" && null
                        )}
                      </div>
                    </div>
                    {communityData?.member === false &&
                    communityData.type === "PRIVATE" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        className="my-10"
                      >
                        <img
                          src={privateCommunity}
                          style={{ height: "30%", width: "20%" }}
                          className=""
                          alt=""
                        />
                        <p className="text-4xl privatetxt">
                          This community is private
                        </p>
                        <p className="text-2xl mt-2 mb-5 text-slate-500 privatetxt">
                          Join to see their posts
                        </p>
                      </div>
                    ) : (
                      <div className="px-10 setPaddingMobile">
                        {postsLoader ? (
                          <div className="w-full flex justify-center">
                            <Loader />
                          </div>
                        ) : (
                          <CommunityPosts
                            id={props?.id}
                            posts={posts}
                            getCommunityPosts={() => getCommunityPosts()}
                            communityBlocked={communityData?.communityBlocked}
                            memberBlocked={communityData?.memberBlocked}
                            isMember={communityData?.member}
                            postsOnlyByAdmin={communityData?.postsOnlyByAdmin}
                            admin={communityData?.admin}
                            tagsList={tags}
                            selectedTags={selectedTag}
                            setSelectedTags={setSelectedTag}
                            refreshPosts={refreshPosts}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
            <div className="feedsRightContainer">
              <div className="feedsRight">
                {/* <div className="feedsRightSideTop">
              <ActiveUsers id={props?.id} />
            </div> */}
                <div
                  className="feedsRightSideTop"
                  style={{
                    minHeight:
                      posts?.length >= 0 && posts?.length < 2
                        ? "10rem"
                        : posts?.length >= 2 && posts?.length < 4
                        ? "12rem"
                        : posts?.length >= 4
                        ? "16rem"
                        : "10rem",
                  }}
                >
                  {false ? (
                    <div className="w-full flex justify-center">
                      <Loader />
                    </div>
                  ) : (
                    <LatestActivity
                      id={props?.id}
                      type={communityData?.type}
                      member={communityData?.member}
                      posts={posts}
                    />
                  )}
                </div>
                <div className="feedsRightSideMiddle">
                  {/* Your latest activity content */}
                  <Trending />
                </div>
              </div>
            </div>
          </div>
          {showSurvey && (
            <Modal
              centered
              open
              onCancel={() => {
                setShowSurvey(false);
              }}
              footer={false}
            >
              <Survey.Survey
                json={religionForm}
                data={formDataObject}
                isReadOnly={true}
                mode={"display"}
              />
            </Modal>
          )}
          <div className={createPopup ? "overlay show" : "overlay"}></div>
          {addNewMember && (
            <AddnewMemberPopup
              onCancelClick={() => {
                setAddNewMember(false);
                getcommunityData();
                if (typeof window != "undefined" && window.document) {
                  const feedsContent =
                    document.getElementById("communityWrapper");
                  if (feedsContent) {
                    feedsContent.style.position = "unset";
                  }
                }
              }}
              commuinityId={props.id}
              membersList={members}
              onSaveClick={() => {
                setAddNewMember(false);
                if (typeof window != "undefined" && window.document) {
                  const feedsContent =
                    document.getElementById("communityWrapper");
                  if (feedsContent) {
                    feedsContent.style.position = "unset";
                  }
                }
              }}
              getmembersData={() => getMembersData()}
            />
          )}
        </div>
      )}
    </>
  );
}

export default CommunityPreviewComponent;
