import React, { useEffect, useState } from "react";
import "./ChatSidebar.css";
import profilePlaceholder from "../../assets/images/MatrimonyProfilePlaceholder.png";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { request } from "../../Utils/request";
import { useUserMatrimonyContext } from "../../Context/UserMatrimonyContext";
import { useChatContext } from "../../Context/ChatContext";
import ChatSelectUserComponent from "./ChatSelectUserComponent";
import { isMobileOnly } from "../../Utils/mobileSettings";
import { useNavigate } from "react-router";
import { LeftOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const ChatSidebar: React.FC<any> = () => {
  const [yourMatrimonyList, setYourMatrimonyList] = useState<any>([]);
  const [chatList, setChatList] = useState<any>([]);
  const { userMatrimonyData } = useUserMatrimonyContext();
  const { chatData, setChatDataFunction } = useChatContext();
  const navigate = useNavigate();

  const getYourMatrimonyData = async () => {
    // Assuming request is a function that fetches matrimony data

    const resp = await request(
      `/member/current/matrimony-profile/active/profiles`
    );
    if (resp?.length > 0) {
      setYourMatrimonyList(resp);
    } else {
      toast.error(`To allow conversation, create a Matrimony profile.`);
      navigate("/matrimony");
      console.error("error");
    }
  };

  useEffect(() => {
    getYourMatrimonyData();
  }, []);

  useEffect(() => {
    const getChatList = () => {
      if (!userMatrimonyData?.matrimonyId) return; // Don't proceed if matrimonyId is not available

      const unsub = onSnapshot(
        doc(db, "recentChats", userMatrimonyData?.matrimonyId),
        (doc) => {
          setChatList(doc.data() || {});
        }
      );
      return () => {
        unsub();
      };
    };

    const authenticateUser = async () => {
      if (!userMatrimonyData?.matrimonyId) return;
      const resp = await request(
        `/member/current/authenticate/matrimony-profile/${userMatrimonyData?.matrimonyId}`
      );
      if (resp?.auth) {
        getChatList();
      }
    };

    userMatrimonyData?.matrimonyId && authenticateUser();
    // userMatrimonyData?.matrimonyId && getChatList();
  }, [userMatrimonyData?.matrimonyId]);

  const handleSelect = (value: any) => {
    setChatDataFunction(value);
    if (isMobileOnly) {
      navigate("/chat/chat-details");
    }
  };

  function truncateString(str: string, maxLength: number) {
    if (str?.length > maxLength) {
      return str?.substring(0, maxLength) + "...";
    }
    return str;
  }

  return (
    <div className="chatSidebar rounded-l-2xl flex">
      <div className="w-full">
        <div
          className={
            isMobileOnly
              ? "sticky top-0 flex items-center relative bg-[#804C31] -mt-2"
              : "sticky top-0 flex items-center relative "
          }
        >
          {isMobileOnly ? (
            <div className="z-50 h-full p-2">
              <button onClick={() => window.history.back()}>
                <LeftOutlined className="text-white" />
              </button>
            </div>
          ) : (
            <></>
          )}
          <ChatSelectUserComponent />
        </div>
        <div className="chatsList overflow-y-auto z-0">
          <div>
            {!(Object.keys(chatList).length === 0) ? (
              <>
                {Object.entries(chatList)
                  ?.sort((a: any, b: any) => b[1].date - a[1].date)
                  ?.map((chat: any) => (
                    <div
                      className={
                        chatData?.matrimonyId === chat[1]?.userInfo?.matrimonyId
                          ? "userChat cursor-pointer w-full rounded-lg bg-[#ded0c2]"
                          : "userChat cursor-pointer w-full rounded-lg hover:bg-[#ded0c2] "
                      }
                      key={chat[0]}
                      onClick={() => handleSelect(chat[1].userInfo)}
                    >
                      {chat[1].userInfo?.profileUrl?.length > 0 ? (
                        <img
                          src={chat[1].userInfo?.profileUrl[0]}
                          alt="profile pic"
                          className="chatImg"
                          style={{ aspectRatio: 3 / 2 }}
                        />
                      ) : (
                        <img
                          src={profilePlaceholder}
                          alt="profile pic"
                          className="chatImg"
                        />
                      )}
                      <div className="userChatInfo">
                        <span className="chatUserName text-semibold">
                          {chat[1].userInfo?.member?.name}
                        </span>
                        <p className="lastText">
                          {truncateString(chat[1].lastMessage?.text, 40)}
                        </p>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <div className="text-gray-600 my-40">
                <div className="flex justify-center items-center">
                  <i className="bi bi-chat-text text-4xl"></i>
                </div>
                <div className="flex justify-center items-center text-lg">
                  Your chat history is currently empty.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSidebar;
