import React, { useState, useEffect } from "react";
import { TIMEAGO } from "../../Utils/timeago";
// import { Input, Button, Modal } from "antd";
import "./FeedsPreview.css";
import {
  SendOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  LikeOutlined,
  LikeTwoTone,
  DislikeTwoTone,
  DislikeOutlined,
  ShareAltOutlined,
  LinkOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import Loader from "../Loader/Loader";
import { request } from "../../Utils/request";
import { isMobile } from "../../Utils/mobileSettings";
import CommentIcon from "../../assets/svg icons/Group 110.svg";
import commentDisabled from "../../assets/svg icons/comments disable.svg";
import FeedsComponentPopup from "./FeedsComponentPopup";
import LinkPreview from "./LinkPreview";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import { getExpireDate } from "../../Utils/timeago";
import { Input, Button, Modal, Radio, Spin } from "antd";
import { videoType, imageType } from "../../Utils/VideoType";


function FeedsPreview() {
  const [popupImgVideo, setPopupImgVideo] = useState<any>({});
  const [imgPrevNext, setImgPrevNext] = useState<any>(0);
  const [particularPost, setParticularPost] = useState<any>({});
  const [comment, setComment] = useState<any>("");
  const [postId, setPostId] = useState<any>("");
  const [currentCommentPage, setCurrentCommentPage] = useState<any>(0);
  const [postComments, setPostComments] = useState<any[]>([]);
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [sharePost, setSharePost] = useState<any>();
  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [commentRepliesMap, setCommentRepliesMap] = useState<{
    [key: string]: any[];
  }>({});
  const [replyingToCommentId, setReplyingToCommentId] = useState<any>({});
  const [particularPostReply, setParticularPostReply] = useState<any>("");
  const [showPoll, setShowPoll] = useState<boolean>(false);
  const [particularPostPoll, setParticularPostPoll] = useState<any>({});
  const [remainingPollUsers, setRemainingPollUsers] = useState<any>({});
  const [loading, setLoading] = useState<any>(true)
  const [isSending, setIsSending] = useState(false); // State to track if the comment is being sent
  const [isReplySending, setIsReplySending] = useState(false); // State to track if the reply is being sent
  const navigate = useNavigate();

  const {pid} = useParams();
  
  const getPostData = async (postID: any) => {
    setPostId(pid);
    try {
      const response = await request(`/member/current/post/${postID}`);
      if (response) {
        if(response?.metaData || response?.youtubeURL){
        const { metaData = [], youtubeURL = [] } = response;
        const combinedURLs = metaData?.concat(youtubeURL)?.filter(Boolean);
  
        const formattedURLs = combinedURLs?.map((url:any) => ({
          url,
          type: videoType?.some((fileType) => url?.includes(fileType)) ? "video" : "photo",
          altTag: "Image Video",
        }));
  
        const postsWithCombinedURLs = {
          ...response,
          combinedURLs: formattedURLs,
        };
  
        setPopupImgVideo(postsWithCombinedURLs?.combinedURLs[0]);
        setParticularPost(postsWithCombinedURLs);
      }else{
        setParticularPost(response)
      }
      }
      if(response?.noOfComments >0){
            gettingComments(postID);
        }
    } catch (error) {
      console.error("Error fetching post data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
      getPostData(pid);
      window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pid]);

  useEffect(() => {
    let dt = particularPost?.combinedURLs?.[imgPrevNext];
    setPopupImgVideo(dt);
  }, [imgPrevNext]);

  const displaySharePost = (postItem: any, from?: any) => {
    let postData = { ...postItem };
    if (postItem?.member) {
      if (from === "post" && postItem?.combinedURLs) {
        let data = [];
        let post = postItem?.combinedURls;
        for (let i = 0; i < post?.length; i++) {
          let format = {
            url: post[i],
            type: videoType.some((fileType) => post[i].includes(fileType))
              ? "video"
              : "photo",
            altTag: "Image Video",
          };
          data.push(format);
        }
        postData["combinedURLs"] = data;
      }
      setSharePost(postData);
      isMobile ? setShareModal(true) : setCreatePopup(true);
    }
  };

  const LikeAndDislikeCall = async (
    type: string,
    data: any,
    index?: number
  ) => {
    const post = data;
    const status = post?.currentSentimentStatus;
    const body = {
      method: "POST",
      body: JSON.stringify({
        communityId: post?.communityId,
        sentimentType:
          status === null ? type : status === type ? "NONPARTICIPATING" : type,
      }),
    };

    const resp = await request(
      `/member/current/post/${post?.postId}/sentiment`,
      body
    );
    if (resp?.id) {
      let newParticularPost = particularPost;
      newParticularPost = {
        ...newParticularPost,
        currentSentimentStatus: resp?.sentimentType,
        noOfDislikes: resp?.noOfDislikes,
        noOfLikes: resp?.noOfLikes,
      };

      setParticularPost(newParticularPost);
    }
  };

  const LikeAndDisLikeComponent = (
    post: any,
    previewPopup?: boolean,
    index?: number
  ) => {
    return (
      <>
        <div className="flex flex-row gap-6 items-center mr-6">
          <div className="flex gap-2">
            {previewPopup && (
              <div className="text-base font-light ml-2">
                {" "}
                {post?.noOfLikes > 0 ? post?.noOfLikes : ""}
              </div>
            )}
            {post?.member ? (
              !post?.communityBlocked && !post?.memberBlocked ? (
                post?.currentSentimentStatus === "LIKE" ? (
                  <LikeTwoTone
                    onClick={(event) => {
                      LikeAndDislikeCall("NONPARTICIPATING", post, index);
                      event?.stopPropagation();
                    }}
                    className="like-share-icon"
                  />
                ) : (
                  <LikeOutlined
                    onClick={(event) => {
                      LikeAndDislikeCall("LIKE", post, index);
                      event?.stopPropagation();
                    }}
                    className="like-share-icon"
                  />
                )
              ) : (
                <LikeOutlined
                  disabled
                  className="like-share-icon  disabled:opacity-15"
                  style={{ color: "gray" }}
                />
              )
            ) : (
              <LikeOutlined
                disabled
                className="like-share-icon  disabled:opacity-15"
                style={{ color: "gray" }}
              />
            )}
            <span className={(!post?.communityBlocked && !post?.memberBlocked) ? "" : "text-gray-500"}>{
              !previewPopup &&
              // <div className="text-base">
              // {" "}
              post?.noOfLikes > 0
                ? post?.noOfLikes
                : ""
              // </div>
            }</span>
            {previewPopup ? <span className="mr-4">Like</span> : ""}
          </div>
          <div className="flex gap-2">
            {post?.member ? (
              !post?.communityBlocked && !post?.memberBlocked ? (
                post?.currentSentimentStatus === "DISLIKE" ? (
                  <DislikeTwoTone
                    onClick={(event) => {
                      LikeAndDislikeCall("NONPARTICIPATING", post, index);
                      event.stopPropagation();
                    }}
                    className="like-share-icon"
                  />
                ) : (
                  <DislikeOutlined
                    onClick={(event) => {
                      LikeAndDislikeCall("DISLIKE", post, index);
                      event.stopPropagation();
                    }}
                    className="like-share-icon"
                  />
                )
              ) : (
                <DislikeOutlined
                  disabled
                  className="like-share-icon"
                  style={{ color: "gray" }}
                />
              )
            ) : (
              <DislikeOutlined
                disabled
                className="like-share-icon"
                style={{ color: "gray" }}
              />
            )}
            {previewPopup ? <span>Dislike</span> : ""}
          </div>
        </div>
      </>
    );
  };


  const gettingComments = async (postID: any) => {
    try {
      const response = await request(
        `/member/current/comments/${pid}?page=${
          currentCommentPage || 0
        }&size=5`
      );
      if (response?.content?.length > 0) {
        // setPostComments(response?.content);
        const commentsWithReplies = await Promise.all(
          response?.content?.map(async (comment: any) => {
            if (comment?.noOfReplies > 0) {
              const replies = await gettingPostReply(comment?.commentId);
              return { ...comment, replies };
            }
            return comment;
          })
        );

        setPostComments((prevComments: any) => ({
          ...prevComments,
          [postID]:
            currentCommentPage === 0
              ? commentsWithReplies
              : [...(prevComments[postID] || []), ...commentsWithReplies],
        }));
      }
    } catch (error) {}
  };
  const gettingPostReply = async (commentID: any) => {
    try {
      const response = await request(
        `/member/current/comment/${commentID}/reply`
      );
      if (response?.length > 0) {
        setCommentRepliesMap((prevComments: any) => ({
          ...prevComments,
          [commentID]: response,
        }));
      }
    } catch (error) {
      // Handle error
    }
  };
  const replyToComment = (commentId: string) => {
    setReplyingToCommentId(commentId); // Store the comment ID being replied to
  };
  const sendReply = async (
    commentId: string,
    replyText: string,
    postId: string
  ) => {
    // Add the reply to the commentRepliesMap under the specific comment ID
    if (replyText) {
      const abusiveResult = await CheckBadWordsExist(replyText);
      setIsReplySending(true)
      if (abusiveResult?.status === true) {
        if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
          try {
            const body = {
              method: "POST",
              body: JSON.stringify({
                commentId: commentId,
                postId: postId,
                content: replyText,
                replyType: "FIRST_REPLY",
              }),
            };
            const response = await request("/member/current/reply", body);
            setParticularPostReply("");
            gettingPostReply(commentId);
          } catch (error) {
            console.error("Error===>", error);
          }
        } else if (abusiveResult?.profanity) {
          toast.error("Prohibited from generating abusive content");
        }
      } else {
        toast.error(abusiveResult?.message);
      }
      setIsReplySending(false)
    }
  };
  useEffect(() => {
    if (postId?.length > 0) gettingComments(postId);
  }, [currentCommentPage]);

  const sendComments = async () => {
    if (comment) {
      const abusiveResult = await CheckBadWordsExist(comment);
      setIsSending(true); // Disable input and button
      if (abusiveResult?.status === true) {
        if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
          try {
            const body = {
              method: "POST",
              body: JSON.stringify({
                postId: postId,
                content: comment,
              }),
            };

            const response = await request("/member/current/comment", body);
            if (response?.commentId) {
              let newPost = particularPost;
              newPost = {
                ...newPost,
                noOfComments: response?.noOfCommentsOfPost,
              };
              setParticularPost(newPost);
            }

            setComment("");
            setCurrentCommentPage(0);
            gettingComments(postId);
          } catch (error) {
            console.error("Error===>", error);
          }
        } else if (abusiveResult?.profanity) {
          toast.error("Prohibited from generating abusive content");
        }
      } else {
        toast.error(abusiveResult?.message);
      }
      setIsSending(false); // Enable input and button
    }
  };
  const loadMoreComments = () => {
    setCurrentCommentPage((Page: any) => Page + 1);
  };
  function getVideoId(url: any) {
    const patterns = [
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch(?:\/|.+?)?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([a-zA-Z0-9_-]{11}).*/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11}).*/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  }
  function postlink(content: any) {
    // const youtubeUrlPattern =
    //   /(?<!href="|target="_blank">)https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+(?:&[\w=%]*)?/g;

    // // Remove YouTube URLs from the input string
    // const processedString = content?.replace(youtubeUrlPattern, "");
    // console.log(processedString);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        // key={index}
        className="rich-html-text"
      />
    );
  }
  const onRadioButtonChange = async (
    postId: string,
    optionValue: any,
    participated: any
  ) => {
    if (!participated) {
      const requestPayload = {
        method: "POST",
        body: JSON.stringify({
          postId,
          option: optionValue,
        }),
      };
      const resp = await request(
        `/member/current/poll/fill/${postId}?option=${optionValue}`,
        requestPayload
      );
      let postCopy: any = particularPost;
      // const findIndex = postCopy.findIndex((item) => item.postId === resp.postId);
      postCopy = {
        ...postCopy,
        pollOutput: resp?.pollOutput,
      };
      setParticularPost(postCopy);
    }
  };
  const onViewMoreClick = (post: any, postItem: any) => {
    let postCopy = post;
    // const findIndex = postCopy.findIndex((item) => item.postId === post.postId);
    postCopy = {
      ...postCopy,
      viewMore: true,
      pollOutput: {
        ...postCopy.pollOutput,
        viewMore: true,
      },
    };
    setParticularPost(postCopy);
  };
  const displayPollData = (post: any) => {
    let postData = post;
    setParticularPostPoll(postData);
    const resetRemainingPollUsers: any = {};
    post?.pollOutput?.options?.forEach((poll: any) => {
      resetRemainingPollUsers[poll.option] = null;
    });
    setRemainingPollUsers(resetRemainingPollUsers);
    setShowPoll(true);
  };
  const handleViewAllUsers = (selectedOption: any) => {
    const usersVotedForOption = particularPostPoll?.pollOutput?.options?.find(
      (poll: any) => poll.option === selectedOption
    )?.users;
    if (usersVotedForOption) {
      setRemainingPollUsers({
        ...remainingPollUsers,
        [selectedOption]: usersVotedForOption.slice(3),
      });
    }
  };
  return (
    <>
     {loading ? (
        <div className="flex justify-center items-center mt-8">
          <Loader />{" "}
        </div>
      ) : (
    <div className="feedsPreviewWrapper">
      <div className="feeds-modal-preview">
        <div className="feedsHeaderWrapper">
          <div className="PreviewFeeds-header">
            <div
              className="feeds-header-avatar"
              style={{ backgroundColor: `${particularPost?.profileColorCode}` }}
            >
              {particularPost?.userProfileUrl !== null ? (
                <img
                  src={particularPost?.userProfileUrl}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "100%",
                  }}
                  alt=""
                />
              ) : (
                particularPost?.userName?.substring(0, 1)
              )}
            </div>
            <div className="feed" style={{ width: "90%" }}>
              <p>
                <strong className="textFont capitalize text-[#804C31]">{particularPost?.userName}</strong>
                <span className="ml-1 mr-1 text-[#804C31]">&#8594;</span>
                <strong className="textFont text-[#804C31]">
                  {particularPost?.communityName}
                </strong>
              </p>
              <div className="text-sm">
                <TIMEAGO timestamp={particularPost?.createdAt} />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-row tagContainer">
              {particularPost?.tags
                ?.filter((postTag: any) => postTag?.toLowerCase() !== "general")
                ?.map((tag: any) => (
                  <div className="flex flex-row tag">{tag}</div>
                ))}
            </div>
          </div>
          <div className="overflow-wrap">
            {postlink(particularPost?.content)}
          </div>
        </div>
        {particularPost?.pollOutput !== null ? (
          <div className="postContent">
            <div className="pollContentContainer">
              <div>
                <p className="postOptionContent text-xl font-bold">
                  {particularPost?.pollOutput?.question}
                </p>
                <p className="postOptionContent font-normal">
                  {particularPost?.pollOutput?.comment}
                </p>
              </div>
              <div
                className="poll-voting-options"
                style={{
                  height: particularPost?.viewMore
                    ? "400px"
                    : particularPost?.pollOutput?.options?.length === 2
                    ? "200px"
                    : "250px",
                  overflow: particularPost?.viewMore ? "auto" : "hidden",
                }}
              >
                <Radio.Group
                  className="radio-options-group"
                  value={
                    particularPost?.pollOutput?.options?.find(
                      (item: any) => item?.participated
                    )?.option || ""
                  }
                >
                  {particularPost?.pollOutput?.options.map((pollItem: any) => (
                    <div
                      onClick={(e) => {
                        if (
                          particularPost?.pollOutput?.pollStatusEnum !==
                          "EXPIRED"
                        ) {
                          e.stopPropagation();
                          onRadioButtonChange(
                            particularPost?.postId,
                            pollItem?.option,
                            pollItem?.participated
                          );
                        } else {
                          // toast.error('')
                        }
                      }}
                      className={`pollOptions ${
                        pollItem?.participated ? "option-selected" : "unSelect"
                      }`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor:
                          particularPost?.pollOutput?.pollStatusEnum !==
                          "EXPIRED"
                            ? "pointer"
                            : "auto",
                      }}
                    >
                      {particularPost?.pollOutput?.pollStatusEnum !==
                      "EXPIRED" ? (
                        <div>
                          <Radio
                            style={{
                              display: "hidden",
                            }}
                            className="radio-option"
                            value={pollItem?.option}
                          >
                            <div className="testing">
                              <div>
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                >
                                  {pollItem?.option}
                                </span>
                              </div>
                            </div>
                          </Radio>
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{
                              display: "hidden",
                            }}
                            className="radio-option"
                            // value={pollItem?.option}
                          >
                            <div className="testing">
                              <div>
                                <span>{pollItem?.option}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {particularPost?.pollOutput?.pollStatusEnum ===
                        "EXPIRED" ||
                      particularPost?.pollOutput?.pollTypeEnum === "OPEN" ? (
                        <div className="text-[18px] pr-3 text-[#808080] font-medium">
                          <span>
                            {pollItem?.votes}{" "}
                            {pollItem?.votes === 1 ? "vote" : "votes"}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </Radio.Group>
              </div>

              {particularPost?.pollOutput?.options?.length > 3 &&
                !particularPost?.viewMore && (
                  <div
                    className="text-[#06be51] text-lg font-medium"
                    onClick={() => {
                      onViewMoreClick(
                        particularPost,
                        particularPost?.pollOutput?.options
                      );
                    }}
                  >
                    {particularPost?.pollOutput?.options?.length - 3 === 1
                      ? `+${
                          particularPost?.pollOutput?.options?.length - 3
                        } More option`
                      : ` +${
                          particularPost?.pollOutput?.options?.length - 3
                        } More options`}
                  </div>
                )}

              <div
                className="flex justify-between items-center postOptionContent mt-2"
                style={{ cursor: "context-menu" }}
              >
                <p className="md:text-base text-sm font-semibold">
                  {particularPost?.pollOutput?.pollStatusEnum === "ACTIVE"
                    ? `Poll Expiry :  ${getExpireDate(
                        particularPost?.pollOutput?.expiryDate
                      )}`
                    : "Poll Expired"}
                </p>
                {(particularPost?.pollOutput?.pollStatusEnum === "EXPIRED" ||
                  particularPost?.pollOutput?.pollTypeEnum === "OPEN" ||
                  (particularPost?.pollOutput?.pollTypeEnum === "HIDDEN" &&
                    particularPost?.pollOutput?.viewVoters)) && (
                  <span
                    className="text-[#06be51] md:text-lg text-sm font-medium"
                    style={{
                      cursor: particularPost?.pollOutput?.viewVoters
                        ? "pointer"
                        : "context-menu",
                    }}
                    onClick={() =>
                      particularPost?.pollOutput?.viewVoters
                        ? displayPollData(particularPost)
                        : null
                    }
                  >
                    Total votes : {particularPost?.pollOutput?.totalVotes}
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          popupImgVideo && (
            <>
              <div className="feeds-img-video-container">
                <div className="postsImage">
                  <>
                    {imgPrevNext !== 0 && (
                      <div
                        className="prev-icon"
                        onClick={() =>
                          setImgPrevNext((prevState: any) =>
                            prevState - 1 <= 0 ? 0 : prevState - 1
                          )
                        }
                      >
                        <LeftCircleOutlined />
                      </div>
                    )}
                    <div>
                      {popupImgVideo?.type === "video" ? (
                        <video className="video" controls>
                          <source src={popupImgVideo?.url} type="video/mp4" />
                        </video>
                      ) : imageType.some((fileType) =>
                          popupImgVideo?.url?.includes(fileType)
                        ) ? (
                        <img
                          className="feedsPreviewImage"
                          src={popupImgVideo?.url}
                          alt=""
                        />
                      ) : popupImgVideo?.url?.includes("youtu") ? (
                        <iframe
                          title="youtube"
                          loading="lazy"
                          src={`https://www.youtube.com/embed/${getVideoId(
                            popupImgVideo?.url
                          )}?autoplay=1`}
                          width="100%"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          className="singlePost"
                          style={{
                            backgroundColor: "black",
                          }}
                          srcDoc={`<style>
  * {
  padding: 0;
  margin: 0;
  overflow: hidden;
  }
  
  body, html {
  height: 100%;
  }
  
  img, svg {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
  }
  
  svg {
  filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
  transition: all 250ms ease-in-out;
  }
  
  body:hover svg {
  filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
  transform: scale(1.2);
  }
  </style>
  <a href='https://www.youtube.com/embed/${getVideoId(
    popupImgVideo?.url
  )}?autoplay=1'>
  <img src='https://img.youtube.com/vi/${getVideoId(
    popupImgVideo?.url
  )}/hqdefault.jpg' alt='Coffee Recipe Javascript Project'><div class='play-button'>
  <button class="ytp-large-play-button ytp-button ytp-large-play-button-red-bg" aria-label="Play" title="Play"><svg version="1.1"  width='50' height='50' viewBox='0 0 60 50'><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></button>
  </div> </a>
  `}
                        />
                      ) : (
                        <LinkPreview url={popupImgVideo?.url} from="MODAL" />
                      )}
                    </div>

                    {particularPost?.combinedURLs?.length - 1 ===
                    imgPrevNext ? (
                      ""
                    ) : (
                      <div
                        className="next-icon"
                        onClick={() =>
                          setImgPrevNext((prevState: any) =>
                            prevState + 1 >
                            particularPost?.combinedURLs?.length - 1
                              ? prevState
                              : prevState + 1
                          )
                        }
                      >
                        <RightCircleOutlined />
                      </div>
                    )}
                  </>
                </div>
              </div>
            </>
          )
        )}
        <div className="commentinfo ml-1 flex justify-between">
          <div className="flex  gap-1">
            {LikeAndDisLikeComponent(particularPost, false)}
            <div
              className="post-comments"
              onClick={(event) => {
                // IsMobileClick(post);
                // displayImgVideo(particularPost);
                event.stopPropagation();
              }}
            >
              <span className="mr-2 flex flex-row items-center">
                <img
                  src={
                    particularPost?.member
                      ? !particularPost?.communityBlocked && !particularPost?.memberBlocked
                        ? CommentIcon
                        : commentDisabled
                      : commentDisabled
                  }
                  className="text-xl commenticon"
                  alt=""
                />{" "}
                <span className={  particularPost?.member
                      ? !particularPost?.communityBlocked && !particularPost?.memberBlocked
                        ? "text-lg ml-3"
                      : "text-lg ml-3 disabled"
                    :"text-lg ml-3 disabled"}>
                  {particularPost?.noOfComments > 0 
                   ? particularPost?.noOfComments 
                   : null}
                </span>{" "}
              </span>
            </div>
            {particularPost?.type !== "POLL" && (
              <div
                className="flex items-center cursor-pointer"
                onClick={(event) => {
                  if (particularPost?.member) {
                    if (!particularPost?.communityBlocked && !particularPost?.memberBlocked)
                      displaySharePost(particularPost, "modal");
                  }
                  event?.stopPropagation();
                }}
              >
                <ShareAltOutlined
                  style={{
                    color: particularPost?.member
                      ? !particularPost?.communityBlocked && !particularPost?.memberBlocked
                        ? "black"
                        : "gray"
                      : "gray",
                  }}
                  className="like-share-icon"
                />
              </div>
            )}
          </div>

          {isMobile ? null : <TIMEAGO timestamp={particularPost?.createdAt} />}
        </div>
        {particularPost?.member ? (
          !particularPost?.communityBlocked && !particularPost?.memberBlocked ? (
            <>
              <div className="msg-chat-input">
                {localStorage?.getItem("profileUrl") !== "null" ? (
                  <div
                    className={`capitalize relative inline-flex items-center 
                      justify-center w-10 h-10 overflow-hidden  rounded-full`}
                    onClick={() =>
                      navigate(`/profile/${particularPost?.userId}`)
                    }
                  >
                    <span className="font-medium ">
                      <img
                        src={localStorage?.getItem("profileUrl") || ""}
                        alt=""
                        style={{
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor:
                        localStorage?.getItem("profileColorCode") || "",
                    }}
                    onClick={() =>
                      navigate(`/profile/${particularPost?.userId}`)
                    }
                    className={`capitalize relative inline-flex items-center 
                      justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                  >
                    <span className="font-medium text-[#FFFFFF]">
                      {localStorage?.getItem("name")?.substring(0, 1)}
                    </span>
                  </div>
                )}
                <Input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Type your comments..."
                  onPressEnter={() => sendComments()}
                  maxLength={1000}
                  disabled={isSending} // Disable input when sending
                />
                    {isSending?
                         <Spin className="msg-send-icon" style={{borderRadius:"50%"}}/>
                         :
                <Button
                  onClick={() => sendComments()}
                  className="msg-send-icon"
                  shape="circle"
                  icon={<SendOutlined />}
                  disabled={isSending} // Disable input when sending
                />
                    }
              </div>
              {comment?.length > 0 && (
                <p className="commentRemainingCharacters">
                  {comment?.length} / 1000
                </p>
              )}
            </>
          ) : (
            <div className="msg-chat-input">
              <Input
                placeholder="Comments have been disabled"
                disabled={true}
              />
              <Button
                className="msg-send-icon"
                shape="circle"
                disabled={true}
                icon={<SendOutlined />}
              />
            </div>
          )
        ) : (
          <div className="msg-chat-input">
            <Input
              placeholder="Join the community to comment"
              disabled={true}
            />
            <Button
              className="msg-send-icon"
              shape="circle"
              disabled={true}
              icon={<SendOutlined />}
            />
          </div>
        )}
        {postComments[particularPost?.postId]?.length > 0 && (
          <div className="comment-section">
            {postComments[particularPost?.postId]?.map(
              (item: any, index: any) => (
                <>
                  <div className="flex items-center py-1">
                    {item?.userProfileUrl !== null ? (
                      <div
                        className="capitalize relative py-1 mr-4
                                  rounded-full flex items-center justify-center"
                      >
                        <img
                          src={item?.userProfileUrl}
                          style={{
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div
                        className="capitalize relative  text-[#FFFFFF] p-1 mr-4
                                  flex items-center justify-center"
                        style={{
                          backgroundColor: item?.profileColorCode,
                          width: "45px",
                          height: "45px",
                          borderRadius: "50%",
                        }}
                      >
                        {item?.author?.substring(0, 1)}
                      </div>
                    )}

                    {/* <div className="comment-content">
                  <p>
                    <strong>{item?.author}</strong>
                  </p>
                  <h3>{item?.content}</h3>
                  <div className="flex justify-end">
                    <TIMEAGO timestamp={item?.createdAt} />
                  </div>
                </div> */}
                    <div className="comment-content-container">
                      <div className="comment-content">
                        <p>
                          <strong>{item?.author}</strong>
                        </p>
                        <h3>{item?.content}</h3>
                        <div className="flex justify-end">
                          <TIMEAGO timestamp={item?.createdAt} />
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-end">
                        <div
                          className="flex justify-end reply-button"
                          onClick={() => {
                            replyToComment(item?.commentId);
                          }}
                        >
                          Reply
                        </div>
                        {item?.noOfReplies > 0 && (
                          <div
                            className="flex justify-end noOfReplies ml-1"
                            onClick={() => {
                              replyToComment(item?.commentId);
                            }}
                          >
                            <i className="bi bi-dot"></i> {item?.noOfReplies}{" "}
                            {item?.noOfReplies > 1 ? "Replies" : "Reply"}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {replyingToCommentId === item?.commentId &&
                    (particularPost?.member ? (
                      <>
                        {!particularPost?.communityBlocked && !particularPost?.memberBlocked ? (
                          <div className="reply-chat-input">
                            {localStorage?.getItem("profileUrl") !== "null" ? (
                              <div
                                className={`capitalize relative inline-flex items-center 
                                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                onClick={() =>
                                  navigate(`/profile/${particularPost?.userId}`)
                                }
                              >
                                <span className="font-medium ">
                                  <img
                                    src={
                                      localStorage?.getItem("profileUrl") || ""
                                    }
                                    alt=""
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </span>
                              </div>
                            ) : (
                              <div
                                style={{
                                  backgroundColor:
                                    localStorage?.getItem("profileColorCode") ||
                                    "",
                                }}
                                onClick={() =>
                                  navigate(`/profile/${particularPost?.userId}`)
                                }
                                className={`capitalize relative inline-flex items-center 
                                          justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                              >
                                <span className="font-medium text-[#FFFFFF]">
                                  {localStorage
                                    ?.getItem("name")
                                    ?.substring(0, 1)}
                                </span>
                              </div>
                            )}
                            <Input
                              value={particularPostReply}
                              onChange={(e) =>
                                setParticularPostReply(e.target.value)
                              }
                              placeholder="Type your reply..."
                              onPressEnter={() =>
                                sendReply(
                                  item?.commentId,
                                  particularPostReply,
                                  particularPost?.postId
                                )
                              }
                              maxLength={1000}
                              disabled={isReplySending} // Disable input when sending
                              />
                                  {isReplySending?
                                       <Spin className="msg-send-icon" style={{borderRadius:"50%"}}/>
                                       :
                            <Button
                              onClick={() =>
                                sendReply(
                                  item?.commentId,
                                  particularPostReply,
                                  particularPost?.postId
                                )
                              }
                              className="msg-send-icon"
                              shape="circle"
                              icon={<SendOutlined />}
                              disabled={isReplySending}
                            />
}
                          </div>
                        ) : (
                          <div className="reply-chat-input">
                            {localStorage?.getItem("profileUrl") !== "null" ? (
                              <div
                                className={`capitalize relative inline-flex items-center 
                                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                onClick={() =>
                                  navigate(`/profile/${particularPost?.userId}`)
                                }
                              >
                                <span className="font-medium ">
                                  <img
                                    src={
                                      localStorage?.getItem("profileUrl") || ""
                                    }
                                    alt=""
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </span>
                              </div>
                            ) : (
                              <div
                                style={{
                                  backgroundColor:
                                    localStorage?.getItem("profileColorCode") ||
                                    "",
                                }}
                                onClick={() =>
                                  navigate(`/profile/${particularPost?.userId}`)
                                }
                                className={`capitalize relative inline-flex items-center 
                                          justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                              >
                                <span className="font-medium text-[#FFFFFF]">
                                  {localStorage
                                    ?.getItem("name")
                                    ?.substring(0, 1)}
                                </span>
                              </div>
                            )}
                            <Input
                              placeholder="Replies have been disabled"
                                    disabled={true}
                            />
                            <Button
                                    className="msg-send-icon"
                                    shape="circle"
                                    disabled={true}
                                    icon={<SendOutlined />}
                            />
                          </div>
                        )}
                        {particularPostReply?.length > 0 && (
                          <p className="commentRemainingCharacters">
                            {particularPostReply?.length} / 1000
                          </p>
                        )}
                      </>
                    ) : (
                      <div className="reply-chat-input">
                        {localStorage?.getItem("profileUrl") !== "null" ? (
                          <div
                            className={`capitalize relative inline-flex items-center 
                                          justify-center w-10 h-10 overflow-hidden  rounded-full`}
                            onClick={() =>
                              navigate(`/profile/${particularPost?.userId}`)
                            }
                          >
                            <span className="font-medium ">
                              <img
                                src={localStorage?.getItem("profileUrl") || ""}
                                alt=""
                                style={{
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                              />
                            </span>
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundColor:
                                localStorage?.getItem("profileColorCode") || "",
                            }}
                            onClick={() =>
                              navigate(`/profile/${particularPost?.userId}`)
                            }
                            className={`capitalize relative inline-flex items-center 
                                          justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                          >
                            <span className="font-medium text-[#FFFFFF]">
                              {localStorage?.getItem("name")?.substring(0, 1)}
                            </span>
                          </div>
                        )}
                        <Input
                          placeholder="Join the community to reply"
                          disabled={true}
                        />
                        <Button
                          className="msg-send-icon"
                          shape="circle"
                          icon={<SendOutlined />}
                          disabled={true}
                        />
                      </div>
                    ))}
                  {commentRepliesMap[item?.commentId]?.map((reply, index) => (
                    // Display each reply under the respective comment
                    <div
                      className="flex m-3 items-center replyComment"
                      key={index}
                    >
                      {reply?.userProfileUrl !== null ? (
                        <div
                          className="capitalize relative py-1 mr-4
                            rounded-full flex items-center justify-center"
                        >
                          <img
                            src={reply?.userProfileUrl}
                            style={{
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                            alt="commentProfile"
                          />
                        </div>
                      ) : (
                        <div
                          className="capitalize relative  text-[#FFFFFF] w-10 h-10 p-1 mr-4
                            rounded-full flex items-center justify-center"
                          style={{ backgroundColor: reply?.profileColorCode }}
                        >
                          {reply?.author?.substring(0, 1)}
                        </div>
                      )}
                      {/* <span className="flex flex-col w-full items-end"> */}
                      {/* <div className="flex flex-col w-full"> */}
                      <div className="reply-content-container">
                        <div className="reply-content">
                          {reply?.author?.length > 0 && (
                            <p>
                              <strong>{reply?.author}</strong>
                            </p>
                          )}
                          <h3>{reply?.content}</h3>
                          <div className="flex justify-end">
                            <TIMEAGO timestamp={reply?.createdAt} />
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                      {/* </span> */}
                    </div>
                    // ...
                  ))}
                </>
              )
            )}
          </div>
        )}
        {postComments[particularPost?.postId]?.length > 0 &&
          postComments[particularPost?.postId]?.length !==
            particularPost?.noOfComments && (
            <button onClick={loadMoreComments} className="load-more-button">
              Load More Comments...
            </button>
          )}
      </div>
      <Modal
        open={shareModal}
        footer={false}
        transitionName="ant-modal-slide-up"
        width={"100%"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "86.5vh",
          overflowY: "hidden",
          padding: "0px",
          width: "100%",
        }}
        // centered
        onCancel={() => setShareModal(false)}
        title={
          <div className="flex flex-row items-center justify-center pt-3">
            Share Post
          </div>
        }
      >
        <div className="flex flex-row justify-center py-5 px-10">
          {/* <div className="flex flex-col items-center">
                    <LinkOutlined className="text-3xl" onClick={()=>{setShareModal(false);handleShare();}}/>
                    <span onClick={()=>{setShareModal(false);handleShare();}}>Via Link</span>
                    </div> */}
          <div className="flex flex-col items-center">
            <UsergroupAddOutlined
              className="text-3xl"
              onClick={() => {
                setShareModal(false);
                setCreatePopup(true);
              }}
            />
            <span
              onClick={() => {
                setShareModal(false);
                setCreatePopup(true);
              }}
            >
              Via Communities
            </span>
          </div>
        </div>
      </Modal>
      {createPopup && (
        <FeedsComponentPopup
          onCancelClick={() => {
            setCreatePopup(false);
          }}
          onSaveClick={() => {
            setCreatePopup(false);
            // getPosts(0, "createFeed");
          }}
          communityPostContent={sharePost?.content}
          metaData={sharePost?.metaData}
          from={"CommunityPosts"}
        />
      )}
      {showPoll && (
        <div className="pollPopup">
          <Modal
            centered
            open
            className="pollModal"
            // width={700}
            title={
              <div
                className="modalTitle flex flex-row items-center"
                style={{
                  padding: "10px 0px 10px 10px",
                  backgroundImage: "linear-gradient(270deg, #804C31, #67702E)",
                  color: "white",
                  borderRadius: "8px 8px 0 0",
                  //  backgroundColor:"#804C31", borderRadius:"5px 5px 0 0", color:"white"
                }}
              >
                <i className="bi bi-bar-chart-fill"></i>
                <span className="px-2">Poll</span>
              </div>
            }
            onCancel={() => {
              const resetRemainingPollUsers: any = {};
              particularPostPoll?.pollOutput?.options?.forEach((poll: any) => {
                resetRemainingPollUsers[poll.option] = null;
              });
              setRemainingPollUsers(resetRemainingPollUsers);
              setShowPoll(false);
            }}
            footer={false}
          >
            <div className="pollModalContainer">
              <span className="text-[#06be51] md:text-lg text-sm font-medium mb-5">
                Total votes : {particularPostPoll?.pollOutput?.totalVotes}
              </span>
              {particularPostPoll?.pollOutput?.options?.map(
                (poll: any, index: any) => (
                  <>
                    <div
                      key={index}
                      className="flex flex-row items-center py-2 pl-5 pollModalOptions"
                      style={{
                        justifyContent: "space-between",
                        backgroundImage:
                          "linear-gradient(270deg, #e2e5e6, #e2e5e6)",
                        backgroundSize: `${
                          (poll?.votes /
                            particularPostPoll?.pollOutput?.totalVotes) *
                          100
                        }%`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "#f8f8f8",
                      }}
                    >
                      <div className="poll-radio-option">{poll?.option}</div>
                      <div className="text-[16px] pr-3 text-[#00000] font-normal">
                        <span>
                          {poll?.votes} {poll?.votes === 1 ? "vote" : "votes"}
                        </span>
                      </div>
                    </div>
                    <div className="two-columns-grid">
                      {poll?.users?.length > 0 ? (
                        (poll?.users?.length > 3
                          ? poll?.users?.slice(0, 3)
                          : poll?.users
                        )?.map((user: any, index: any) => (
                          <div
                            key={index}
                            className="flex flex-row items-center mx-5 pollUser"
                          >
                            {user?.profileUrl ? (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember`}
                                //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }

                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <img src={user?.profileUrl} />
                              </div>
                            ) : (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember ${
                                  index === 0 ? "" : "" // Apply green border to the first element
                                }`}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <span
                                  className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                                  style={{
                                    color: "white",
                                    fontSize: "1.15rem",
                                  }}
                                >
                                  <span className="memberSubstring">
                                    {user?.member?.name?.substring(0, 1)}
                                  </span>
                                </span>
                              </div>
                            )}

                            <div
                              className="flex items-center flex-col modalmemberName"
                              style={{ color: "black" }}
                            >
                              <span className="postMembertitle capitalize ml-2">
                                <span className="modalmemberName">
                                  {user?.member?.name}
                                </span>
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      {poll?.users?.length > 3 &&
                        remainingPollUsers[poll?.option] === null && (
                          <div
                            className="flex flex-row items-center mx-5 pollViewAll"
                            style={{ color: "#06be51" }}
                            onClick={() => {
                              handleViewAllUsers(poll?.option);
                            }}
                          >
                            View All
                          </div>
                        )}

                      {remainingPollUsers[poll?.option]?.map(
                        (user: any, index: any) => (
                          <div
                            key={index}
                            className="flex flex-row items-center mx-5 pollUser"
                          >
                            {user?.profileUrl ? (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember`}
                                //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                onClick={() => navigate(`/profile/${user?.id}`)}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <img src={user?.profileUrl} />
                              </div>
                            ) : (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember ${
                                  index === 0 ? "" : "" // Apply green border to the first element
                                }`}
                                onClick={() => navigate(`/profile/${user?.id}`)}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <span
                                  className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                                  style={{
                                    color: "white",
                                    fontSize: "1.15rem",
                                  }}
                                  onClick={() =>
                                    navigate(`/profile/${user?.id}`)
                                  }
                                >
                                  <span className="memberSubstring">
                                    {user?.member?.name?.substring(0, 1)}
                                  </span>
                                </span>
                              </div>
                            )}

                            <div
                              className="flex items-center flex-col modalmemberName"
                              style={{ color: "black" }}
                              onClick={() => navigate(`/profile/${user?.id}`)}
                            >
                              <span className="postMembertitle capitalize ml-2">
                                <span className="modalmemberName">
                                  {user?.member?.name}
                                </span>
                              </span>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
      )
    }
    </>
  );
}

export default FeedsPreview;
