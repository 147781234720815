import React, { useEffect, useState } from "react";
import "./PhoneSignIn.css";
import app from "../../../firebase";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Loader from "../../Loader/Loader";
import { request } from "../../../Utils/request";
import OtpInput from "react-otp-input";
import { Checkbox } from "antd";
import TermsAndPrivacyModal from "../../StaticPageModals/TermsOfUseModal";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Phone = (props) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [expandForm, setExpandForm] = useState();
  const [loader, setLoader] = useState(false);
  const [continueLoader, setContinueLoader] = useState(false);
  const [isUserExist, setUserExist] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [policyValue, setPolicyValue] = useState("");

  const auth = getAuth(app);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  useEffect(() => {}, []);

  const generateRecaptchaVerifier = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        badge: "bottom",
        callback: (response) => {
          if (response) {
            return response;
          }
        },
        "expired-callback": () => {
          console.log("error in recaptcha");
        },
      }
    );
    window.recaptchaVerifier.render();
  };

  useEffect(() => {
    generateRecaptchaVerifier();
  }, []);

  const onSubmitClick = async (e) => {
    setLoader(true);
    if (phoneNumber) {
      if (phoneNumber?.length !== 10) {
        let newPhoneNumber = `+${phoneNumber}`;
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, newPhoneNumber, appVerifier)
          .then(async (confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setExpandForm(true);
            // console.log("newPhoneNumber", newPhoneNumber);
            let phone = newPhoneNumber?.substr(1);

            try {
              const mobileLoginResp = await fetch(`${BASE_URL}/member/current/user/exists`,
                {
                  method: "POST",
                  body:JSON.stringify({
                    phoneNumber:phone
                  }),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              const result = await mobileLoginResp.json();
              if (result?.userExists) {
                setUserExist(true);
              } else {
                setUserExist(false);
              }
            setLoader(false);

            } catch (error) {}
          })
          .catch((error) => {
            setLoader(false);
            if (error?.message?.includes("too-many-requests")) {
              toast.error("Too many attempts!");
            } else if (error?.message?.includes("TOO_SHORT")) {
              toast.error("Please enter a valid 10-digit phone number.");
            } else {
              toast.error(error?.message);
            }
          });
      } else {
        setLoader(false);
        toast.error("Please enter valid mobile number");
      }
    } else {
      setLoader(false);
      toast.error("Please enter mobile number");
    }

  };

  console.log("isUserExist", isUserExist);

  const meApiCall = async (userExists) => {
    if (userExists) {
      const meApiResp = await request("/member/current/me");

      if (meApiResp?.id) {
        localStorage.setItem("id", meApiResp?.id);
        localStorage.setItem("name", meApiResp?.member?.name);
        localStorage.setItem("profileColorCode", meApiResp?.profileColorCode);
        localStorage.setItem("profileUrl", meApiResp?.profileUrl);
        localStorage.setItem("profileScore", meApiResp?.profileScore);
        if (meApiResp?.profileScore === 100) {
          props?.parentCallback();
          navigate("/feeds");
        } else {
          navigate(`/profile`);
          props.parentCallback();
        }
      }
    } else {
      navigate(`/profile`);
      props?.parentCallback();
    }
  };

  const verifyOTP = (e) => {
    setContinueLoader(true);
    if (otp?.length === 6) {
      let confirmationResult = window.confirmationResult;
      try {
        confirmationResult
          .confirm(otp)
          .then(async (result) => {
            // User signed in successfully.
            const user = result.user;
            const idToken = await user.getIdToken(true);
            localStorage.setItem("token", idToken);
            localStorage.setItem("firebaseId", user?.uid);
            localStorage.setItem("phoneNumber", user?.phoneNumber);
            localStorage.setItem("loginInVia", "phone");
            localStorage.setItem("user", JSON.stringify(user));
            let phone = user.phoneNumber.substr(1);
            const mobileLoginRespBody = {
              method: "POST",
              body: JSON.stringify({
                phoneNumber:phone,
              }),
            };
            try {
              const mobileLoginResp = await request(
                `/member/current/user/exists`,
                mobileLoginRespBody
              );
              localStorage.setItem("userExist", mobileLoginResp?.userExists);

              if (
                mobileLoginResp?.signUpVia?.in_APP_PHONE &&
                mobileLoginResp?.userExists
              ) {
                meApiCall(mobileLoginResp?.userExists);
              }

              if (
                mobileLoginResp?.userExists &&
                mobileLoginResp?.signUpVia?.in_APP_PHONE === false
              ) {
                try {
                  const fireBaseRequestBody = {
                    method: "POST",
                    body: JSON.stringify({
                      fireBaseUID: user?.uid,
                      signUpVia: "IN_APP_PHONE",
                      token: `Bearer ${idToken}`,
                      phoneNumber: phone,
                    }),
                  };
                  const firebaseMappingResp = await request(
                    `/member/current/add/firebase/account`,
                    fireBaseRequestBody
                  );

                  if (
                    firebaseMappingResp?.signUpVia?.in_APP_PHONE &&
                    firebaseMappingResp?.userExists
                  ) {
                    meApiCall(firebaseMappingResp?.userExists);
                  } else {
                    props.parentCallback();
                    navigate(`/profile`);
                  }
                  localStorage.setItem(
                    "userExist",
                    mobileLoginResp?.userExists
                  );
                } catch (error) {
                  toast.error(error?.message || "Something went wrong1");
                  setContinueLoader(false);
                  return;
                }
              } else if (!mobileLoginResp?.userExists) {
                const requestBody = {
                  body: JSON.stringify({
                    phoneNumber: phone,
                    firebaseUID: user?.uid,
                    email: `${phoneNumber}@mobile.com`,
                    role: "MEMBER",
                    signUpVia: "IN_APP_PHONE",
                  }),
                  method: "POST",
                };
                const signUpViaPhoneResp = await request(
                  "/member/current/sign-up-via-phone",
                  requestBody
                );
                if (signUpViaPhoneResp?.userId) {
                  localStorage.setItem("userExist", true);
                  meApiCall(mobileLoginResp?.userExists);
                } else {
                  toast.error("Something went wrong");
                  setContinueLoader(false);
                }
              }
            } catch (error) {
              toast.error(error?.message || "Something went wrong");
              return;
            }
          })
          .catch((error) => {
            if (error?.message?.includes("invalid-verification-code"))
              toast.error("Kindly input the accurate OTP for validation");
            setContinueLoader(false);
          });
      } catch (e) {
        console.log("e", e);
      }
    }
  };

  return (
    <div className="relative">
      <div className="">
        <div className="mb-3 text-lg font-semibold ">
          <div className="flex flex-col gap-5 phoneinputwrapper">
            <PhoneInput
              country={"in"}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              inputType="number"
            />
          </div>

          {expandForm ? (
            <div className="expandForm items-center flex  flex-col my-4">
              <div className="my-3 otp">
                <OtpInput
                  className=""
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span className="space"></span>}
                  renderInput={(props) => (
                    <input className="otpInput" {...props} />
                  )}
                  inputType="number"
                />
              </div>
              <div className="flex flex-col justify-center items-center mt-2">
                {isUserExist === false && (
                  <Checkbox
                    onChange={(e) => {
                      setTermsAgreed(!termsAgreed);
                    }}
                    className="checkboxmultiPhone"
                  >
                    I agree to the{" "}
                    <span
                      className="textColor"
                      onClick={() => {
                        setShowTermsModal(true);
                        setPolicyValue("privacyPolicy");
                      }}
                    >
                      Privacy Policy
                    </span>{" "}
                    and{" "}
                    <span
                      className="textColor"
                      onClick={() => {
                        setShowTermsModal(true);
                        setPolicyValue("TermsOfService");
                      }}
                    >
                      {" "}
                      Terms of Service{" "}
                    </span>
                    by registering.
                  </Checkbox>
                )}
                <button
                  style={{
                    opacity:
                      isUserExist === false
                        ? !(termsAgreed && otp?.length === 6)
                          ? 0.5
                          : 1
                        : otp.length === 6
                        ? 1
                        : 0.5,
                  }}
                  disabled={
                    isUserExist === false
                      ? !(termsAgreed && otp?.length === 6)
                        ? true
                        : false
                      : otp.length === 6
                      ? false
                      : true
                  }
                  className="loginComponentButton relative"
                  onClick={() => {
                    verifyOTP();
                  }}
                >
                  Verify OTP
                  {continueLoader ? (
                    <div className="loader w-[50px]">
                      <Loader />
                    </div>
                  ) : (
                    ""
                  )}
                </button>{" "}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center mt-3">
              <button
                onClick={() => {
                  onSubmitClick();
                }}
                id="otpButton"
                className="loginComponentButton relative"
              >
                Get OTP
                {loader ? (
                  <div className="loader w-[50px]">
                    <Loader />
                  </div>
                ) : (
                  ""
                )}
              </button>
            </div>
          )}
          <div className="recaptcha-wrapper">
            <div id="recaptcha-container"></div>
          </div>
        </div>
      </div>
      <TermsAndPrivacyModal
        modalValue={policyValue}
        isModalVisible={showTermsModal}
        handleCancel={() => setShowTermsModal(false)}
        handleOk={() => setShowTermsModal(false)}
      />
    </div>
  );
};

export default Phone;
