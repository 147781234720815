import moment from "moment";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import { clearCache } from "./request";


export const TIMEAGO = ({ timestamp }) => {
  const newDate = moment.utc(timestamp).toDate();
  let res = moment(newDate).fromNow(); 
  if(res === 'a minute ago') {
    res ='Just now'
  } 

  return (
    <div>
      <span className="time-ago-format">{res}</span>
    </div>
  );
};

export  const getExpireDate = (time) => {
  var utcTime = moment.utc(time);
  // const istTime = utcTime.utcOffset("+05:30");
  return moment(time).format('lll');
}


export const getToken = () => {
  try {
    return new Promise(async (resolve, reject) => {
      const user = await new Promise((innerResolve, innerReject) => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          unsubscribe(); // Unsubscribe after the first call
          if (user) {
            const token = await user.getIdToken(true);
            localStorage.setItem("token", token);
            resolve(user);
          } else {
            localStorage.clear()
            clearCache()
            window.location.href='/login'
            // resolve(null);
            reject(null)
          }
        }, innerReject);
      });
  
      if (user) {
        resolve(user.getIdToken());
      } else {
        localStorage.clear()
        clearCache()
        window.location.href='/login'
        reject("User not authenticated.");
      }
    });
  } catch(error) {
    console.log('error',error)
  }
 
};