import { useState, useEffect } from "react";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  API_TIMEOUT_INTERVAL,
  cacheResponse,
  getCachedResponse,
  request,
} from "../../Utils/request";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import connection from "../../assets/images/connection_3.png";
import "./YourCommunity.css";
import "./Discover.css";
import { useNavigate } from "react-router-dom";
import { UserAddOutlined, IssuesCloseOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";
import { DownOutlined } from "@ant-design/icons";
import image from "../../assets/images/imageplaceholder.png";
import PullToRefresh from "react-simple-pull-to-refresh";
import newloader from "../../assets/loader-new.gif";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";
type Props = {
  categoryId: string;
  toggleSelected: boolean;
  selectedCategoryId: string;
  queryText: string;
  triggerSearch?: number;
};

// eslint-disable-next-line no-empty-pattern
function Discover(props: Props) {
  const [discoverData, setDiscoverData] = useState<any[]>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const navigate = useNavigate();
  const [communityType, setCommunityType] = useState("");
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  useEffect(() => {
    setCurrentPage(0);
  }, [props?.categoryId, props?.toggleSelected]);

  useEffect(() => {
    setHasMore(true);
    getCommunityNames(currentPage);
  }, [props?.toggleSelected, currentPage, toggleRefresh, communityType]);

  const getCommunityNames = async (page?: number) => {
    let requestPromise;
    if (
      props?.queryText.trim()?.length > 0 &&
      props?.selectedCategoryId.trim()?.length > 0
    ) {
      const url = `/member/current/community/search?communityName=${
        props?.queryText
      }&communityCategoryId=${
        props?.selectedCategoryId !== "" ? props?.selectedCategoryId : null
      }&isMyCommunity=false`;
      requestPromise = request(url);
    } else if (props.categoryId && communityType) {
      requestPromise = request(
        `/member/current/communities?categoryId=${props.categoryId}&&type=${communityType}&page=${page}&size=10`
      );
    } else if (props.categoryId) {
      requestPromise = request(
        `/member/current/communities?categoryId=${props.categoryId}&page=${page}&size=10`
      );
    } else if (communityType?.length > 0) {
      requestPromise = request(
        `/member/current/communities?type=${communityType}&page=${page}&size=10`
      );
    } else {
      requestPromise = request(
        `/member/current/communities?page=${page}&size=10`
      );
    }

    let timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, API_TIMEOUT_INTERVAL)
    );

    try {
      const resp = await Promise.race([requestPromise, timeoutPromise]);

      if (!resp) {
        const cachedResponse = await getCachedResponse(
          `/member/current/communities?page=${page}&size=10`
        );
        if (cachedResponse) {
          setDiscoverData(cachedResponse);
          setLoader(false);
        } else {
          console.error("Failed to get cached data");
        }
        return;
      }

      if (resp?.content?.length === 0) {
        setHasMore(false);
      }
      if (
        props?.queryText.trim()?.length > 0 &&
        props?.selectedCategoryId.trim()?.length > 0
      ) {
        setDiscoverData(resp);
        return;
      }

      if (page === 0) {
        setDiscoverData(resp?.content);
        cacheResponse(
          `/member/current/communities?page=${page}&size=10`,
          resp?.content
        );
      } else {
        let oldDiscoverData = [...discoverData];
        oldDiscoverData = [...oldDiscoverData, ...resp?.content];
        setDiscoverData(oldDiscoverData);
        cacheResponse(
          `/member/current/communities?page=${page}&size=10`,
          oldDiscoverData
        );
      }
      setLoader(false);
    } catch (error) {
      // Handle timeout or other errors
      console.error("Error in getCommunityNames:", error);

      // Use cached data if available
      const cachedResponse = await getCachedResponse(
        `/member/current/communities?page=${page}&size=10`
      );

      if (cachedResponse) {
        setDiscoverData(cachedResponse);
        setLoader(false);
        getCommunityNames(page);
      } else {
        console.error("Failed to get cached data");
      }
    }
  };

  useEffect(() => {
    if (props?.queryText.trim()?.length === 0) {
      getCommunityNames(0);
      setCurrentPage(0);
    }
  }, [props?.queryText]);

  useEffect(() => {
    getCommunityNames(0);
  }, [props?.triggerSearch]);

  const handleJoinCommunity = async (communityId: any) => {
    try {
      const body = {
        method: "POST",
      };
      const response = await request(
        `/member/current/community/join/${communityId}`,
        body
      );
      if (response.communityId) {
        let newDiscoverData: any[] = [...discoverData];
        let index = discoverData.findIndex(
          (item: any) => item?.id === response.communityId
        );

        newDiscoverData[index] = {
          ...newDiscoverData[index],
          requested: true,
        };
        setDiscoverData(newDiscoverData);
        toast.success("Your request has been sent for approval!");
      }else{
      toast.error("Membership request already sent, please wait for the admin to respond.");
      }
    } catch (error) {
      toast.error("Error sendig request. Please try again later.");
    }
  };

  const onChange = (value: string) => {
    setCommunityType(value);
    setCurrentPage(0);
  };

  const handleRefresh = async (): Promise<void> => {
    setToggleRefresh(!toggleRefresh);
  };

  return (
    <PullToRefreshComponent onRefresh={() => handleRefresh()}>
      <div style={{ minHeight: "250px" }}>
        {loader ? (
          <div className="loader mt-22">
            <Loader />{" "}
          </div>
        ) : (
          <div>
            <div className="infoBox">
              {" "}
              <p className="text-base">
                <p className="text-base">
                  "Discover" is a feature designed to enhance a user's online
                  experience by recommending new online communities or forums
                  based on their existing interests and affiliations.
                </p>

                <p className="text-base">
                  These recommendations often rely on algorithms that take into
                  account factors such as the user's previous interactions,
                  engagement levels, and shared interests with others.
                  Ultimately, "Suggested Communities" aims to connect
                  individuals with like-minded peers and expand their online
                  network, fostering a more enriching and diverse online
                  experience.
                </p>
              </p>
            </div>
            <div className="topPanelHead">
              <h2 className="topPanelHeadColL ">Suggested for you</h2>
              <div className="topPanelHeadColR ">
                <Select
                  style={{ width: "100px" }}
                  defaultValue="ALL"
                  className="typeDropdown ml-5 searchbardiscover"
                  bordered={false}
                  suffixIcon={
                    <DownOutlined className="text-base text-[#686f2e]" />
                  }
                  placeholder="Select community type"
                  optionFilterProp="children"
                  onChange={onChange}
                  options={[
                    {
                      value: "PUBLIC",
                      label: "PUBLIC",
                    },
                    {
                      value: "PRIVATE",
                      label: "PRIVATE",
                    },
                    {
                      value: "",
                      label: "ALL",
                    },
                  ]}
                />
              </div>
            </div>

            <InfiniteScroll
              dataLength={discoverData?.length}
              next={() => {
                setCurrentPage(currentPage + 1);
              }}
              hasMore={hasMore}
              loader={<></>}
            >
              <div className="thumbnailBox">
                {discoverData?.length > 0 ? (
                  <div className="discoverthumbnailRowForThree">
                    {discoverData?.map((data: any, index) => (
                      <div
                        className="discoverthumbnailRowForThreeColumn"
                        key={data.id}
                        onClick={() =>
                          navigate(`/communityPreview/${data?.id}`, {
                            state: {
                              communityMemberStatus: data?.admin
                                ? "admincommunity"
                                : "othercommunity",
                                from : 0,
                            },
                          })
                        }
                      >
                        <div className="py-10" style={{}}>
                          <div
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            className="text-[#804C31] disoverListouter"
                          >
                            <img
                              src={
                                data?.communityProfileUrl
                                  ? data?.communityProfileUrl
                                  : connection
                              }
                              style={{
                                borderRadius: "150px",
                                width: "100px",
                                height: "100px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                objectFit: "cover",
                              }}
                              alt=""
                            />
                            <div className="discoverUIwrap">
                              <div className="discoverthumbnailTitle">
                                {data.name}
                              </div>
                              <div className="discoverthumbnailDescription">
                                <p className="text-slate-600">
                                  {data?.noOfParticipants}{" "}
                                  {data?.noOfParticipants > 1
                                    ? "members"
                                    : "member"}
                                </p>
                                {data?.requested === true &&
                                data?.member === false ? (
                                  <div className="joingroup">
                                    <Button
                                      style={{
                                        width: "fit-content",
                                        color: data?.requested
                                          ? "#a3c844"
                                          : "#804C31",
                                        borderWidth: "2px",
                                        borderColor: data?.requested
                                          ? "#a3c844"
                                          : "#804C31",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleJoinCommunity(data.id);
                                      }}
                                    >
                                      <IssuesCloseOutlined />
                                      Requested
                                    </Button>
                                  </div>
                                ) : (
                                  <div className="joingroup">
                                    <Button
                                      style={{
                                        width: "fit-content",
                                        color: data?.requested
                                          ? "#a3c844"
                                          : "#804C31",
                                        borderWidth: "2px",
                                        borderColor: data?.requested
                                          ? "#a3c844"
                                          : "#804C31",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleJoinCommunity(data.id);
                                      }}
                                      className="showdesktop"
                                    >
                                      <UserAddOutlined />
                                      {/* {joinStatus} */}
                                      {data?.requested
                                        ? "Requested"
                                        : "Join Group"}
                                      {/* Join Group */}
                                    </Button>
                                    <Button
                                      className="showmobile"
                                      onClick={(event) =>{
                                        event.stopPropagation();
                                        handleJoinCommunity(data.id)}
                                      }
                                    >
                                      <UserAddOutlined />
                                      Join
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-center pt-10 pb-10"
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      justifyContent: "center",
                      marginTop: "75px",
                      marginBottom: "75px",
                    }}
                  >
                    <img
                      src={image}
                      style={{ width: "100px", height: "100px" }}
                      alt=""
                    />
                    <div className="text-gray-500 text-2xl pt-5">
                      {" "}
                      No new communities{" "}
                    </div>
                  </div>
                )}
              </div>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </PullToRefreshComponent>
  );
}

export default Discover;
