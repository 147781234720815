import React, { useEffect, useState } from "react";
import { Modal, Form, Select, Button, Input } from "antd";
import { request } from "../../Utils/request";
import { toast } from "react-toastify";
import "./AddnewMemberPopup.css";
import { useNavigate } from "react-router";
import { DownOutlined } from "@ant-design/icons";
import { multiFormRequest } from "../../Utils/request";
import {
  SendOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  LikeOutlined,
  LikeTwoTone,
  DislikeTwoTone,
  DislikeOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined,
  MoreOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

interface AddMemberProps {
  onCancelClick: Function;
  commuinityId?: string;
  membersList?: any;
  onSaveClick: Function;
  getmembersData: Function;
}

const { Option } = Select;

const AddnewMemberPopup = (props: AddMemberProps) => {
  const [membersList, setMemberList] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  //   const [selectedMembers, setSelectedMembers] = useState<any[]>([{email:'Chaith@',name:'c'},{email:'Chaith@',name:'c'}]);
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [searchResultsWithOldData, setSearchResultWithOldData] = useState<
    any[]
  >([]);
  const [postType, setPostType] = useState("addMembers");
  const [memberStatus, setMemberStatus] = useState<any>([]);
  const [finalMemberStatus, setFinalMemberStatus] = useState<any>([]);
  const [members, setMembers] = useState<any>([]);
  const [blockReason, setBlockReason] = useState<any>({});
  const navigate = useNavigate();

  const items = [
    {
      key: "addMembers",
      label: `Add Members`,
    },
    {
      key: "addPermission",
      label: `Block Members`,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await request(`/member/get-users-list`);
        setSearchResults(response);

        let searchResultWithOldDataCopy = [...searchResultsWithOldData];
        searchResultWithOldDataCopy = [
          ...searchResultWithOldDataCopy,
          ...response,
        ];
        setSearchResultWithOldData(response);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // Make the API call only if the search term has 3 or more characters
    // if (searchTerm.length >= 3) {
    //   fetchData();
    // } else {
    //   setSearchResults([]); // Clear results if the search term is less than 3 characters
    // }
  }, []);
  useEffect(() => {
    const getMembersData = async () => {
      try {
        const resp = await request(
          `/member/current/community/${props.commuinityId}/members`
        );
        if (resp) {
          const datafetch = resp;
          setMembers(datafetch);
        } else {
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getMembersData();
  }, []);
  useEffect(() => {
    if (members) {
      const updatedMemberStatus = [{}];

      members.forEach((member: any) => {
        const newStatus = {
          block: member?.block,
          userId: member?.userId,
          communityId: props?.commuinityId,
        };

        updatedMemberStatus[member?.userId] = newStatus;
      });
      setMemberStatus((prevMemberStatus: any) => ({
        ...prevMemberStatus,
        ...updatedMemberStatus,
      }));
    }
  }, [members, props?.commuinityId]);

  const onAddButtonClick = async () => {
    const addButtonPayload = {
      method: "POST",
      body: JSON.stringify({
        userId: selectedMembers?.map((item) => item?.userId),
        communityId: props.commuinityId || "",
      }),
    };
    const data = Object.values(finalMemberStatus);
    console.log("Data",JSON.stringify(data))
    const saveButtonPayload = {
      method: "PATCH",
      body: JSON.stringify({"data" : data}),
      type: "application/json",
    };
    if (postType === "addMembers") {
      const resp = await request(
        `/member/add-member-by-admin`,
        addButtonPayload
      );
      if (resp?.length) {
        props?.getmembersData();
        toast.success("Every member is now actively engaged in the community");
        props?.onSaveClick();
        if (resp[0]?.message) {
          setSelectedMembers([]);
          setSelectedValues([]);
        }
      } else {
        let selectedMembersCopy = [...selectedMembers];
        selectedMembersCopy = selectedMembersCopy?.map((item: any, index) => {
          if (resp?.usersFound?.includes(item?.userId)) {
            return {
              ...item,
              userExist: true,
            };
          } else {
            return {
              ...item,
            };
          }
        });
        setSelectedMembers(selectedMembersCopy);
        toast.error(
          "Kindly remove existing members to make way for new additions to the community"
        );
      }
    } else {
      const response = await request(
        `/member/current/community/admin/block`,
        saveButtonPayload
      );
      if (response?.length) {
        toast.success("Members Permissions Updated");
        props?.onSaveClick();
      }
    }
  };

  const handleSearch = (value: any) => {
    setSearchTerm(value);
  };

  const handleSelectChange = (selected: any) => {
    let selectedItemCopy: any[] = [...searchResultsWithOldData];
    let resultArray: any[] = selectedItemCopy.filter((item: any) =>
      selected.includes(item?.email)
    );
    resultArray = resultArray?.map((item: any) => ({
      ...item,
      userExist: false,
    }));
    setSelectedMembers(resultArray);
  };

  const handleDeselect = (deselectedValue: any) => {
    let selectedItemCopy: any[] = [...selectedMembers];

    const updatedValues = selectedValues.filter(
      (value: any) => value !== deselectedValue
    );
    const resultArray = selectedItemCopy.filter(
      (item: any) => item?.email !== deselectedValue
    );

    setSelectedValues(updatedValues);
    setSelectedMembers(resultArray);
  };

  const onDeleteClick = (member: any) => {
    let selectedMembersCopy = [...selectedMembers];
    let selectedSearchResults = [...selectedValues];
    selectedMembersCopy = selectedMembersCopy?.filter(
      (item: any) => item.email != member?.email
    );
    selectedSearchResults = selectedSearchResults.filter(
      (item: any) => item != member.email
    );
    setSelectedMembers(selectedMembersCopy);
    setSelectedValues(selectedSearchResults);
  };

  const onChange = (value: any, index: any, id: any) => {
    // const findIndex = memberStatus.findIndex((item:any) => item.userId === id);
    if (memberStatus[id]?.length > 0) {
      const updatedStatuses = [...memberStatus];
      updatedStatuses[id] = {
        ...updatedStatuses[id],
        block: value.toUpperCase() === "ACTIVE" ? false : true,
        userId: id,
        communityId: props?.commuinityId,
      };
      setMemberStatus(updatedStatuses);
      setFinalMemberStatus(updatedStatuses);
    } else {
      const newStatus = {
        block: value.toUpperCase() === "ACTIVE" ? false : true,
        userId: id,
        communityId: props?.commuinityId,
      };
      setMemberStatus({ ...memberStatus, [id]: newStatus });
      setFinalMemberStatus({ ...finalMemberStatus, [id]: newStatus });
    }
  };
  const onReasonChange = (e:any, userId:any) => {
    const updatedBlockReason:any = { ...blockReason };
    updatedBlockReason[userId] = e.target.value;
    setBlockReason(updatedBlockReason);
  
    const updatedStatuses = { ...memberStatus };
    const finalUpdatedStatuses = { ...finalMemberStatus };
    updatedStatuses[userId] = {
      ...updatedStatuses[userId],
      reason: e.target.value,
    };
    finalUpdatedStatuses[userId] = {
      ...finalUpdatedStatuses[userId],
      reason: e.target.value,
    };
    setMemberStatus(updatedStatuses);
    setFinalMemberStatus(finalUpdatedStatuses);
  };

  // const memberPermissions = ["Active", "Blocked"];
  const memberPermissions = ["Active", "Block"];
  const { Option } = Select;
  return (
    <div>
      <div id="postPopup" className="addMemberWrapper">
        <Modal
          className="addMembersPopup"
          title="Community Settings"
          open
          onCancel={() => {
            props.onCancelClick();
          }}
          style={{ width: "850px" }}
          footer
        >
          <div className="select-Dropdown pb-2">
            <div className="tabsWrapper">
              {items?.map((tabOptions: any) => (
                <div
                  className={`${
                    tabOptions?.key === postType ? "activeTab" : ""
                  } optionsTab`}
                  onClick={() => {
                    setPostType(tabOptions?.key);
                  }}
                >
                  <p>{tabOptions?.label}</p>
                </div>
              ))}
            </div>
          </div>
          {postType === "addMembers" ? (
            <>
              <div className="SelectContainer">
                <Select
                  className="select-dropdown"
                  showSearch
                  bordered
                  mode="multiple" // Enable multi-select mode
                  value={selectedValues}
                  placeholder="Search and select options"
                  style={{ width: "100%" }}
                  loading={loading}
                  onSearch={handleSearch}
                  maxTagCount={5}
                  onChange={(selectedEmail) => {
                    setSelectedValues(selectedEmail);
                    handleSelectChange(selectedEmail);
                  }}
                  onDeselect={(e) => {
                    handleDeselect(e);
                  }}
                >
                  {searchTerm?.length >= 3 &&
                    searchResults?.map((result: any, resultIndex: any) => {
                      return (
                        <Select.Option
                          key={result?.email}
                          value={result?.email}
                          label={result?.email}
                        >
                          <div className="flex flex-row items-center familymemberOption ">
                            <div>
                              {result?.profileUrl ? (
                                <img
                                  className="text-9xl text-gray-400  dropdownProfileImage mr-2"
                                  alt="preview"
                                  src={result?.profileUrl}
                                  style={{
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div
                                  className={`capitalize relative inline-flex  items-center justify-center overflow-hidden mr-2`}
                                  style={{
                                    backgroundColor: result?.profileColorCode,
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <span className="text-lg text-white dark:text-gray-300">
                                    {result?.name?.substring(0, 1) ||
                                      result?.email?.substring(0, 1)}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="flex flex-col">
                              <span className="dropdownName">
                                {result?.name}
                              </span>
                              <span className="dropdownEmail">
                                {result?.email}
                              </span>
                            </div>
                          </div>
                        </Select.Option>
                      );
                    })}
                </Select>
                <div className="memberListContainer">
                  {selectedMembers?.map((result: any, resultIndex: any) => {
                    return (
                      <div className="flex items-center justify-between mt-2">
                        <div className="flex flex-row items-center familymemberOption">
                          <div>
                            {result?.profileUrl ? (
                              <img
                                className="text-gray-400  dropdownProfileImage mr-2"
                                alt="preview"
                                src={result?.profileUrl}
                                style={{
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div
                                className={`capitalize relative inline-flex items-center items-center justify-center overflow-hidden mr-2 custom-profile`}
                                style={{
                                  backgroundColor: result?.profileColorCode,
                                }}
                              >
                                <span className=" text-white dark:text-gray-300">
                                  {result?.name?.substring(0, 1) ||
                                    result?.email?.substring(0, 1)}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col">
                            <span className="dropdownName">{result?.name}</span>
                            <span
                              title={result?.email}
                              className="dropdownEmail"
                            >
                              {result?.email}
                            </span>
                          </div>
                        </div>

                        <div className="flex gap-2">
                          {result?.userExist && (
                            <div className="custom-check">
                              <i className="bi bi-check text-lg"></i>
                            </div>
                          )}
                          <i
                            onClick={(event) => {
                              onDeleteClick(result);
                              event.stopPropagation();
                            }}
                            className="bi bi-trash  text-red-700 cursor-pointer deleteIcon"
                          ></i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-center adminMembersList">
              <div className="flex flex-col adminMembersListContainer">
                {members?.slice(1)?.map((member: any, index: any) => (
                  <div>
                  <div className="flex flex-row items-center justify-between">
                    <div
                      key={member?.userId}
                      className="flex flex-row items-center py-2 pl-5 justify-start"
                    >
                      {!member?.profileIncomplete ? (
                      member?.profileUrl ? (
                        <div
                          className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                          //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                          onClick={() => navigate(`/profile/${member?.userId}`)}
                          style={{
                            backgroundColor: member.profileColorCode,
                          }}
                        >
                          <img src={member?.profileUrl} />
                        </div>
                      ) : (
                        <div
                          className={`capitalize relative inline-flex items-center cursor-pointer justify-center overflow-hidden rounded-full modalmember ${
                            index === 0 ? "" : "" // Apply green border to the first element
                          }`}
                          onClick={() => navigate(`/profile/${member?.userId}`)}
                          style={{
                            backgroundColor: member.profileColorCode,
                          }}
                        >
                          <span
                            className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                            style={{ color: "white", fontSize: "1.15rem" }}
                            onClick={() =>
                              navigate(`/profile/${member?.userId}`)
                            }
                          >
                            <span className="memberSubstring">
                              {member?.author?.substring(0, 1)}
                            </span>
                          </span>
                        </div>
                      )
                    ) : (
                      <div
                        className={`capitalize relative inline-flex items-center cursor-pointer justify-center overflow-hidden rounded-full modalmember ${
                          index === 0 ? "" : "" // Apply green border to the first element
                        }`}
                        onClick={() =>
                          navigate(
                            `/profile/${member?.userId}`
                          )
                        }
                        style={{
                          backgroundColor: "#e0e0e0",
                        }}
                      >
                        <i
                          className="bi bi-person-fill"
                          style={{
                            fontSize: "30px",
                            color: "gray",
                          }}
                        />
                      </div>
                    )
                    }

                      <div
                        className="flex items-center flex-col modalmemberName cursor-pointer"
                        style={{ color: "black" }}
                        onClick={() =>{!member?.profileIncomplete ? navigate(`/profile/${member?.userId}`) : toast.warn(
                          "User has an incomplete profile"
                        )}}
                      >
                        <span className="postMembertitle capitalize ml-2">
                        <div className="flex flex-col items-start">
                                                    <span className="modalmemberName mobileOverflow">
                                                      {!member?.profileIncomplete ? (
                                                        member?.author
                                                      ) : member?.mobile ? (
                                                        `+91 ${member?.mobile
                                                          ?.toString()
                                                          ?.slice(2)}`
                                                      ) : (
                                                        <span
                                                          style={{
                                                            textTransform:
                                                              "lowercase",
                                                          }}
                                                        >
                                                          {member?.email}
                                                        </span>
                                                      )}
                                                    </span>
                                                    {member?.profileIncomplete && (
                                                      <span className="text-xs text-slate-500">
                                                        Incomplete profile
                                                      </span>
                                                    )}
                                                  </div>
                        </span>
                      </div>
                    </div>
                    <div>
                      <Select
                        defaultValue={member?.block ? "Blocked" : "Active"}
                        className={
                          member?.block && memberStatus?.length === 0
                            ? "blockedDropdown statusDropdown searchbardiscover"
                            : memberStatus[member?.userId]?.block
                            ? "blockedDropdown statusDropdown searchbardiscover"
                            : " activeDropdown statusDropdown searchbardiscover"
                        }
                        bordered={false}
                        optionLabelProp="label"
                        style={{ width: "100%" }}
                        onChange={(value) =>
                          onChange(value, index, member?.userId)
                        }
                      >
                        {memberPermissions?.map((item: any) => (
                          <Option key={item} value={item} label={item}>
                            <div>
                              {item === "Blocked" ? "Block" : `${item}`}
                            </div>
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                   {finalMemberStatus[member?.userId]?.block && 
                   <div className="msg-blocked-input">
                                            <Input
                                              value={blockReason[member?.userId] || ""}
      onChange={(e) => onReasonChange(e, member?.userId)}
                                              placeholder="Reason for blocking (optional)"
                                              maxLength={50}
                                            />
                                          </div>}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="flex justify-center">
          <div className="flex flex-col saveButtonContainer">
                                          <div className="flex justify-end">
            <Button
              disabled={
                postType === "addMembers"
                  ? selectedMembers?.length !== 0
                    ? false
                    : true
                  : finalMemberStatus?.length !== 0
                  ? false
                  : true
              }
              className="addButton"
              onClick={(event) => {
                onAddButtonClick();
                event.stopPropagation();
              }}
            >
              {postType === "addMembers" ? "Add" : "Save"}
            </Button>
            </div>
          </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default AddnewMemberPopup;
