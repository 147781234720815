import React, { useState, useEffect } from "react";
import { request } from "../Utils/request";
import Loader from "../Components/Loader/Loader";
import "./Matrimony.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import MatchingDoughnutChart from "../Components/MatrimonyProfile/MatchingDoughnutChart";
import {
  Image,
  Button,
  Tooltip,
  Modal,
  Select,
  ConfigProvider,
  Space,
  Carousel,
} from "antd";
import image from "../assets/images/imageplaceholder.png";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import MatrimonyInfoCard from "../Components/MatrimonyProfile/MatrimonyInfoCard";
import backIcon from "../assets/matrimonyIcons/back.svg";
import profilePlaceholder from "../assets/images/MatrimonyProfilePlaceholder.png";
import chat from "../assets/newUiImages/chat green.svg";
import { isMobile } from "../Utils/mobileSettings";
import { toast } from "react-toastify";
import { useChatContext } from "../Context/ChatContext";
import KYCActive from "../assets/newUiImages/kycActive.svg";
import KYC from "../assets/newUiImages/kyc.svg";
import SelectMatrimonyModal from "../Components/MatrimonyProfile/SelectMatrimonyModal";

const { Option } = Select;

function convertHeightToFeetAndInches(heightInCms: number) {
  const inches = heightInCms / 2.54;
  const feet = Math.floor(inches / 12);
  const remainingInches = Math.round(inches % 12);

  // Adjust for the case where remainingInches is 12
  if (remainingInches === 12) {
    return `${feet + 1}' 0''`;
  }

  return `${feet}' ${remainingInches}''`;
}

interface UserProfile {
  request: string;
  aboutMe: string;
  id: string;
  userId: string;
  profileFor: string;
  member: {
    name: string;
    currentState: string;
    currentCity: string;
    gender: string;
    dob: string;
    height: number | null;
    profileUrl: string | null;
  };
  age: number | null;
  foodHabit: string | null;
  educationDetails: any;
  maritalStatus: {
    status: string;
    noOfChildren: number;
  };
  profileUrl: string[] | null;
  familyDetails: {
    religion: string;
    caste: string;
    subCaste: string;
    rashi: string;
    gothra: string;
    nakshathra: string;
    foodHabits: string;
    division: string;
    familyName: string;
  };
  lookingFor: {
    castePreference: string[];
    educationPreference: string[];
    location: string[];
    minHeight: number;
    maxHeight: number;
    food: string;
    religion: string;
  };
  contactDetails: {
    emailId: string;
    phoneNumber: string;
    contactSharingAllowed: boolean;
  };
  isActive: boolean;
  hobbies: string[];
  bioDataDocument: string | null;
  createdAt: string;
  show: string;
  formFields: string[];
  kycVerified: boolean;
  kycStatusEnum: string | null;
  thumbnailImage: boolean;
  user: boolean;
  communityOutputs: any;
  yourProfileMatchingScores: any;
}

function MatrimonyProfile() {
  const params = useParams();
  const location = useLocation();
  const [profileData, setProfileData] = useState<UserProfile>();
  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [showCommunityModal, setShowCommunityModal] = useState<boolean>(false);
  const [showYourMatrimonyModal, setShowYourMatrimonyModal] =
    useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<any>([]);
  const [yourMatrimonyList, setYourMatrimonyList] = useState<any>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const id = params.id;
  const [recommended, setRecommended] = useState<any[]>([]);
  const [similarData, setSimilarData] = useState<any[]>([]);
  const { setChatDataFunction } = useChatContext();
  const navigate = useNavigate();
  const handleMatrimonyProfile = (id: any) => {
    navigate(`/matrimony/matrimony-profile/${id}`, {
      state: { user: "explore" },
    });
  };

  let profileImages: string[] = [];
  if (profileData?.profileUrl && Array.isArray(profileData.profileUrl)) {
    profileImages = profileData.profileUrl;
  }

  const handleRequestModalOpen = () => {
    if (yourMatrimonyList?.length > 0) {
      setShowRequestModal(true);
    } else {
      toast?.error("Please create a matrimony profile to request.");
    }
  };

  const handleRequestModalClose = () => {
    setShowRequestModal(false);
  };

  const handleCommunityModalOpen = () => {
    setShowCommunityModal(true);
  };

  const getYourMatrimonyData = async () => {
    // Assuming request is a function that fetches matrimony data
    const resp = await request(
      `/member/current/matrimony-profile/active/profiles`
    );
    if (resp.length) {
      setYourMatrimonyList(resp);
    } else {
      console.error("error");
    }
  };

  const handleCommunityModalClose = () => {
    setShowCommunityModal(false);
  };

  const handleRequestSubmit = async () => {
    const profile = yourMatrimonyList?.find(
      (item: any) => item?.matrimonyId === selectedProfile
    );

    const requestBody = {
      method: "POST",
      body: JSON.stringify({
        matrimonyProfileUserId: profileData?.userId.toString(),
        request: "REQUEST",
        requestedByMatrimonyId: selectedProfile,
        requestedByMatrimonyUserName: profile?.member?.name,
      }),
    };

    try {
      // const resp = await axios.post(
      //   `${BASE_URL}/member/current/matrimony-profile/${id}/request`,
      //   requestBody,
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      const resp = await request(
        `/member/current/matrimony-profile/${id}/request`,
        requestBody
      );
      if (resp) {
        toast.success("Your request has been sent for approval!");
        setShowRequestModal(false);
        getProfileData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getProfileData = async () => {
    try {
      const resp = await request(`/member/current/matrimony-profile/${id}`);

      if (resp !== "loader") {
        const newprofileData: UserProfile = resp;

        setProfileData(newprofileData);
        if (newprofileData?.user) {
          getRecommendedData();
        } else {
          getSimilarProfilesData();
          getYourMatrimonyData();
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    }
  };

  const getRecommendedData = async () => {
    const resp = await request(
      `/member/current/matrimony-profile/${id}/preferences`
    );
    setRecommended(resp);
  };

  const getSimilarProfilesData = async () => {
    const resp = await request(
      `/member/current/matrimony-profile/similar/profiles/${id}`
    );
    setSimilarData(resp);
  };

  const handleChatClick = () => {
    const chatuserdata = {
      matrimonyId: id,
      member: { name: profileData?.member?.name },
      emailId: profileData?.contactDetails?.emailId,
      profileUrl: profileData?.profileUrl,
    };

    setChatDataFunction(chatuserdata);
    setShowYourMatrimonyModal(true);
    // navigate("/chat");
  };

  useEffect(() => {
    if (id) {
      getProfileData();
    }
  }, [id]);

  return (
    <div className="">
      <div className="mt-4 ml-4 mb-0 pb-0 pt-2"></div>

      <div className="containerWithOutSideBar grid grid-cols-12 ml-9 pl-9 matrimonyProfileView">
        {loader ? (
          <div className="fixed inset-0 flex items-center justify-center bg-white opacity-90">
            <Loader />
          </div>
        ) : (
          <>
            <div className="p-2 m-2 mt-0 pb-2 pt-10 bg-[#fff] rounded-l-3xl col-span-9 relative mr-0 profileData  mb-0">
              <img
                src={backIcon}
                className="backiconmatrimony"
                onClick={() => navigate(-1)}
                alt=""
              />
              {!profileData?.user && (
                <>
                  {profileData?.show !== "ONLY_TO_REQUESTED_PEOPLE" ? (
                    <>
                      <button
                        className="absolute -top-12 right-0 md:top-10 md:right-10 hover:text-yellow-500"
                        onClick={handleChatClick}
                      >
                        <img src={chat} alt="" />
                      </button>
                    </>
                  ) : (
                    <>
                      {!profileData?.request ? (
                        <Button
                          className="absolute -top-12 right-0 md:top-10 md:right-10 bg-[#8A934E] hover:text-yellow-500"
                          onClick={handleRequestModalOpen}
                        >
                          <div className="text-white">Request more details</div>
                        </Button>
                      ) : (
                        <>
                          {profileData?.request === "REQUEST" ? (
                            <Button
                              className="absolute -top-12 right-0 md:top-10 md:right-10 bg-[#8A934E] hover:text-yellow-500"
                              disabled
                            >
                              <div className="text-gray-500">Request Sent</div>
                            </Button>
                          ) : profileData?.request === "APPROVE" ? (
                            <button
                              className="absolute -top-12 right-0 md:top-10 md:right-10 hover:text-yellow-500"
                              onClick={handleChatClick}
                            >
                              <img src={chat} alt="" />
                            </button>
                          ) : (
                            <Button
                              className="absolute -top-12 right-0 md:top-10 md:right-10 bg-[#8A934E] hover:text-yellow-500"
                              disabled
                            >
                              <div className="text-red-300 font-semibold">
                                Request Rejected
                              </div>
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <div className="flex flex-wrap profileimgWrap">
                <div className="col-span-9 p-2 m-2 md:flex md:flex-0 md:max-w-3/4">
                  <div className="col-span-12 pr-4">
                    <div className="grid grid-cols-12">
                      <div className="col-span-11 font-black text-3xl">
                        {profileImages?.length < 2 && (
                          <div className="mt-2">
                            {profileImages?.[0] ? (
                              profileData?.show !==
                                "ONLY_TO_REQUESTED_PEOPLE" ||
                              profileData?.user ||
                              profileData?.request === "APPROVE" ||
                              profileData?.thumbnailImage ? (
                                <Image
                                  src={profileImages[0]}
                                  alt=""
                                  className="w-full h-full rounded-2xl"
                                  style={{
                                    height: "350px",
                                    width: "290px",
                                    aspectRatio: 3 / 2,
                                  }}
                                  preview={{
                                    toolbarRender: (
                                      _,
                                      {
                                        transform: { scale },
                                        actions: { onZoomOut, onZoomIn },
                                      }
                                    ) => (
                                      <Space
                                        size={12}
                                        className="toolbar-wrapper"
                                      >
                                        <ZoomOutOutlined
                                          disabled={scale === 1}
                                          onClick={onZoomOut}
                                        />
                                        <ZoomInOutlined
                                          disabled={scale === 50}
                                          onClick={onZoomIn}
                                        />
                                      </Space>
                                    ),
                                  }}
                                />
                              ) : (
                                <Image
                                  preview={false}
                                  src={profileImages[0]}
                                  alt=""
                                  className="w-full h-full rounded-2xl blur-sm"
                                  style={{
                                    height: "350px",
                                    width: "290px",
                                    aspectRatio: 3 / 2,
                                  }}
                                />
                              )
                            ) : (
                              <Image
                                src={profilePlaceholder}
                                preview={false}
                                alt=""
                                className="w-full h-full rounded-2xl"
                                style={{
                                  height: "350px",
                                  width: "290px",
                                  aspectRatio: 3 / 2,
                                }}
                              />
                            )}
                          </div>
                        )}

                        {profileImages?.length > 1 && (
                          <ConfigProvider
                            theme={{
                              components: {
                                Carousel: {
                                  dotWidth: 10,
                                  dotActiveWidth: 12,
                                  dotHeight: 8,
                                },
                              },
                              token: {
                                colorPrimary: "#7d8742",
                                borderRadius: 10,
                                colorPrimaryBorder: "#7d8742",
                              },
                            }}
                          >
                            <Carousel
                              className="mb-4"
                              style={{
                                marginTop: "10px",
                                width: "290px", // You can adjust the width
                              }}
                            >
                              {profileImages?.map((img: any) => (
                                <div
                                  key={img}
                                  style={{
                                    height: "390px",
                                    aspectRatio: "3/2",
                                  }}
                                >
                                  <Image
                                    src={img}
                                    alt=""
                                    style={{
                                      height: "390px",
                                      aspectRatio: "3/2",
                                    }}
                                    className="w-full h-full rounded-2xl object-cover"
                                    preview={{
                                      toolbarRender: (
                                        _,
                                        {
                                          transform: { scale },
                                          actions: { onZoomOut, onZoomIn },
                                        }
                                      ) => (
                                        <Space
                                          size={12}
                                          className="toolbar-wrapper"
                                        >
                                          <ZoomOutOutlined
                                            disabled={scale === 1}
                                            onClick={onZoomOut}
                                          />
                                          <ZoomInOutlined
                                            disabled={scale === 50}
                                            onClick={onZoomIn}
                                          />
                                        </Space>
                                      ),
                                    }}
                                  />
                                </div>
                              ))}
                            </Carousel>
                          </ConfigProvider>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="space-y-4 profileDetails ">
                    <div className="col-span-12 mt-2 pt-2 pl-2 ml-2 text-lg tracking-tight ">
                      <div className="flex">
                        <h1 className="text-5xl font-bold tracking-tight">
                          {profileData?.member?.name}
                        </h1>
                        <div>
                          <img
                            src={profileData?.kycVerified ? KYCActive : KYC}
                            className="kyc-verify-img"
                            onClick={() => {
                              if (
                                location?.state?.user === "yourProfile" &&
                                profileData?.kycStatusEnum === null &&
                                profileData?.kycVerified === false
                              ) {
                                navigate(`/kyc/${id}`);
                              } else if (
                                location?.state?.user === "yourProfile" &&
                                profileData?.kycStatusEnum === "REQUEST" &&
                                profileData?.kycVerified === false
                              ) {
                                toast.error(
                                  "KYC already requested. Waiting for admin approval!"
                                );
                              } else if (
                                location?.state?.user === "yourProfile" &&
                                profileData?.kycStatusEnum === "APPROVE" &&
                                profileData?.kycVerified
                              ) {
                                toast.error("KYC already verified!");
                              }
                            }}
                            alt=""
                          />
                        </div>
                      </div>

                      <div className="flex space-x-10 p-2 m-2">
                        <ul className="marker:text-gray-300 list-disc pr-9 mr-9 text-gray-600">
                          <li className="p-2 whitespace-nowrap ">
                            {profileData?.age ?? (
                              <p className="text-lg blur-sm">XXXX</p>
                            )}{" "}
                            years,{" "}
                            {profileData?.member?.height ? (
                              <>
                                {convertHeightToFeetAndInches(
                                  profileData?.member?.height
                                )}
                                {` (${profileData?.member?.height}cms)`}
                              </>
                            ) : (
                              <p className="text-lg blur-sm">XXXX</p>
                            )}{" "}
                          </li>
                          {profileData?.formFields?.includes("caste") ||
                            (profileData?.formFields?.includes("subCaste") && (
                              <li className="p-2 whitespace-nowrap">
                                {profileData?.formFields?.includes("caste") && (
                                  <>
                                    {profileData?.familyDetails?.caste ?? (
                                      <p className="text-lg blur-sm">XXXX</p>
                                    )}
                                    ,
                                  </>
                                )}
                                {profileData?.formFields?.includes(
                                  "subCaste"
                                ) && (
                                  <>
                                    {profileData?.familyDetails?.subCaste ?? (
                                      <p className="text-lg blur-sm">XXXX</p>
                                    )}
                                  </>
                                )}
                              </li>
                            ))}

                          {profileData?.formFields?.includes("nakshatra") ||
                            (profileData?.formFields?.includes("rashi") && (
                              <li className="p-2 whitespace-nowrap ">
                                {profileData?.formFields?.includes(
                                  "nakshatra"
                                ) && (
                                  <div className="flex items-center">
                                    Nakshatra is{" "}
                                    {profileData?.familyDetails?.nakshathra ?? (
                                      <p className="text-lg blur-sm">XXXX</p>
                                    )}
                                  </div>
                                )}
                                {profileData?.formFields?.includes("rashi") && (
                                  <div className="flex items-center">
                                    Raasi is{" "}
                                    {profileData?.familyDetails?.rashi ?? (
                                      <p className="text-lg blur-sm">XXXX</p>
                                    )}
                                  </div>
                                )}
                              </li>
                            ))}
                          <li className="p-2 whitespace-nowrap">
                            Lives in{" "}
                            {profileData?.member?.currentCity ?? (
                              <p className="text-lg blur-sm">XXXX</p>
                            )}
                            ,&nbsp;
                            {profileData?.member?.currentState ?? (
                              <p className="text-lg blur-sm">XXXX</p>
                            )}
                          </li>
                        </ul>
                        <ul className="list-disc pl-9 ml-9 marker:text-gray-300 text-gray-600">
                          <li className="p-2 whitespace-nowrap">
                            Studied{" "}
                            {profileData?.educationDetails
                              ?.educationDetails ?? (
                              <p className="text-lg blur-sm">XXXX</p>
                            )}
                          </li>
                          <li className="p-2 whitespace-nowrap">
                            {profileData?.educationDetails
                              ?.employmentDetails ?? (
                              <p className="text-lg blur-sm">XXXX</p>
                            )}
                          </li>
                          <li className="p-2 whitespace-nowrap">
                            Earns ₹{" "}
                            {profileData?.educationDetails?.income === 0 ? (
                              <p className="text-lg blur-sm">XXXX</p>
                            ) : (
                              <>
                                {profileData?.educationDetails?.minIncome} -{" "}
                                {profileData?.educationDetails?.maxIncome}
                              </>
                            )}{" "}
                            Lakhs per annum
                          </li>

                          {profileData?.communityOutputs && (
                            <>
                              <li
                                className="p-2 whitespace-nowrap font-semibold cursor-pointer text-[#804C31]"
                                onClick={handleCommunityModalOpen}
                              >
                                {profileData?.communityOutputs?.length}{" "}
                                {profileData?.communityOutputs?.length > 1 ? (
                                  <>
                                    {profileData?.user ? (
                                      <>linked</>
                                    ) : (
                                      <>common</>
                                    )}{" "}
                                    communities
                                  </>
                                ) : (
                                  <>
                                    {profileData?.user ? (
                                      <>linked</>
                                    ) : (
                                      <>common</>
                                    )}{" "}
                                    community
                                  </>
                                )}
                              </li>{" "}
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full mt-1">
                {profileData && <MatrimonyInfoCard profileData={profileData} />}
              </div>
            </div>
            {!isMobile && (
              <div
                className="rounded-3xl h-full recommend-similarprofiles"
                style={{}}
              >
                {profileData?.user ? (
                  <div>
                    <p className="RightSideTextLabel">Recommended</p>
                    <div className="">
                      {recommended?.length ? (
                        recommended?.map((recommended, index) => (
                          <div
                            key={index}
                            className=" p-1 border-gray-300 flex flex-row justify-between items-center border rounded-xl  mb-3"
                            onClick={() =>
                              handleMatrimonyProfile(recommended?.matrimonyId)
                            }
                          >
                            <Tooltip title={recommended?.name}>
                              <div
                                className=" p-1 m-1  rounded-xl "
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  cursor: "pointer",
                                }}
                              >
                                {recommended?.profileUrl?.length > 0 ? (
                                  <img
                                    className="rounded-lg "
                                    src={recommended?.profileUrl?.[0]}
                                    alt="profile pic"
                                    height={30}
                                    width={70}
                                  />
                                ) : (
                                  <img
                                    className="rounded-full"
                                    src={profilePlaceholder}
                                    alt="profile pic"
                                    height={50}
                                    width={55}
                                  />
                                )}
                                <p className="text-lg p-2 m-2">
                                  {recommended?.member?.name}
                                  <p className="text-md ">
                                    {recommended?.age} years
                                  </p>
                                </p>

                                {/* <MatchingDoughnutChart/> */}
                              </div>
                            </Tooltip>
                            <div
                              style={{
                                width: "25%",
                                height: "25%",
                                marginRight: "10px",
                              }}
                            >
                              <p
                                style={{ fontWeight: "bold", fontSize: "13px" }}
                              >
                                Profile Match
                              </p>
                              <MatchingDoughnutChart
                                matchingPercentage={
                                  recommended?.matchingPercentage
                                }
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex flex-col items-center justify-center w-full h-full pt-[50%]">
                          <img
                            src={image}
                            style={{ width: "75px", height: "75px" }}
                            alt=""
                          />
                          {/* <img src={image} style={{width:"100px", height:"100px"}}/> */}
                          <div className="text-gray-500 text-xl py-3">
                            {" "}
                            No Profiles{" "}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="RightSideTextLabel">Similar Profiles</p>
                    <div className="">
                      {similarData?.length ? (
                        similarData?.map((recommended, index) => (
                          <div
                            className="m-2 p-1 border-gray-300 border rounded-xl  mb-3"
                            key={index}
                            onClick={() =>
                              handleMatrimonyProfile(recommended?.matrimonyId)
                            }
                          >
                            <Tooltip title={recommended?.name}>
                              <div className="flex flex-wrap p-1 m-1  rounded-xl cursor-pointer">
                                {recommended?.profileUrl?.length > 0 ? (
                                  <img
                                    className={`rounded-lg ${
                                      !recommended?.thumbnailImage
                                        ? "blur-sm"
                                        : ""
                                    }`}
                                    src={recommended?.profileUrl[0]}
                                    alt="profile pic"
                                    height={30}
                                    width={70}
                                  />
                                ) : (
                                  <img
                                    className="rounded-lg"
                                    src={profilePlaceholder}
                                    alt="profile pic"
                                    height={50}
                                    width={55}
                                  />
                                )}
                                <p className="text-xl p-2 m-2">
                                  {recommended?.member?.name}
                                  <p className="text-lg ">
                                    {recommended?.age} years
                                  </p>
                                </p>
                              </div>
                            </Tooltip>
                          </div>
                        ))
                      ) : (
                        <div className="flex flex-col items-center justify-center w-full h-full pt-[50%]">
                          <img
                            src={image}
                            style={{ width: "75px", height: "75px" }}
                            alt=""
                          />
                          {/* <img src={image} style={{width:"100px", height:"100px"}}/> */}
                          <div className="text-gray-500 text-xl py-3">
                            {" "}
                            No Profiles{" "}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>

      {/* Request More Details Modal */}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#7d8742",
            borderRadius: 10,
            colorPrimaryBorder: "#7d8742",
          },
        }}
      >
        <Modal
          title={
            <div className="text-2xl font-bold text-center bg-[#7d8742] text-white rounded-t-lg pb-3 pt-2">
              Request Details
            </div>
          }
          visible={showRequestModal}
          width={400}
          className="mx-auto max-w-md"
          bodyStyle={{ padding: "1.5rem" }}
          footer
          onCancel={handleRequestModalClose}
        >
          <div className="mb-4">
            <p className="text-lg font-semibold">
              Select the profile with which you want to request:
            </p>
          </div>

          <Select
            style={{ width: "100%" }}
            placeholder="Select profile"
            onChange={(value) => {
              setSelectedProfile(value);
            }}
            className="w-4/5"
          >
            {yourMatrimonyList?.map((profile: any) => (
              <Option value={profile?.matrimonyId} key={profile?.matrimonyId}>
                {profile?.member?.name}
              </Option>
            ))}
          </Select>
          <div className="flex justify-end mt-2">
            <Button onClick={handleRequestModalClose} className="m-2">
              Cancel
            </Button>
            <Button onClick={handleRequestSubmit} className="m-2">
              Confirm
            </Button>
          </div>
        </Modal>
      </ConfigProvider>

      {/*Common Communities Modal*/}
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#7d8742",
            borderRadius: 10,
            colorPrimaryBorder: "#7d8742",
          },
        }}
      >
        <Modal
          title={
            <div className="text-2xl font-bold text-center bg-[#7d8742] text-white rounded-t-lg pb-3 pt-2">
              {profileData?.user ? (
                <>Linked Communities</>
              ) : (
                <>Common Communities</>
              )}
            </div>
          }
          visible={showCommunityModal}
          width={isMobile ? 350 : 400}
          className="mx-auto max-w-md"
          bodyStyle={{ padding: "1rem" }}
          centered
          footer={false}
          onCancel={handleCommunityModalClose}
        >
          <div className="modalContainer filtercontents">
            {profileData?.communityOutputs?.map(
              (community: any, index: any) => (
                <div
                  key={index}
                  className="flex flex-row items-center py-2 pl-0"
                >
                  {community?.communityProfileUrl ? (
                    <div
                      className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                      //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                      onClick={() =>
                        navigate(`/communityPreview/${community?.id}`,
                          {
                            state: {from : 0}
                          }
                        )
                      }
                    >
                      <img src={community?.communityProfileUrl} alt="" />
                    </div>
                  ) : (
                    <div
                      className={`capitalize relative inline-flex items-center cursor-pointer justify-center overflow-hidden rounded-full modalmember ${
                        index === 0 ? "" : "" // Apply green border to the first element
                      }`}
                      onClick={() =>
                        navigate(`/communityPreview/${community?.id}`,
                          {
                            state: {from : 0}
                          })
                      }
                    >
                      <span
                        className="text-3xl text-white dark:text-gray-100 flex flex-col items-center cursor-pointer"
                        style={{ color: "white" }}
                        onClick={() =>
                          navigate(`/communityPreview/${community?.id}`,
                            {
                              state: {from : 0}
                            })
                        }
                      >
                        <span className="memberSubstring">
                          {community?.name?.substring(0, 1)}
                        </span>
                      </span>
                    </div>
                  )}

                  <div
                    className="flex items-center flex-col modalmemberName cursor-pointer"
                    style={{ color: "black" }}
                    onClick={() =>
                      navigate(`/communityPreview/${community?.id}`,
                        {
                          state: {from : 0}
                        })
                    }
                  >
                    <span className="postMembertitle capitalize ml-2">
                      {index === 0 ? (
                        <>
                          <span className="">{community?.name}</span>
                        </>
                      ) : (
                        <span className="modalmemberName">
                          {community?.name}
                        </span>
                      )}
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
        </Modal>
      </ConfigProvider>

      {/*Select your matrimony modal*/}
      {showYourMatrimonyModal && (
        <SelectMatrimonyModal
          onCancelClick={() => {
            setShowYourMatrimonyModal(false);
          }}
          yourMatrimonyList={yourMatrimonyList}
        />
      )}
    </div>
  );
}

export default MatrimonyProfile;
