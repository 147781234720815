import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { toast } from "react-toastify";
import { request } from "../../Utils/request";
import Loader from "../Loader/Loader";
import community from "../../assets/images/community-connect.jpg";
import { TIMEAGO } from "../../Utils/timeago";
import RejectComponent from "../RejectComponent/RejectComponent";
import NoRequest from "./NoRequest";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";

type Props = {};

// eslint-disable-next-line no-empty-pattern
const MatrimonyMessages = ({}: Props) => {
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [matrimonyRequest, setMatrimonyRequest] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [specificData, setSpecificData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [showRejectComponent, setShowRejectComponent] =
    useState<Boolean>(false);
  const navigate = useNavigate();

  const getMatrimonyProfileRequest = async (page?: number) => {
    try {
      const resp = await request(
        `/member/current/matrimony-profile/all/requests?page=${
          page || 0
        }&size=10`
      );
      console.log(page, "MatrimonyMessages======>", resp.content);
      if (resp.content.length === 0) {
        setHasMore(false);
      }
      if (page === 0) {
        setMatrimonyRequest(resp?.content);
        setLoading(false);
      } else if (resp?.content?.length) {
        let oldData = [...matrimonyRequest];
        oldData = [...oldData, ...resp?.content];
        setMatrimonyRequest(oldData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMatrimonyProfileRequest(currentPage);
  }, [currentPage, toggleRefresh]);

  const OnApproveORRejectClick = async (
    data: any,
    type: string,
    message?: string
  ) => {
    const body = {
      method: "PUT",
      body: JSON.stringify({
        ...(type === "reject" && { reason: message }),
        requestTypeEnum: type === "approve" ? "APPROVE" : "REJECT",
      }),
    };
    if (type) {
      let abusiveResult;
      if (message) {
        abusiveResult = await CheckBadWordsExist(message);
      }

      if (
        type === "approve" ||
        (!abusiveResult?.profanity && !abusiveResult?.words?.length)
      ) {
        const res = await request(
          `/member/current/matrimony-profile/${data?.id}/request/process`,
          body
        );
        if (res) {
          if (type === "approve" && res?.matrimonyProfileId) {
            toast.success("Approval processed successfully");
          } else if (type === "reject" && res?.matrimonyProfileId) {
            toast.success("Rejection processed successfully");
          } else {
          }
          const filteredState = matrimonyRequest.filter(
            (item: any) => !(item.id === data?.id)
          );
          setMatrimonyRequest(filteredState);
          // getMatrimonyProfileRequest(currentPage);
          setShowRejectComponent(false);
        }
      } else if (abusiveResult?.profanity) {
        toast.error("Prohibited from generating harmful content.");
        setShowRejectComponent(true);
      } else if (type !== "approve" && !abusiveResult.status) {
        toast.error(abusiveResult?.message || "Something went wrong");
      }
    }
  };

  const handleRefresh = async (): Promise<void> => {
    setCurrentPage(0);
    setToggleRefresh(!toggleRefresh);
  };

  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handleRefresh();
      }}
    >
      <section>
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <div className="thumbnailRowForThree requestbox">
            {!matrimonyRequest.length ? (
              <div className="text-xl font-medium w-full">
                <NoRequest />
              </div>
            ) : (
              <InfiniteScroll
                dataLength={matrimonyRequest?.length}
                next={() => {
                  setCurrentPage(currentPage + 1);
                }}
                hasMore={hasMore}
                style={{ overflow: "visible"}}
                loader={<></>}
              >
                {matrimonyRequest?.map((data, index) => (
                  <div
                    className="thumbnailRowForThreeCol"
                    key={data.id}
                    style={{
                      display: "flex",
                      padding: "0 10px",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={community}
                      alt=""
                      className="reveived-comm-icon"
                    />

                    <div className="thumbnailDescription fontSize !text-black">
                      <span>
                        You have received a request from{" "}
                        <strong
                          onClick={() => {
                            navigate(`/profile/${data?.userId}`);
                          }}
                          className="font-medium  cursor-pointer text-[#6d3a21]"
                        >
                          {" "}
                          {data?.userName}
                        </strong>{" "}
                        to view more details of{" "}
                        <strong
                          className="font-medium text-[#6d3a21] cursor-pointer !mr-1"
                          onClick={() => {
                            navigate(
                              `/matrimony/matrimony-profile/${data?.matrimonyProfileId}`
                            );
                          }}
                        >
                          {" "}
                          {data?.profileName || "--"}
                        </strong>
                        to view matrimony profile of
                        <strong
                          className="font-medium text-[#6d3a21] cursor-pointer"
                          onClick={() => {
                            navigate(
                              `/matrimony/matrimony-profile/${data?.requestedByMatrimonyId}`
                            );
                          }}
                        >
                          {" "}
                          {data?.requestedByMatrimonyUserName || "--"}
                        </strong>
                        <div className="mt-2">
                          <TIMEAGO timestamp={data?.createdAt} />
                        </div>
                      </span>
                    </div>
                    <div className="buttonRow">
                      <Button
                        onClick={() => {
                          OnApproveORRejectClick(data, "approve");
                        }}
                        className="approve-btn border border-green-500 text-green-500  font-medium m-1"
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={() => {
                          setShowRejectComponent(true);
                          setSpecificData(data);
                          // OnApproveORRejectClick(data, "reject");
                        }}
                        className="reject-btn border-red-500 text-red-500 font-medium m-1"
                      >
                        Reject
                      </Button>
                    </div>
                    {showRejectComponent ? (
                      <div className="">
                        <RejectComponent
                          onCancelClick={() => {
                            setShowRejectComponent(false);
                          }}
                          onRejectClick={(rejectText: any) => {
                            OnApproveORRejectClick(
                              specificData,
                              "reject",
                              rejectText
                            );
                            // setShowRejectComponent(false);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>
        )}
      </section>
    </PullToRefreshComponent>
  );
};

export default MatrimonyMessages;
